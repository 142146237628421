import React, { useContext, useEffect } from "react";
import SearchIcon from "../../../assets/logo/SearchIcon.svg";
import styled from "styled-components";
import { Logincontext } from "../../../lib/Providers/Loginstatus";
import { useState } from "react";

const StyledContainer = styled.div`
  box-shadow: 0px 4px 16px #00000029;
  background-color: #ffffff;
  border-radius: 18px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1em;
  height: 7vh;
`;

const StyledSearchBar = styled.input`
  width: 100%;
  border: none;
  border-radius: 18px;
  padding: 0 1em;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #9f9f9f;
    font-size: 1vw;
  }
`;

const StyledSearchIcon = styled.img`
  padding: 0.6em 0;
`;

const SearchBar = (props) => {
  const [search, setSearch] = useState('')
  const { setTabIndex, setSearchvalues, searchval } = useContext(Logincontext)
  const handlesearch = (e) => {
    setSearch(e)
    setSearchvalues(e)
  }
  const handleFocus = () => {
    setTabIndex(3)
  }
  // console.log('search', search.length);

  useEffect(() => {
    if (search.length > 3) {
      console.log('hello');
      const listener = event => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
          console.log("Enter key was pressed. Run your function.");
          event.preventDefault();
          var SearchButton = document.getElementById("searchbutton");
          SearchButton.click(); // this will trigger the click event
        }
      };
      document.addEventListener("keydown", listener);
      return () => {
        document.removeEventListener("keydown", listener);
      };
    }
  }, [search])

  return (
    <StyledContainer>
      <StyledSearchBar {...props} value={search} onChange={(e) => { handlesearch(e.target.value) }} onFocus={handleFocus} />
      <StyledSearchIcon src={SearchIcon} alt="Search" id="searchbutton" onClick={() => { searchval() }} />
    </StyledContainer>
  );
};

export default SearchBar;
