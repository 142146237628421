import { Box, Flex, useRadio } from "@chakra-ui/react";

const RadioCard = (props) => {
    const { btnw, children, rounded, fontSize, py } = props;
    const { getInputProps, getCheckboxProps } = useRadio(props);
  
    const input = getInputProps();
    const checkbox = getCheckboxProps();

    const isChecked = input.value === props.defaultValue;

    return (
      <Box marginInlineStart={"0!important"} mb={0} as="label">
        <input {...input} defaultChecked={isChecked} />
        <Flex
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          rounded={rounded || '25px'}
          fontSize={fontSize || '12px'}
          color="primary"
          justify="center"
          px={3}
          py={py || 1}
          mr={0}
          whiteSpace="nowrap"
          border="1px solid primary"
          bg="transparent"
          transition="0.3s"
          _checked={{
            bg: '#00ab83',
            borderColor: '#00ab83',
            color:'#fff',
          }}
          _focus={{
            bg: '#00ab83',
            color:'#fff',
          }}
          w={btnw}
        >
          {children}
        </Flex>
      </Box>
    );
  };

  export default RadioCard;