import { Box, Center, Flex, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { LiveTicketCardContainer } from './styles'
import { CiEdit } from "react-icons/ci";

const TicketNotecard = () => {
    return (
        <Box mt={8} px={6}>
            <Flex py={4} align="center">
                <Heading textAlign="start" as="h6" fontSize={15} fontWeight={600}>Notes</Heading>
                <Center
                    // display="inline-block"
                    // as="span"
                    w="25px"
                    ml={3}
                    h="25px"
                    border="solid 1px #01AB97"
                    rounded="50px"
                ><CiEdit color='#01AB97' /></Center>
            </Flex>
            <LiveTicketCardContainer >
                <Text textAlign="start" fontSize={14}>Lorem ipsum dolor sit amet consectetur. Nec vitae lectus porttitor dolor dolor risus consectetur.Lorem ipsum dolor sit amet consectetur. Nec vitae lectus porttitor dolor dolor risus consectetur.Lorem ipsum dolor sit amet consectetur. Nec vitae lectus porttitor dolor dolor risus consectetur.</Text>
            </LiveTicketCardContainer>
        </Box>
    )
}

export default TicketNotecard