import React, { useEffect } from "react";
import SearchgreenIcon from "../../../assets/logo/SearchgreenIcon.svg";
import styled from "styled-components";

const StyledContainer = styled.div`
  background-color: #ffffff;
  border-radius: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: 1em;
  height: 6vh;
  border: solid 1px #00AB83;
`;

const StyledSearchBar = styled.input`
  width: 100%;
  border: none;
  border-radius: 15px;
  padding: 0 1em;
  &:focus {
    outline: none;
  }
  &::placeholder {
    color: #9f9f9f;
    font-size: 1vw;
  }
`;

const StyledSearchIcon = styled.img`
  padding: 0.6em 0;
`;

const SearchBar2 = (props) => {
  const { searchmedicine, setSearchvalue, searchvalue,setSearchmedicine } = props

  useEffect(()=>{
    const listener = event =>{
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        console.log("Enter key was pressed. Run your function.");
        event.preventDefault();
        var SearchButton = document.getElementById("searchbutton2");
        SearchButton.click(); // this will trigger the click event
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  },[])

  const handlesearch = (e) =>{
    if(e.length === 0){
      setSearchmedicine()
      setSearchvalue(e)
    }else{
      setSearchvalue(e);
      searchmedicine(e)
    }
  }



  return (
    <>
      <StyledContainer>
        <StyledSearchBar value={searchvalue}
          onChange={(e) => {
            // searchmedicine(e.target.value)
            handlesearch(e.target.value)
            
          }}
        />
        <StyledSearchIcon id={"searchbutton2"} src={SearchgreenIcon} 
        onClick={()=>{searchmedicine(searchvalue)}}  
        alt="Search" />
      </StyledContainer>
    </>
  );
};

export default SearchBar2;
