import { Box, Button, Grid, GridItem, Heading, HStack, Input, Text, useNumberInput, VStack } from '@chakra-ui/react'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import Config from '../../../../../../lib/Config'
import { Cartcontext } from '../../../../../../lib/Providers/CartFormation'
import { TicketContext } from '../../../../../../lib/Providers/TicketDetails'

const CartAdded = (props) => {
    const { cartdata } = props
    const { detailedticket, setFetch } = useContext(TicketContext)
    const handledecrease = async (item) => {

        const medi = {
            mastermenuItemId: item.mastermenuItemId,
            medicineName: item.medicineName,
            quantity: item.quantity - 1,
            unit: "unit",
            mrp: item.mrp,
            price: item.price,
            hdpOrderId: detailedticket.id,
            promcode_applicable: item?.promcode_applicable

        }
        await axios.post(Config.Add_Or_Update_Cart, medi)
            .then((data) => {
                setFetch((pre) => pre + 1)
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const handleIncrease = async (item) => {
        const medi = {
            mastermenuItemId: item.mastermenuItemId,
            medicineName: item.medicineName,
            quantity: item.quantity + 1,
            unit: "unit",
            mrp: item.mrp,
            price: item.price,
            hdpOrderId: detailedticket.id,
            promcode_applicable: item?.promcode_applicable
        }
        await axios.post(Config.Add_Or_Update_Cart, medi)
            .then((data) => {
                setFetch((pre) => pre + 1)
            })
            .catch((err) => {
                console.log(err);
            })
    }
    return (
        <>
            {cartdata && cartdata?.map((item) => (
                <Grid borderBottom="solid 2px #f7f7f7" templateColumns='repeat(12, 1fr)' gap={0}>
                    <GridItem colSpan={8} py={4} w='100%' h='100%'>
                        <VStack align="flex-start" spacing={2}>
                            <Heading textTransform={'capitalize'} fontWeight={600} fontSize={14} color="#474747">{item.medicineName}</Heading>
                            <Heading color="#A23DFF" textAlign="start" as="h6" fontWeight={600} fontSize={14}>{(100 - (item.price * 100) / item.mrp).toFixed(0)}% off</Heading>
                            <HStack justifyContent="space-between" width="100%">
                                <Heading color="#474747" textAlign="start" as="h6" fontWeight={600} fontSize={14}>
                                    <Text textDecoration="line-through" as="span">₹{((item.mrp) * item.quantity).toFixed(2)}</Text>
                                    <Text ml={2} color="#00AB83" as="span">₹{((item.price) * item.quantity).toFixed(2)}</Text></Heading>
                            </HStack>
                        </VStack>
                    </GridItem>
                    <GridItem colSpan={4} py={4} w='100%' h='100%'>
                        <HStack border="solid 1px #000" rounded="50px">
                            <Button bg="none"
                                width="auto"
                                minW="auto"
                                px="4"
                                height="auto"
                                _hover={{ bg: 'none' }}
                                onClick={() => { handledecrease(item) }}
                            // onClick={()=>{dispatch({
                            //     type: "D_CART_QTY",
                            //     payload: item,
                            //   })}}
                            >-</Button>
                            <Input color="#00AB83" textAlign="center" value={item.quantity} ml="0!important" px={1} _focus={{ border: 'none', outlineColor: "none", boxShadow: 'none' }} border="none" />
                            <Button
                                bg="none"
                                width="auto"
                                minW="auto"
                                px="4"
                                height="auto"
                                ml="0!important"
                                _hover={{ bg: 'none' }}
                                onClick={() => { handleIncrease(item) }}
                            // onClick={() => {
                            //     dispatch({
                            //         type: "I_CART_QTY",
                            //         payload: item,
                            //     })
                            // }}
                            >+</Button>
                        </HStack>
                        {/* <Text textAlign="end" color="#b8b8b8" fontWeight={700}>₹11.33</Text>s */}
                    </GridItem>
                </Grid>
            ))}
        </>
    )
}

export default CartAdded