/* eslint-disable no-mixed-operators */
import { Box, Flex, Heading, Spacer, Text } from '@chakra-ui/react'
import axios from 'axios'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import Config from '../../../../../../lib/Config'
import { Cartcontext } from '../../../../../../lib/Providers/CartFormation'
import { TicketContext } from '../../../../../../lib/Providers/TicketDetails'
import { UserProfileWrapper, UserProfileWrapper2 } from '../style'
import CartAdded from './CartAdded'
import CartCoupon from './CartCoupon'
import Delivercard from './Delivercard'
import { BsTrash } from 'react-icons/bs'
import { type } from '@testing-library/user-event/dist/type'

let gsttax = 0

const CartAddbox = (props) => {
    const { tabchange } = props
    const [cartdata, setCartdata] = useState()
    const [total, setTotal] = useState()
    const [othertotal, setOtherTotal] = useState()
    const [otherdiscount, setOtherDiscount] = useState()
    const [othermrp, setOtherMrp] = useState()
    const [medicinediscount, setMedicineDiscount] = useState()
    const [medicinemrp, setMedicineMrp] = useState()
    const [mrp, setMrp] = useState()
    const [couponresponse, setCouponresponse] = useState()
    const [selectedNearbyPharmacy, setSelectedNearbypharmacy] = useState()
    const { fetch, setFetch, resetPage, detailedticket, vendordetail, setNearbyStoreslist } = useContext(TicketContext)
    const [deliveycharges, setDeliveycharges] = useState(0)
    const [Gst, setGst] = useState(0)
    const [topay, setTopay] = useState(0)
    const [discountonmrp, setDiscountonmrp] = useState(0)
    const [maxdiscount, setMaxdiscount] = useState()
    const [discountpercentage, setDiscountpercentage] = useState()
    const [yousaved, setYousaved] = useState(0)
    const [gstpercentage, setGstpercentage] = useState(0)
    const [placeorderdisabled, setPlaceorderdisabled] = useState(true)
    const [minimumordervaluedisabled, setMinimumordervaluedisabled] = useState(true)
    const [couponcode, setCouponcode] = useState()
    const [disable, setDisable] = useState(false)
    const [minimumorderamount, setMinimumorderamount] = useState(false)
    const [minimumordervaluedisable, setMinimumordervaluedisable] = useState(true)
    const [TotalQuantity, setTotalQuantity] = useState(0)
    const [isCouponDisable, setisCoupondisable] = useState(false)
    const [isselectDisable, setisselectdisable] = useState(false)



    console.log('ddddd detailticket', detailedticket);

    const handleremovecoupon = () => {
        setCouponcode()
        setDisable(false)
        setCouponresponse()
        setDiscountonmrp(0)
        setYousaved(0)
        setPlaceorderdisabled(true)
    }

    const getCart = async () => {
        await axios.get(`${Config.Get_Cart_by_TicketId}${detailedticket?.id}`)
            .then((result) => result)
            .then((data) => {
                if (data.data.data?.length <= 0) {
                    handleremovecoupon()
                }
                setCartdata(data.data.data)
                console.log("cart_Data", data.data.data);
                let cart = data.data.data

                let othersubtotal = data?.data?.data.reduce((acc, curr) => {
                    if (curr.promcode_applicable == 0) {
                        return acc + Number(curr.price) * curr.quantity;
                    }
                    return acc;
                }, 0);

                let othermrptotal = data?.data?.data.reduce((acc, curr) => {
                    if (curr.promcode_applicable == 0) {
                        return acc + Number(curr.mrp) * curr.quantity;
                    }
                    return acc;
                }, 0);

                let otherdiscount = parseFloat(othermrptotal - othersubtotal).toFixed(2)

                setOtherMrp(othermrptotal)
                setOtherDiscount(otherdiscount)
                setOtherTotal(othersubtotal)

                // let subtotal = data?.data?.data.reduce((acc, curr) => acc + Number(curr.price) * curr.quantity, 0)
                // let mrptotal = data?.data?.data.reduce((acc, curr) => acc + Number(curr.mrp) * curr.quantity, 0)
                let subtotal = data?.data?.data.reduce((acc, curr) => {
                    if (curr.promcode_applicable == 1) {
                        return acc + Number(curr.price) * curr.quantity;
                    }
                    return acc;
                }, 0);

                let mrptotal = data?.data?.data.reduce((acc, curr) => {
                    if (curr.promcode_applicable == 1) {
                        return acc + Number(curr.mrp) * curr.quantity;
                    }
                    return acc;
                }, 0);

                setMedicineDiscount(mrptotal - subtotal)
                setMedicineMrp(mrptotal)


                console.log('otherto', cart, vendordetail, othersubtotal, 'othermrp', othermrptotal, 'total', subtotal, 'mrp', mrptotal);


                setTotal(data?.data?.data.reduce((acc, curr) => acc + Number(curr.price) * curr.quantity, 0))
                setMrp(data?.data?.data.reduce((acc, curr) => acc + Number(curr.mrp) * curr.quantity, 0))
                setTotalQuantity(data?.data?.data?.reduce((acc, obj) => acc + obj.quantity, 0))

                console.log('isminimum minimum_order_value_for_delivery', vendordetail?.minimum_order_value_for_delivery, othersubtotal, subtotal, (othersubtotal + subtotal));
                if (detailedticket?.delivery_pickup_types?.toLowerCase() !== "pickup" && cart?.length > 0 && parseInt(vendordetail?.minimum_order_value_for_delivery) > (othersubtotal + subtotal)) {
                    console.log('isminimum');
                    setMinimumordervaluedisabled(true)
                } else {
                    setMinimumordervaluedisabled(false)
                }

                // console.log("Total Quantity: " + totalQuantity);
                let dcharge = 0
                let secondsum = 0
                console.log("detailedkfkdfk", (subtotal + othersubtotal) < parseInt(vendordetail?.charges[0]?.max_value), typeof (subtotal), typeof (othersubtotal), subtotal, othersubtotal, subtotal + othersubtotal, parseInt(vendordetail?.charges[0]?.max_value));
                if (detailedticket?.delivery_pickup_types?.toLowerCase() !== "pickup") {

                    if (cart.length > 0) {
                        if (vendordetail?.charges && (subtotal + othersubtotal) < parseInt(vendordetail?.charges[0]?.max_value)) {
                            let dis = couponresponse?.distance || selectedNearbyPharmacy?.distance
                            let distance = parseFloat(dis)
                            let fixedKm = parseInt(vendordetail?.fixed_km)
                            console.log('delivery 1', distance, fixedKm, dis, selectedNearbyPharmacy);
                            if (distance && distance > fixedKm) {
                                distance = Math.ceil(distance)
                                dcharge = parseFloat(vendordetail?.fixed_price) + parseFloat((distance - fixedKm) * parseInt(vendordetail?.per_km_price))
                                console.log('delivery 2', dcharge, distance);
                                // let newdeliveryCharge = parseFloat(vendorSet?.fixed_price) + parseFloat((distance - fixedKm) * parseInt(vendorSet?.per_km_price))
                                setDeliveycharges(dcharge);

                            } else {
                                dcharge = parseFloat(vendordetail?.fixed_price)
                                setDeliveycharges(dcharge)
                            }
                            // dcharge = 200
                        } else {
                            console.log('delivery 3');
                            dcharge = 0
                            setDeliveycharges(0);
                        }
                    }

                    // vendordetail?.charges?.map((item) => {
                    //     if (data.data.data?.length > 0) {
                    //         if (parseInt(item.min_value) <= (subtotal + othersubtotal) && (subtotal + othersubtotal) <= parseInt(item.max_value)) {
                    //             dcharge = item.delivery_charges
                    //             setDeliveycharges(parseFloat(item.delivery_charges));
                    //         }
                    //     }
                    // })
                } else {
                    setDeliveycharges(0);
                    console.log('delivery 4');
                }

                if ((vendordetail?.included_tax === "Yes" || vendordetail?.included_tax === "0") && couponresponse) {
                    console.log('logg in gst');
                    if (maxdiscount && discountpercentage) {
                        gsttax = (subtotal * parseInt(couponresponse?.restaurant_service_tax) / 100).toFixed(2)
                        setGstpercentage(couponresponse?.restaurant_service_tax)
                        let discount1 = (mrptotal * discountpercentage) / 100
                        if (discount1 < maxdiscount) {
                            secondsum = parseFloat(othersubtotal) + parseFloat(subtotal) + parseFloat(dcharge) + parseFloat(gsttax) - parseFloat(discount1)
                            setDiscountonmrp(discount1)
                            let saved = parseFloat(othermrptotal) + parseFloat(mrptotal) - parseFloat(othersubtotal) - parseFloat(subtotal) + parseFloat(discount1)
                            setYousaved(saved)
                        } else {
                            secondsum = parseFloat(othersubtotal) + parseFloat(subtotal) + parseFloat(dcharge) + parseFloat(gsttax) - parseFloat(maxdiscount)
                            setDiscountonmrp(maxdiscount)
                            let saved = parseFloat(othermrptotal) + parseFloat(mrptotal) - parseFloat(othersubtotal) - parseFloat(subtotal) + parseFloat(maxdiscount)
                            setYousaved(saved)
                        }
                        setGst(gsttax)
                        setTopay(secondsum)
                    }
                    else {
                        const sum = parseFloat(othersubtotal) + parseFloat(subtotal) + parseFloat(dcharge)
                        setGst(0)
                        setTopay(sum)
                    }
                } else if (couponresponse) {
                    // console.log(couponresponse, 'logg out gst');
                    if ((subtotal + othersubtotal) > minimumorderamount) {
                        console.log("ordercan be placed");
                        setMinimumordervaluedisable(false)

                    }
                    // const sum = parseFloat(subtotal) + parseFloat(dcharge)
                    gsttax = 0
                    let discount1 = (mrptotal * discountpercentage) / 100
                    if (discount1 < maxdiscount) {
                        secondsum = parseFloat(othersubtotal) + parseFloat(subtotal) + parseFloat(dcharge) + parseFloat(gsttax) - parseFloat(discount1)
                        setDiscountonmrp(discount1)
                        let saved = parseFloat(othermrptotal) + parseFloat(mrptotal) - parseFloat(othersubtotal) - parseFloat(subtotal) + parseFloat(discount1)
                        setYousaved(saved)
                    } else {
                        secondsum = parseFloat(othersubtotal) + parseFloat(subtotal) + parseFloat(dcharge) + parseFloat(gsttax) - parseFloat(maxdiscount)
                        setDiscountonmrp(maxdiscount)
                        let saved = parseFloat(othermrptotal) + parseFloat(mrptotal) - parseFloat(othersubtotal) - parseFloat(subtotal) + parseFloat(maxdiscount)
                        setYousaved(saved)
                    }
                    setGst(0)
                    setTopay(secondsum)
                }
                else {
                    const sum = parseFloat(othersubtotal) + parseFloat(subtotal) + parseFloat(dcharge)
                    setTopay(sum)
                    let saved = parseFloat(othermrptotal) + parseFloat(mrptotal) - parseFloat(othersubtotal) - parseFloat(subtotal)
                    setYousaved(saved)
                }

                // if (couponresponse) {
                //     const discount = ((mrptotal * discountpercentage) / 100)
                //     console.log(discount, "dd", mrp);
                //     if (discount < maxdiscount) {
                //         setDiscountonmrp(discount)
                //     } else {
                //         setDiscountonmrp(maxdiscount)
                //     }

                //     setTotal(total - parseFloat(discountonmrp))
                // }

                // getdeliverycharges(data?.data?.data?.reduce((acc, curr) => acc + Number(curr.price) * curr.quantity, 0))
                // const sum = parseFloat(data?.data?.data?.reduce((acc, curr) => acc + Number(curr.price) * curr.quantity, 0)) + deliveycharges
                // console.log(sum, "sumsya");
                // setTopay(sum)

                //console.log(getdeliverycharges(data?.data?.data?.reduce((acc, curr) => acc + Number(curr.mrp) * curr.quantity, 0)), 'dc');

            })
            .catch((err) => {
                console.log(err);
                setCartdata([])
                handleremovecoupon()
                setTopay(0)
            })
    }




    useEffect(() => {
        // if (detailedticket) {
        getCart();
        // }
    }, [fetch, detailedticket, couponresponse, resetPage, selectedNearbyPharmacy])


    const GetStorelist = useCallback(async () => {
        if (detailedticket?.longitude && detailedticket?.latitude) {
            const obj = {
                latitude: detailedticket.latitude, //"26.89785963486394" for testing lat long
                longitude: detailedticket.longitude, //"75.76839593696442"
                vendor_id: 41167,
            };

            try {
                const result = await axios.post(Config.Get_Store_List, obj);
                console.log('data qwerty', result.data);
                setNearbyStoreslist(result?.data?.result)
                setSelectedNearbypharmacy(null)
                handleremovecoupon()
                setisselectdisable(false)
                setisCoupondisable(false)
            } catch (error) {
                setisselectdisable(false)
                setisCoupondisable(false)
                setSelectedNearbypharmacy(null)
                handleremovecoupon()
                console.error('Error fetching store list', error);
            }
        }
    }, [detailedticket?.longitude, detailedticket?.latitude]);

    useEffect(() => {
        GetStorelist();
    }, [GetStorelist]);


    const clearcart = async () => {
        await axios.delete(`${Config.Delete_Cart}${detailedticket?.id}`)
            .then((res) => res.data)
            .then((data) => {
                getCart()
            })
            .catch((err) => {
                console.log(err, "deleteerror");
            })
    }



    return (
        <>
            <Flex borderBottom="solid 1px #b0b0b0" alignContent='baseline !important'>
                <Heading // borderBottom="solid 1px #b0b0b0"
                    mt={2} pb={2} fontSize={15} color="#00AB83" as="h6">Cart
                    <Text color="#474747" as="span" fontWeight={400}> {cartdata?.length > 0 ? `(${TotalQuantity} Items added)` : `(No Items Added)`}</Text>
                </Heading>
                <Spacer />
                {/* <Heading */}
                {/* //borderBottom="solid 1px #b0b0b0"  */}
                {/* mt={2} pb={2} fontSize={15} color="#00AB83" as="h1"> */}
                {cartdata?.length > 0 && <BsTrash fontSize='x-large' color="#00AB83" onClick={clearcart} />}
                {/* <Text color="#474747" as="span" fontWeight={400}> {couponresponse?.name}</Text> */}
                {/* </Heading> */}
            </Flex>
            {couponresponse &&
                <Heading
                    borderBottom="solid 1px #b0b0b0"
                    mt={2} pb={2} fontSize={15} color="#00AB83" as="h6">Assigned Pharmacy
                    <Text textTransform={'capitalize'} color="#474747" as="span" fontWeight={400}> {couponresponse?.name}</Text>
                </Heading>
            }
            {
                selectedNearbyPharmacy &&
                <Heading
                    borderBottom="solid 1px #b0b0b0"
                    mt={2} pb={2} fontSize={15} color="#00AB83" as="h6">Assigned Pharmacy
                    <Text textTransform={'capitalize'} color="#474747" as="span" fontWeight={400}> {selectedNearbyPharmacy?.name}</Text>
                </Heading>
            }
            <UserProfileWrapper2>
                <CartAdded cartdata={cartdata} />
                <CartCoupon total={total} setTotal={setTotal} mrp={mrp}
                    setCouponresponse={setCouponresponse} setDiscountonmrp={setDiscountonmrp}
                    discountonmrp={discountonmrp} setDiscountpercentage={setDiscountpercentage}
                    setMaxdiscount={setMaxdiscount} discountpercentage={discountpercentage}
                    maxdiscount={maxdiscount} setYousaved={setYousaved} setPlaceorderdisabled={setPlaceorderdisabled}
                    couponcode={couponcode} setCouponcode={setCouponcode} disable={disable} setDisable={setDisable}
                    handleremovecoupon={handleremovecoupon} setMinimumorderamount={setMinimumorderamount}
                    setSelectedNearbypharmacy={setSelectedNearbypharmacy} selectedNearbyPharmacy={selectedNearbyPharmacy}
                    medicinemrp={medicinemrp} setMinimumordervaluedisable={setMinimumordervaluedisable}
                    isCouponDisable={isCouponDisable} setisCoupondisable={setisCoupondisable} isselectDisable={isselectDisable} setisselectdisable={setisselectdisable}
                />
                <Delivercard
                    selectedNearbyPharmacy={selectedNearbyPharmacy}
                    othermrp={othermrp}
                    otherdiscount={otherdiscount}
                    othertotal={othertotal}
                    medicinemrp={medicinemrp}
                    medicinediscount={medicinediscount}
                    tabchange={tabchange} total={total} deliveycharges={deliveycharges} mrp={mrp} setTopay={setTopay}
                    topay={topay} Gst={Gst} couponresponse={couponresponse} discountonmrp={discountonmrp} yousaved={yousaved}
                    gstpercentage={gstpercentage} placeorderdisabled={placeorderdisabled} cartdata={cartdata}
                    setPlaceorderdisabled={setPlaceorderdisabled} minimumordervaluedisable={minimumordervaluedisable}
                    clearcart={clearcart} minimumordervaluedisabled={minimumordervaluedisabled}
                    setSelectedNearbypharmacy={setSelectedNearbypharmacy} setisCoupondisable={setisCoupondisable} setisselectdisable={setisselectdisable} />
            </UserProfileWrapper2>
        </>
    )
}

export default CartAddbox