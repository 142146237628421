/* eslint-disable no-unused-expressions */
import { Box, Button, Center, Flex, Heading, Link, List, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import axios from 'axios';
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Config from '../../../../../lib/Config';
import { ConvertToRequiredWeekDay } from '../../../../../lib/utlis/Functios';
import { TicketContext } from '../../../../../lib/Providers/TicketDetails';
import { useContext } from 'react';

const Doctorlistpopup = ({ isOpen, onClose, slecteddoc, setSelecteddoc, SetDocdetils }) => {
    const { setDocNameDetails } = useContext(TicketContext)
    const [doclist, setDoctorlist] = useState();
    const Day = ConvertToRequiredWeekDay(new Date().getDay())
    // {"consulting_type":"ONLINE",
    // "time_slots":"e52c4a6e-86bc-486e-8d7f-64bd9f88bc0b",
    // "doctor_id":"3b7cb255-f414-49d0-9ba3-18f45af96c7c",
    // "start_time":"2023-04-04T17:05:00.000Z",
    // "end_time":"2023-04-04T17:05:00.000Z",
    // "date_of_appointment":"2023-04-04T17:05:00.000Z", 
    // "appointment_type": "PRESCRIPTION_APPROVAL",  
    // "ticket_id": 310} 

    const handleselect = (item) => {
        console.log(item, "prescriptionGenerateMapper");
        // let timslot, startTime, endTime
        // for (let x of item.timeslot) {
        //     if (x.day_of_week === Day) {
        //         startTime = x.start_time,
        //             endTime = x.end_time,
        //             timslot = x.id
        //     }
        // }
        // let docdetails = {
        //     consulting_type: "ONLINE",
        //     appointment_type: "PRESCRIPTION_APPROVAL",
        //     time_slots: timslot,
        //     start_time: startTime,
        //     end_time: endTime,
        //     date_of_appointment: `${moment().format('YYYY-MM-DDT00:00:00.000')}Z`,
        //     doctor_id: item.id
        // }
        SetDocdetils(item)
        setSelecteddoc(item)
        setDocNameDetails(item)
        onClose()
    }
    const handledeselect = (item) => {
        setSelecteddoc()
        setDocNameDetails()
        SetDocdetils()
    }

    const Getavailabledoctors = async () => {
        let Doctorist = [];
        await axios.get(Config.Get_Doctor_List_Insta_Doc)
            .then((result) => result.data)
            .then((data) => {
                console.log('qwertydata', data.data);
                setDoctorlist(data?.data)
                // data?.map((item) => {
                //     if (item.timeslot?.length > 0 || item.timeslot === !null) {
                //         item.timeslot.map((item1) => {
                //             if (item1.day_of_week === Day) {
                //                 Doctorist.push(item)
                //             }

                //         })
                //     }
                // })
            })
            .catch((err) => {
                console.log(err);
            })
        // const jsonObject = Doctorist.map(JSON.stringify);
        // const uniqueSet = new Set(jsonObject);
        // let arr = Array.from(uniqueSet).map(JSON.parse);
        // setDoctorlist(arr);
    }


    const getSelect = (item) => {
        if (item?.id === slecteddoc?.id) {
            return (
                <Link textAlign='center' width="30%" textDecoration="underline" onClick={() => { handledeselect(item) }} color={"#00AB83"}>Selected</Link>
            )
        } else {
            return (
                <Link textAlign='center' width="30%" pr="10px" onClick={() => { handleselect(item) }}>Select</Link>
            )
        }

    }

    useEffect(() => {
        Getavailabledoctors()
    }, [])


    return (
        <>
            <Modal closeOnOverlayClick={false} isCentered
                onClose={onClose}
                isOpen={isOpen}
            >
                <ModalOverlay
                    bg='blackAlpha.300'
                    backdropFilter='blur(10px)'
                />
                <ModalContent maxW={630}>
                    <ModalHeader bg={'#d9d9d9'}>
                        <Flex w="100%">
                            <Heading width="35%" fontSize={16} color="#000">
                                Available Doctors
                            </Heading>
                            {/* <Heading textAlign="center" width="35%" fontSize={16} color="#000">
                                Speciality
                            </Heading> */}
                            <Heading width="30%" fontSize={16} px={5} color="#000" />
                        </Flex>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p={0} maxH="300px" overflowY="auto" >
                        <List
                            // border="solid 1px rgba(151,151,151,0.4)"
                            // borderRadius="10px"
                            // marginTop="16px"
                            overflowY="auto"
                            maxH="200px">
                            {
                                doclist?.map((item, index) => (
                                    <ListItem
                                        key={item?.id || index}
                                        display="flex"
                                        justifyContent="space-between"
                                        borderBottom="solid 1px #97979766"
                                        py={2}
                                        px={6}
                                    >
                                        <Text width="35%" fontWeight="700" fontSize="16px" textTransform='capitalize' >Dr. {item.firstName + " " + item.lastName}</Text>
                                        {/* <Text textAlign='center' fontWeight="500" width="35%" fontSize="15px">{item.speciality}</Text> */}
                                        {getSelect(item)}
                                        {/* <Link textAlign='center' width="30%" textDecoration="underline" onClick={()=>{handleselect(item)}} color={"#00AB83"}>Select</Link>
                                        <Link textAlign='center' width="30%" pr="10px">Select</Link> */}
                                    </ListItem>
                                ))
                            }
                            {/* <ListItem
                                display="flex"
                                justifyContent="space-between"
                                borderBottom="solid 1px #97979766"
                                py={2}
                                px={6}
                            >
                                <Text width="35%" fontWeight="700" fontSize="16px" >Dr. Sushma Kumar</Text>
                                <Text textAlign='center' fontWeight="500" width="35%" fontSize="15px">General Medicine</Text>
                                <Link textAlign='center' width="30%" textDecoration="underline" color={"#00AB83"}>Selected</Link>
                            </ListItem>
                            <ListItem
                                display="flex"
                                justifyContent="space-between"
                                borderBottom="solid 1px #97979766"
                                py={2}
                                px={6}
                            >
                                <Text width="35%" fontWeight="700" fontSize="16px" >Dr. Sushma Kumar</Text>
                                <Text textAlign='center' fontWeight="500" width="35%" fontSize="15px">General Medicine</Text>
                                <Link textAlign='center' width="30%" textDecoration="underline" color={"#00AB83"}>Selected</Link>
                            </ListItem>
                            <ListItem
                                display="flex"
                                justifyContent="space-between"
                                borderBottom="solid 1px #97979766"
                                py={2}
                                px={6}
                            >
                                <Text width="35%" fontWeight="700" fontSize="16px" >Dr. Sushma Kumar</Text>
                                <Text textAlign='center' fontWeight="500" width="35%" fontSize="15px">General Medicine</Text>
                                <Link textAlign='center' width="30%" textDecoration="underline" color={"#00AB83"}>Selected</Link>
                            </ListItem>
                            <ListItem
                                display="flex"
                                justifyContent="space-between"
                                borderBottom="solid 1px #97979766"
                                py={2}
                                px={6}
                            >
                                <Text width="35%" fontWeight="700" fontSize="16px" >Dr. Sushma Kumar</Text>
                                <Text textAlign='center' fontWeight="500" width="35%" fontSize="15px">General Medicine</Text>
                                <Link textAlign='center' width="30%" textDecoration="underline" color={"#00AB83"}>Selected</Link>
                            </ListItem>
                            <ListItem
                                display="flex"
                                justifyContent="space-between"
                                borderBottom="solid 1px #97979766"
                                py={2}
                                px={6}
                            >
                                <Text width="35%" fontWeight="700" fontSize="16px" >Dr. Sushma Kumar</Text>
                                <Text textAlign='center' fontWeight="500" width="35%" fontSize="15px">General Medicine</Text>
                                <Link textAlign='center' width="30%" textDecoration="underline" color={"#00AB83"}>Selected</Link>
                            </ListItem>
                            <ListItem
                                display="flex"
                                justifyContent="space-between"
                                borderBottom="solid 1px #97979766"
                                py={2}
                                px={6}
                            >
                                <Text width="35%" fontWeight="700" fontSize="16px">Dr. Sushma Kumar</Text>
                                <Text textAlign='center' width="35%" fontWeight="500" fontSize="15px">General Medicine</Text>
                                <Link textAlign='center' width="30%" pr="10px">Select</Link>
                            </ListItem> */}
                        </List>
                        {/* <Center my={8}>
                            <Button className='upload-btn' bg="transparent" >Request for Prescription</Button>
                        </Center> */}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    )
}

export default Doctorlistpopup