import * as Yup from 'yup';

export const Createtickervalidation = Yup.object().shape({
    patientphonenumber: Yup.string().required('Required')
    .min(10, 'The phone number must have 10 digits.')
    .max(10, 'The phone number must have 10 digits.'),
    patientname: Yup.string().required('Required'),
    doctorphonenumber: Yup.string()
    .min(10, 'The phone number must have 10 digits.')
    .max(10, 'The phone number must have 10 digits.')
  })