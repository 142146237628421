import React from 'react'
import { MiddleSectionWrapper } from './style';
// import TicketsTab from './ticketsTab';
// import TotalCount from './totalCount';
// import { useSelector } from 'react-redux';
import SearchComponent from './searchComponent';
import TicketsTab from './TicketsTab';
import { Box, Flex, Select } from '@chakra-ui/react';
import { AiOutlineCaretDown } from 'react-icons/ai';
import { useContext } from 'react';
import { Logincontext } from '../../../../lib/Providers/Loginstatus';

function MiddleSection() {
    // const search = useSelector(state => state.tickets?.searched || false);
    const {setStatus,setTabIndex,setSkip,status,setAlltickets} = useContext(Logincontext)

    const handleFocus = () =>{
        setTabIndex(3)
    }

    const handleChange = (e) => {
        setStatus(e.target.value)
        setSkip(0)
        setAlltickets([])
    }

    return (
        <MiddleSectionWrapper>
            <Flex 
            justifyContent='space-between'
            alignItems='center'>
                <Box width='70%'>   
            <SearchComponent />
            </Box>
            <Select
                width='15%'
                height='45px'
                bg="#fff"
                color="#9F9F9F"
                border="transparent"
                _focusVisible={{
                    boxShadow:'none'
                }}
                icon={<AiOutlineCaretDown size="1.2rem" color='#9F9F9F' />}
                onChange={(e) => handleChange(e)}
                onFocus={handleFocus}
                value={status}
            >
                <option value=''>All Orders</option>
                <option value='NEWORDER'>New Order</option>
                <option value='DOCTORCONSULTATIONPENDING'>Doctor Consulatation Pending</option>
                <option value='REJECTED'>Rejected</option>
                <option value='ORDERPLACED'>Order Placed</option>
                <option value='CONFIRM'>Confirm</option>
                <option value='READYTOSERVE'>Ready To Serve</option>
                <option value='ONTHEWAY'>On The Way</option>
                <option value='ARRIVED'>Arrived</option>
                <option value='DELIVERED'>Delivered</option>
            </Select>
            </Flex>
            {/* {!search ? */}
            <>
                <TicketsTab />
                {/* <TotalCount /> */}
            </>
            {/* : null} */}
        </MiddleSectionWrapper>
    )
}

export default MiddleSection;
