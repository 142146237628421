/* eslint-disable no-mixed-operators */
import { Box, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import React from 'react'
import { useEffect } from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import { TicketContext } from '../../../../../../lib/Providers/TicketDetails';
// import { PatientboldText, PatientLightText } from '../style'


const PatientboldText = styled.span`
font-size: 18px;
color: #000;
font-weight: 600;
`;

const PatientLightText = styled.span`
font-size: 18px;
color: gray;
font-weight:400;
`;

const CartbilldetailPopup = ({ isOpen, onClose, deliveycharges, total, mrp, setTopay, Gst,
    couponresponse, topay, discountonmrp, yousaved,
    gstpercentage, detailedticket, othermrp, otherdiscount, othertotal, medicinediscount, medicinemrp }) => {
    const { vendordetail } = useContext(TicketContext)
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Billing Details</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box px={6}>
                        <Flex justifyContent="space-between">

                            <Box>
                                <PatientLightText>Medicine Total (MRP) :</PatientLightText>
                                <br></br>
                                <PatientLightText>Medicine Price Discount :</PatientLightText>
                                <br></br>
                                <PatientLightText>Promocode Discount on Meds :</PatientLightText>
                                <br></br>
                                <PatientLightText>Other Item Total (MRP) :</PatientLightText>
                                <br></br>
                                <PatientLightText>Other Item Price Discount :</PatientLightText>
                                {vendordetail?.included_tax === "0" || vendordetail?.included_tax === "Yes" &&
                                    (
                                        <>
                                            <br></br>
                                            <PatientLightText>GST({gstpercentage}%) : </PatientLightText>
                                        </>)}
                                <br></br>
                                {detailedticket?.delivery_pickup_types?.toLowerCase() === 'delivery' ?
                                    <><PatientLightText>Delivery Charges :</PatientLightText><br></br></> : null}
                                <PatientboldText>To pay :</PatientboldText>
                                <br></br>
                                <PatientboldText>You Saved :</PatientboldText>
                                <br></br>
                            </Box>
                            <Box>
                                <PatientLightText>{medicinemrp?.toFixed(2)}</PatientLightText>
                                <br></br>
                                <PatientLightText>{medicinediscount?.toFixed(2)}</PatientLightText>
                                <br></br>
                                <PatientLightText>{discountonmrp?.toFixed(2)}</PatientLightText>
                                <br></br>
                                <PatientLightText>{othermrp?.toFixed(2)}</PatientLightText>
                                <br></br>
                                <PatientLightText>{otherdiscount}</PatientLightText>
                                {vendordetail?.included_tax === "0" || vendordetail?.included_tax === "Yes" &&
                                    (
                                        <>
                                            <br></br>
                                            <PatientLightText>{Gst?.toFixed(2)}</PatientLightText>
                                        </>)}
                                <br></br>
                                {detailedticket?.delivery_pickup_types?.toLowerCase() === 'delivery' ?
                                    <><PatientLightText>{deliveycharges?.toFixed(2) }</PatientLightText><br></br></> : null}
                                <PatientboldText>{topay?.toFixed(2)}</PatientboldText>
                                <br></br>
                                <PatientboldText>{yousaved?.toFixed(2)}</PatientboldText>
                            </Box>
                        </Flex>
                    </Box>
                </ModalBody>

                <ModalFooter>
                    <Button fontSize={14} bg="#00AB83" color="#fff" _hover={{ bg: 'black' }} onClick={onClose}>
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export default CartbilldetailPopup