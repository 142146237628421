import { Flex, Img, Button } from '@chakra-ui/react';
import React, { useContext } from 'react'
import styled from 'styled-components';
import Instamedlogo from '../../assets/logo/Instamedlogo.svg'
import { useNavigate } from 'react-router-dom';
import { Logincontext } from '../../lib/Providers/Loginstatus'
const LogoHead = styled.p`
  color: #000;
  font-family: Poppins,sans-serif;
  display: inline-block;
  font-weight: 700;
  font-size: 2vw;
  padding: 0.1em;
  margin: 0 0 0 15px;
`
const LogoHeading = () => {
    const navigate = useNavigate()
    const token = localStorage.getItem('token')
    const { setTabIndex } = useContext(Logincontext)


    const logoClick = () => {
        if (!token) {
            navigate("/")
        } else {
            navigate(setTabIndex(0))
        }
    }


    return (
        <span>
            <Flex align="center">
                <Button colorScheme='teal' variant='' onClick={logoClick}>
                    <Img src={Instamedlogo} alt="Instamedlogo" />
                    <LogoHead>InstaMed</LogoHead>
                </Button>

            </Flex>
        </span>
    )
}

export default LogoHeading