import React from 'react'
import DashboardMiddle from '../../components/DashboardElements/DashboardMiddle'
import DashboardTopbar from '../../components/DashboardElements/DashboardTopbar'
import { DashboardMainWrapper } from './styles'

const DashboardMain = () => {
    return (
        <DashboardMainWrapper>
            <DashboardTopbar />
            <DashboardMiddle />
        </DashboardMainWrapper>
    )
}

export default DashboardMain