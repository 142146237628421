import { Box, Flex, Heading, } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import CartBox from './CartBox'
import { PatientboldText, PatientLightText, UserProfileWrapper } from './style'
import { TicketContext } from '../../../../../lib/Providers/TicketDetails'
import axios from 'axios'
import Config from '../../../../../lib/Config'

const Addedcart = () => {
    const { detailedticket, setFetch, setDetailedticket } = useContext(TicketContext)
    const [preorders, setPreOrders] = useState([])

    const GetPreviousorder = async () => {
        if (detailedticket) {
            await axios.get(`${Config.Get_previous_orders}${detailedticket?.phoneNumber}`)
                .then((result) => result.data)
                .then((data) => {
                    setPreOrders(data.data)
                })
        } else {
            setPreOrders([])
        }
    }

    useEffect(() => {
        GetPreviousorder()
    }, [detailedticket])


    return (
        <UserProfileWrapper>
            <Box borderBottom="solid 1px #b0b0b0" px={6}>
                <Flex justifyContent="space-between">
                    <Box>
                        <PatientLightText>Patient name</PatientLightText>
                        <br></br>
                        <PatientboldText>{detailedticket?.patientName}</PatientboldText>
                    </Box>
                    <Box>
                        <PatientLightText>Doctor name</PatientLightText>
                        <br></br>
                        <PatientboldText>{detailedticket?.doctorName}</PatientboldText>
                    </Box>
                </Flex>
                <Heading mt={3} mb={2} fontSize={15} color="#00AB83" as="h6">Previous Orders</Heading>
            </Box>
            {
                preorders?.length > 0 ?
                    (
                        <CartBox preorders={preorders} />
                    )
                    :
                    (
                        <Box p={6}>
                            None
                        </Box>
                    )
            }

            {/* <CartBox /> */}

        </UserProfileWrapper>
    )
}

export default Addedcart