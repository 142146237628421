import SearchBar from '../../../DashboardComponents/SearchBar'
// import SearchedResults from '../../../SearchedResults';

function SearchComponent(props) {
    return (
        <>
            <SearchBar
                placeholder="Search for Patient Name , Patient Number, Ticket ID, Order ID"
                
            />
            {/* <SearchedResults /> */}
        </>
    )
};
export default SearchComponent
