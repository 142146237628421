import { Grid, GridItem, Select } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { AiOutlineCaretDown } from 'react-icons/ai'
import { TicketContext } from '../../../../../lib/Providers/TicketDetails'


const PatientSelect = (props) => {
   
const { setOutcome, outcome, setSubtopic, subtopic, setTopic, topic, setDisposition, disposition } = props                                                                                                                                                                         
    return (
        <Grid mt={8} templateColumns='repeat(2, 1fr)' gap={8}>
            <GridItem w='100%' h='100%'>
                <Select
                    bg="#F0F0F0"
                    color="#9F9F9F"
                    border="transparent"
                    _focus={{ boxShadow: 'none' }}
                    icon={<AiOutlineCaretDown size="1.2rem" color='#9F9F9F' />}
                    placeholder='Disposition'
                    onChange={(e) => { setDisposition(e.target.value) }}
                >
                    <option value={'invalid'} >Invalid </option>
                    <option value={'valid'}>Valid </option>
                </Select>
            </GridItem>
            <GridItem w='100%' h='100%'>
                <Select
                    bg="#F0F0F0"
                    color="#9F9F9F"
                    border="transparent"
                    _focus={{ boxShadow: 'none' }}
                    icon={<AiOutlineCaretDown size="1.2rem" color='#9F9F9F' />}
                    placeholder='Topic'
                    onChange={(e) => { setTopic(e.target.value) }}
                >
                    <option value='inquiry' >Inquiry </option>
                    <option value='order'>Order</option>
                    <option value='delivery'>Delivery</option>
                    <option value='storecall'>Store Call</option>
                    <option value='testcall'>Test Call</option>
                    <option value='transfer'>Transfer</option>
                </Select>
            </GridItem>
            <GridItem w='100%' h='100%'>
                <Select
                    bg="#F0F0F0"
                    color="#9F9F9F"
                    border="transparent"
                    _focus={{ boxShadow: 'none' }}
                    icon={<AiOutlineCaretDown size="1.2rem" color='#9F9F9F' />}
                    placeholder='Sub-Topic'
                    onChange={(e) => { setSubtopic(e.target.value) }}
                >
                    <option value='apprelated'>App related </option>
                    <option value='websiterealted'>Website related</option>
                    <option value='payment'>Payment</option>
                    <option value='orderinquiry'>Order Inquiry</option>
                    <option value='return'>Return</option>
                    <option value='productnotavailable'>Product Not Available</option>
                    <option value='incompleteorder'>Incomplete order</option>
                    <option value='orderconfirmation'>Order Confirmation</option>
                    <option value='deliverytime'>Delivery Time</option>
                    <option value='ambulance'>Ambulance</option>
                    <option value='neworderplaced'>New order Placed</option>
                    <option value='delayindelivery'>Delay in delivery</option>
                    <option value='cancel'>Cancel</option>
                </Select>
            </GridItem>
            <GridItem w='100%' h='100%'>
                <Select
                    bg="#F0F0F0"
                    color="#9F9F9F"
                    border="transparent"
                    _focus={{ boxShadow: 'none' }}
                    icon={<AiOutlineCaretDown size="1.2rem" color='#9F9F9F' />}
                    placeholder='Outcome'
                    onChange={(e) => { setOutcome(e.target.value) }}
                >
                    <option value='customerinformed'>Customer Informed</option>
                    <option value='complaint'>Complaint</option>
                    <option value='connected'>Connected</option>
                    <option value='disconnected'>Disconnected</option>
                    <option value='fcr'>FCR</option>
                </Select>
            </GridItem>
        </Grid>
    )
}

export default PatientSelect