import axios from 'axios'
import React, { createContext, useEffect, useState } from 'react'
import Config from '../Config'

const TicketContext = createContext()
const TicketDetails = ({ children }) => {
    const [detailedticket, setDetailedticket] = useState()
    const [vendordetail, setVendordetail] = useState()
    const [fetch, setFetch] = useState(0)
    const [docdeatils, SetDocdetils] = useState();
    const [docNameDetails, setDocNameDetails] = useState();
    const [resetPage, setResetPage] = useState(0)
    const [emptyPrescription, setEmptyPrescription] = useState(0)
    const [addressLatitude, setAddressLatitude] = useState('')
    const [addressLongitude, setAddressLongitude] = useState('')
    const [nearbystoreslist, setNearbyStoreslist] = useState([])



    const GetvendorSettings = async () => {
        await axios.post(Config.Get_vendor_settings, {
            order_web: Config.Order_web,
            is_langauge: "en",
            latitude: addressLatitude,
            longitude: addressLongitude
        })
            .then((result) => result.data)
            .then((data) => {
                setVendordetail(data.settings);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        // if (detailedticket) {
        GetvendorSettings()
        // }
    }, [addressLatitude, addressLongitude])


    return (
        <TicketContext.Provider value={{
            detailedticket, setDetailedticket, fetch, setFetch,
            vendordetail, docdeatils, SetDocdetils, setDocNameDetails, docNameDetails, resetPage, setResetPage,
            emptyPrescription, setEmptyPrescription, setAddressLongitude, setAddressLatitude,
            nearbystoreslist, setNearbyStoreslist
        }}>
            {children}
        </TicketContext.Provider>
    )
}

export { TicketContext, TicketDetails }