import styled from 'styled-components';
export const MainWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: repeat(2, auto);
`;
export const HeadWrapper = styled.div`
  `;
export const LogoBtn = styled.button`
  background-color:transparent;
  border: none; 
  outline: none;
  margin: 10px 0 0 20px;
  font-size: 2vw;
  font-weight: 700;
`;
export const TabsMainWrapper = styled.div`
  display: flex;
  witdh: 100%;
  height: 90vh;
  justify-content:center;
  align-items: center;
`;
export const TabName = styled.span`
  background-color: ${props => props.backgroundColor || "#9F9F9F"};
  border-radius: 15px 15px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  font-size: 1vw;
  height: 6vh;
  width: 8vw;
`;
export const WhiteCenterDiv = styled.div`
  background-color: #fff;
  box-shadow: 0px 4px 16px #00000029;
  border-radius: 20px;
  padding: 1.2vw 2vw 1vw 2vw;
`;
export const QuoteBookMainWrapper = styled.div`
  padding: ${props => props.isLogin ? '1rem 8rem' : '1rem'};
  width: 70%;
  margin: auto;
`;
export const CustomButton = styled.button`
background-color: rgb(29, 191, 103);
    border: none;
    color: white;
    letter-spacing: 0px;
    flex-direction: row;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 1.2vw;
    font-weight: 600;
    border-radius: 30px;
    height: 7vh;
    width: 35%;
    outline: none;
    text-align: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 0.5rem 3rem 0.5rem 0.5rem;
    margin: 2rem auto 1rem;
`;
export const ButtonSvg = styled.img`
  margin-right: 10px;
`;
export const ErrorMessage = styled.p`
  font-size: 12px;
  color: #f00;
  font-weight: 500;
  text-align: center;
 `