import React, { useRef } from 'react'
import styled from 'styled-components';
import ReactDom from "react-dom";

const modalRoot = document.getElementById("modal-root");

const Background = styled.div`
  top:0;
  width: 100%;
  height: 100%;
  background: rgba(22, 153, 158,0.3) 0% 0% no-repeat padding-box;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalWrapper = styled.div`
  width: 40%;
  height: 50vh;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  color: #000;
  display: grid;
  position: relative;
  z-index: 10;
  border-radius: 20px;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  p {
    margin-bottom: 1rem;
    color: #16999E;
    letter-spacing: 0px;
    font-weight: bold;
  }
  button {
    margin-top: 1.5rem;
  }
`;

const CloseModalButton = styled.span`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
  padding: 0;
  z-index: 10;
`;

const Popupmodel = ({showModal, setShowModal, closeModalBtn = false, children}) => {
    const modalRef = useRef();
    return (
        ReactDom.createPortal(
            <Background ref={modalRef}>
                <ModalWrapper>
                    <ModalContent>
                        {children}
                    </ModalContent>
                    {closeModalBtn && <CloseModalButton onClick={() => setShowModal(false)}>x</CloseModalButton>}
                </ModalWrapper>
            </Background>
            , modalRoot
        )
    )
}

export default Popupmodel