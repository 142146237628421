import { Box, Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Textarea } from '@chakra-ui/react'
import React, { useState } from 'react'

const RejactionPopup = ({ isOpen, onClose, handlePrintStart, setRejectionreson, rejectionreason, UpdatePrescription }) => {
    console.log('rejectionreason.length < 0 && rejectionreason.length < 800', rejectionreason?.length, !(rejectionreason?.length >= 1 && rejectionreason?.length <= 800));
    const handleReject = () => {
        UpdatePrescription('REJECTED', rejectionreason)
        onClose()
    }
    return (
        <Modal closeOnOverlayClick={false} isCentered
            onClose={onClose}
            isOpen={isOpen}
        >
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px)'
            />
            <ModalContent bg='#16999e' color='white' maxW={500}>
                <ModalHeader>
                    Rejection reason <Text as='span' fontSize={14} fontWeight={400}>(Please mention the reason for rejection)</Text>
                </ModalHeader>
                <ModalCloseButton rounded='full' border='solid 1px' fontSize={12} />
                <ModalBody textAlign='center' p={0} maxH="300px" >
                    <Textarea onChange={(e) => { setRejectionreson(e.target.value) }} color={'black'} placeholder='type here...' bg='#fff' width='90%' style={{ height: '150px' }} value={rejectionreason} />

                    <Box textAlign='center' py={6}>
                        <Button
                            isDisabled={!(rejectionreason?.length >= 1 && rejectionreason?.length <= 300)}
                            onClick={() => { handleReject() }}
                            variant='solid'
                            background='#fff'
                            color='#000'
                            rounded='md'
                            fontSize={14}
                            fontWeight={400}
                            border="solid 1px"
                            borderColor="#fff"
                            _hover={{
                                borderColor: '#fff',
                                border: 'solid 1px',
                                background: 'transparent',
                                color: '#fff'
                            }}
                        >
                            Reject and Submit
                        </Button>
                    </Box>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default RejactionPopup