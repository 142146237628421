import { Box, Button, Grid, GridItem, Heading, VStack } from '@chakra-ui/react'
import moment from 'moment'
import React from 'react'
import { TicketContext } from '../../../../../../lib/Providers/TicketDetails'
import { useContext } from 'react'
import Config from '../../../../../../lib/Config'
import axios from 'axios'

const CartBox = ({ preorders }) => {
    const { detailedticket, setFetch } = useContext(TicketContext)
    const handleaddtocart = async (items) => {
        console.log('items', items);
        for (let key of items.medicineList) {
            const medi = {
                mastermenuItemId: key.mastermenuItemId,
                medicineName: key.medicineName,
                quantity: 1,
                unit: "unit",
                mrp: key.mrp,
                price: key.price,
                hdpOrderId: detailedticket?.id,
                promcode_applicable: key?.promcode_applicable
            }
            await axios.post(Config.Add_Or_Update_Cart, medi)
                .then((data) => {
                    setFetch((pre) => pre + 1)
                })
                .catch((err) => {
                    console.log(err);
                })
        }
    }
    return (
        <>
            {preorders?.length > 0 && preorders?.map((item) => (
                <Box borderBottom="solid 1px #b0b0b0" px={6} py={2}>
                    <Grid
                        templateColumns='repeat(12, 1fr)'
                        gap={4}
                    >
                        <GridItem colSpan={7}>
                            <Heading as="h6" fontSize={14}>Tickets ID : #{item?.id}</Heading>
                            <Heading as="h6" fontSize={14} mt={1} color="#A23DFF">Order ID : #{item?.orderId}</Heading>
                            <VStack mt={4} align="flex-start">
                                <Heading color="#A23DFF" textAlign="start" as="h6" fontWeight={600} fontSize={14}>Items</Heading>
                                {item?.medicineList?.map((subitem) => (
                                    <VStack align="flex-start">
                                        <Heading textAlign="start" textTransform='capitalize' as="h6" fontWeight={600} fontSize={14}>{subitem?.medicineName}</Heading>
                                        {/* <Heading color="#b0b0b0" textAlign="start" as="h6" fontWeight={600} fontSize={10}>1 Strip of 10 tablets</Heading> */}
                                    </VStack>
                                ))}
                                {/* <VStack align="flex-start">
                                    <Heading textAlign="start" as="h6" fontWeight={600} fontSize={14}>Cropara 650mg Tablet</Heading>
                                    <Heading color="#b0b0b0" textAlign="start" as="h6" fontWeight={600} fontSize={10}>1 Strip of 10 tablets</Heading>
                                </VStack>
                                <VStack align="flex-start">
                                    <Heading textAlign="start" as="h6" fontWeight={600} fontSize={14}>Cropara 650mg Tablet</Heading>
                                    <Heading color="#b0b0b0" textAlign="start" as="h6" fontWeight={600} fontSize={10}>1 Strip of 10 tablets</Heading>
                                </VStack>
                                <VStack align="flex-start">
                                    <Heading textAlign="start" as="h6" fontWeight={600} fontSize={14}>Cropara 650mg Tablet</Heading>
                                    <Heading color="#b0b0b0" textAlign="start" as="h6" fontWeight={600} fontSize={10}>1 Strip of 10 tablets</Heading>
                                </VStack> */}
                            </VStack>
                        </GridItem>
                        <GridItem textAlign="center" colSpan={5}>
                            {/* 12 Feb 2023 */}
                            <Heading textAlign="center " as="h6" fontSize={14}>{moment(item.createdAt).format('DD MMM YYYY')}</Heading>
                            <Button mt={4} className='btn-tabs' onClick={() => { handleaddtocart(item) }}>Add to cart</Button>
                        </GridItem>
                    </Grid>
                </Box>
            ))
            }
        </>

    )
}

export default CartBox