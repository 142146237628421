import styled from 'styled-components';

export const BoxWrapper = styled.div`

`;
export const ButtonWrapper = styled.button`
text-align: center;
width: 100%;
margin: 30px 0px 0px 0;
`;
export const ButtonBox = styled.button`
text-align: center;
width: 150px;
background: #00AB83;
border-radius: 5px;
color: #fff;
height: 40px;
&:hover{
    background: #000;
}
`;