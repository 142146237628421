import React from 'react';
import { Box } from "@chakra-ui/react";

const ProgressBar = (props) => {
    const { item } = props;
    const HaveRX = item?.rx;
    const ticketStatus = item?.ticketStatus?.toUpperCase();
    const PreviousTicketStatus = item?.previousTicketStatus?.toUpperCase();

    let StaticData = {
        doctorCon: '',
        doctorConApp: '',
        orderPlaced: '',
        orderConfirmed:'',
        OntheWay:'',
    }
    if(HaveRX){
        StaticData.orderPlaced=17;
        StaticData.orderConfirmed=50;
        StaticData.OntheWay=74;
    }else{        
        StaticData.doctorCon=17;
        StaticData.doctorConApp=34;
        StaticData.orderPlaced=51;
        StaticData.orderConfirmed=68;
        StaticData.OntheWay=74;
    }
    let process
    // const GetProcessstatus = (status) => {
    //     switch (status) {
    //         case 'neworder':
    //             return process = 1;
    //         case 'DOCTORCONSULTATIONPENDING':
    //             return process = 17;
    //         case 'REJECTED':
    //             return process = 34;
    //         case 'ORDERPLACED':
    //             return process = 51;
    //         case 'Confirm':
    //             return process = 68;
    //         case 'ReadyToServe':
    //             return process = 68;
    //         case 'OnTheWay':
    //             return process = 84;
    //         case 'Arrived':
    //             return process = 84;
    //         case 'Delivered':
    //             return process = 100;
    //         case 'DELIVERED':
    //             return process = 100;
    //         case 'Cancelled':
    //             return process = 100;
    //         case null:
    //             return process = 0;
    //         default:
    //             return process = 0;
    //     }
    // }
    // if(PreviousTicketStatus !== null && PreviousTicketStatus === 'ORDERPLACED'){
    //     process = 68;
    // }

    const GetProcessstatus = (status, preStatus,) => {
        if(HaveRX){
            switch (true) {
                case (status === 'NEWORDER'):
                    return process = 1;
                case (status === 'ORDERPLACED'):
                    return process = 25;
                case (status === 'CONFIRM'):
                    return process = 50;
                case (status === 'READYTOSERVE'):
                    return process = 50;
                case (status === 'ONTHEWAY'):
                    return process = 74;
                case (status === 'ARRIVED'):
                    return process = 74;
                case (status === 'DELIVERED'):
                    return process = 100;
                case (status === 'CANCELLED' && preStatus === 'ORDERPLACED'):
                    return process = 41;
                case (status === 'CANCELLED' && (preStatus === 'READYTOSERVE' || preStatus === 'CONFIRM')):
                    return process = 60;
                case (status === null):
                    return process = 0;
                default:
                    return process = 0;
            }
        }else{
            switch (true) {
                case (status === 'NEWORDER'):
                    return process = 1;
                case (status === 'DOCTORCONSULTATIONPENDING'):
                    return process = 17;
                case (status === 'REJECTED'):
                    return process = 34;
                case (status === 'ORDERPLACED'):
                    return process = 51;
                case (status === 'CONFIRM'):
                    return process = 68;
                case (status === 'READYTOSERVE'):
                    return process = 68;
                case (status === 'ONTHEWAY'):
                    return process = 84;
                case (status === 'ARRIVED'):
                    return process = 84;
                case (status === 'DELIVERED'):
                    return process = 100;
                case (status === 'CANCELLED' && preStatus === 'ORDERPLACED'):
                    return process = 57;
                case (status === 'CANCELLED' && (preStatus === 'READYTOSERVE' || preStatus === 'CONFIRM')):
                    return process = 72;
                case (status === null):
                    return process = 0;
                default:
                    return process = 0;
            }
        }
        
    }

    return (
        <>
            <Box w={`${GetProcessstatus(ticketStatus, PreviousTicketStatus)}%`} className="timeline-progress"></Box>
            {/* <Box w={`${process}%`} className="timeline-progress"></Box> */}
            <Box className="timeline-items">
                <Box className={process >= 1 ? "timeline-item active" : "timeline-item"}>
                    <Box className="timeline-content">
                        New Order
                    </Box>
                </Box>
                {item?.rx ? null : <Box className={process >= StaticData.doctorCon ? "timeline-item active" : "timeline-item"}>
                    <Box className="timeline-content">
                        Doctor Consulatation Pending
                    </Box>
                </Box>}
                {item?.rx ? null : <Box className={process >= StaticData.doctorConApp && ticketStatus === 'REJECTED' ? 'timeline-item-rejected' : process >= 34 ? "timeline-item active" : "timeline-item"}>
                    <Box className="timeline-content">
                        {/* Doctor Consulatation Approved */}
                        {item?.ticketStatus === 'REJECTED' ? 'Rejected' : "Doctor Consulatation Approved"}
                    </Box>
                </Box>}
                <Box className={process >= StaticData.orderPlaced ? "timeline-item active" : "timeline-item"}>
                    <Box className="timeline-content">
                        Order Placed
                    </Box>
                </Box>
                {PreviousTicketStatus !== null && PreviousTicketStatus === 'ORDERPLACED' && ticketStatus === 'CANCELLED' ?
                    <Box className={ticketStatus === 'CANCELLED' ? 'timeline-item-rejected' : "timeline-item"}>
                        <Box className="timeline-content">
                            Cancelled
                        </Box>
                    </Box> : null}
                <Box className={process >= StaticData.orderConfirmed && (PreviousTicketStatus !== 'ORDERPLACED' || ticketStatus !== 'CANCELLED') ? "timeline-item active" : "timeline-item"}>
                    <Box className="timeline-content">
                        Order confirmed by store
                    </Box>
                </Box>
                {PreviousTicketStatus !== null && (PreviousTicketStatus === 'READYTOSERVE' || PreviousTicketStatus === 'CONFIRM')  && ticketStatus === 'CANCELLED' ?
                    <Box className={ticketStatus === 'CANCELLED' ? 'timeline-item-rejected' : "timeline-item"}>
                        <Box className="timeline-content">
                            Cancelled
                        </Box>
                    </Box> : null}
                <Box className={process >= StaticData.OntheWay ? "timeline-item active" : "timeline-item"}>
                    <Box className="timeline-content">
                        On way
                    </Box>
                </Box>
                <Box className={process >= 100 ? "timeline-item active" : "timeline-item"}>
                    <Box className="timeline-content">
                        Delivered
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ProgressBar;