import { Box, Button, Grid, GridItem } from '@chakra-ui/react'
import React, { useState } from 'react'
import Addedcart from './Addedcart'
import CartAddbox from './CartAddbox'
import Searchmedician from './Searchmedician'

const OrderPlacing = (props) => {
    const { tabchange } = props
    const [show, setShow] = useState(false)


    return (
        <Grid templateColumns='repeat(2, 1fr)' gap={0} h='100%'>
            <GridItem py={4} pl={4} pr={0} w='100%' h='100%'>
                {show && <Searchmedician setShow={setShow} />}
                {!show && (
                    <>
                        <Addedcart />
                        <Box borderTop="solid 1px #b0b0b0" textAlign="center" pt={8} pb={2} mr={4} ml={0}>
                            <Button className='btn-tabs' onClick={()=>{setShow(!show)}}>Add new Items</Button>
                        </Box>
                    </>)}
            </GridItem>
            <GridItem borderLeft="solid 2px #b0b0b0" p={4} w='100%' h='100%'>
                <CartAddbox tabchange={tabchange} />
            </GridItem>
        </Grid>
    )
}

export default OrderPlacing