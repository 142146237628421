import React from 'react'
import ChatSec from './ChatSection'
import DashboardMain from './DashboardMain'
import { DashboardWrapper } from './styles'

const Dashboardpage = () => {
    return (
        <DashboardWrapper>
            <DashboardMain />
            <ChatSec />
        </DashboardWrapper>
    )
}

export default Dashboardpage