import { Box, Button, Flex, IconButton, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, Textarea, useBreakpointValue } from '@chakra-ui/react'
import React, { useState } from 'react'
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";

const Oldprescirptionpopup = ({ isOpen, onClose, Pimages }) => {
    // console.log('Pimages', Pimages);
    const [slider, setSlider] = useState()
    const top = useBreakpointValue({ base: '90%', md: '50%' })
    const side = useBreakpointValue({ base: '30%', md: '10px' })
    return (
        <Modal closeOnOverlayClick={false} isCentered
            onClose={onClose}
            isOpen={isOpen}
            size={'full'}
        >
            <ModalOverlay
                bg='blackAlpha.300'
                backdropFilter='blur(10px)'
            />
            <ModalContent bg='#fff' color='black' w={'100%'} h={'100vh'} >
                <ModalHeader>
                    Prescription
                </ModalHeader>
                <ModalCloseButton rounded='full' border='solid 1px' fontSize={12} />
                <ModalBody>
                    <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                        {Pimages?.map((item) => {
                            return (
                                <>
                                    <SwiperSlide>
                                        <img    
                                            src={item.prescription_link}
                                        />
                                    </SwiperSlide>
                                </>
                            );
                        })}
                    </Swiper>
                </ModalBody>
            </ModalContent >
        </Modal >

    )
}

export default Oldprescirptionpopup