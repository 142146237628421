const Config = {
    // Auth_Url: process.env.REACT_APP_HDP_INSTAMED_AUTH_SERVICE_STAGING,
    Auth_Url: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/login`,
    Create_Ticket: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/Ticket/createTicket`,
    Map_Key_Api: `${process.env.REACT_APP_HDP_INSTAMED_MAP_API_KEY}`,
    Upload_File: `${process.env.REACT_APP_HDP_UPLOAD_FILE}/api`,
    Upadte_Ticket: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/Ticket/updateTicket`,
    Get_All_Tickets: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/Ticket/getAllTicketdetails`,
    Get_Medicine_By_Name: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/instamedicines/getMedicinesBymedicineName?medicineName=`,
    Get_Medicine_By_Id: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/instamedicines/getmedicinesbyId/`,
    Get_TicketDetail_By_Id: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/Ticket/getTicketdetailsById/`,
    Add_Or_Update_Cart: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/Ticket/addOrDeleteMedicinesToCart`,
    Get_Cart_by_TicketId: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/Ticket/getCartByTicketId/`,
    Get_Available_Doctos_List: `${process.env.REACT_APP_UMEED_AUTH_DEV_API}v1/doctor/company-consultant`,
    File_Upload: `${process.env.REACT_APP_INSTAMED_STATGING_API}upload_order_image_public`,
    Delete_Cart: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/Ticket/deleteCartByTicketId/`,
    Get_vendor_settings: `${process.env.REACT_APP_INSTAMED_STATGING_API}get-vendor-settings`,
    Apply_Coupon: `${process.env.REACT_APP_INSTAMED_STATGING_API}apply_coupon`,
    Order_web: process.env.REACT_APP_ORDER_WEB,
    Search_Tickets: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/Ticket/searchTicket/`,
    Get_previous_orders: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/Ticket/previousOrder/`,
    Get_Chatcard_Data: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/Ticket/getLeads`,
    Get_Patient_details: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/Ticket/latestTicket`,
    Remove_Upload: `${process.env.REACT_APP_INSTAMED_STATGING_API}remove_order_image_public`,
    Get_Doctor_List_Insta_Doc: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/employee/getDoctorList`,
    Get_Doctor_Consultation_Request: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/doctorConsultation/getConsultation`,
    Get_Doctor_Detail_By_ID: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/employee/getEmployeeById`,
    Get_Consulttation_Details: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/doctorConsultation/getConsultationDetailsById/`,
    Update_Consultation_Prescription: `${process.env.REACT_APP_HDP_INSTAMED_STAGING}/doctorConsultation/UpdateConsultation/`,
    Get_Store_List: `${process.env.REACT_APP_INSTAMED_STATGING_API}store_list`,
}

export default Config