import { Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { Logincontext } from '../../../../lib/Providers/Loginstatus'
import CaseDespction from './CaseDespoction'
import CustomerInfo from './CustomerInfoTab/CustomerInfo'
import LiveTicketCard from './LiveTicketcard'
import LiveTicketDetails from './LiveTicketcard/LiveTicketDetails'
import OrderPlacing from './OrderPlaceTab'
import { TabContainer, TicketsCountText } from './style'
const TicketsTab = () => {
    // const [tabIndex, setTabIndex] = useState(0);
    const [showdeatils, setShowdetails] = useState(false)
    const [ticketdetail, setTicketdetails] = useState()
    const { tabIndex, setTabIndex, setStatus, setAlltickets, status } = useContext(Logincontext)

    console.log('dddd ticket', ticketdetail);

    const tabchange1 = () => {
        setTabIndex(1)
    }
    const tabchange2 = () => {
        setTabIndex(2)
    }
    const tabchange3 = () => {
        setTabIndex(3)
    }
    const handleTabsChange = (index) => {
        setTabIndex(index)
        if (index !== 3 && status !== '') {
            setStatus('')
            setAlltickets([])
        }
    }

    return (
        <TabContainer>
            {/* <TicketsCountText>You have <b>8 Live Tickets</b> with  <b>3 Follow-ups, 5 on-going trips  and</b> 2 Scheduled Calls</TicketsCountText> */}
            <Tabs variant='enclosed' index={tabIndex} onChange={handleTabsChange} >
                <TabList className='middle-tab'>
                    <Tab id='info'>1. Customer Info</Tab>
                    <Tab id='orderplacing'>2. Order Placing</Tab>
                    <Tab id='case'>3. Case Disposition</Tab>
                    <Tab id='all'>4. All Tickets</Tab>
                </TabList>
                <TabPanels
                    height={500}
                    maxH={500}
                    overflowY="auto"
                    bg="#fff"
                    borderBottomRadius="20px"
                >
                    <TabPanel id='info' p={"2rem"}>
                        <CustomerInfo tabchange={tabchange1} />
                    </TabPanel>
                    <TabPanel id='orderplacing' p={0}>
                        {/* <OrderPlacing tabchange={tabchange2} /> */}
                        <OrderPlacing tabchange={tabchange3} />
                    </TabPanel>
                    <TabPanel id='case' p={"2rem"}>
                        <CaseDespction tabchange={tabchange3} />
                    </TabPanel>
                    <TabPanel id='all' >
                        {showdeatils &&
                            <LiveTicketDetails setShowdetails={setShowdetails} ticketdetail={ticketdetail} />}

                        {!showdeatils &&
                            <VStack>
                                <LiveTicketCard setShowdetails={setShowdetails} showdeatils={showdeatils} setTicketdetails={setTicketdetails} />
                                {/* <LiveTicketCard setShowdetails={setShowdetails} process={60} /> */}
                                {/* <LiveTicketCard setShowdetails={setShowdetails} process={100} /> */}
                            </VStack>}
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </TabContainer>
    )
}

export default TicketsTab