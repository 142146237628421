import { Box } from '@chakra-ui/react'
import React, { useState } from 'react'
import DatePicker from 'react-date-picker'
import styles from './styles'

const Datepicker = ({ width, date, onChange, format }) => {
    // console.log('date', date);
    return (
        <>
            <Box w={width}>
                {styles()}
                <DatePicker
                    value={date}
                    onChange={onChange}
                    format={format}
                    clearIcon={null}
                />
            </Box>
        </>
    )
}

export default Datepicker