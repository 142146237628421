import { Flex, FormLabel, Button, FormControl, FormErrorMessage, Grid, GridItem, Image, Input, Textarea, useDisclosure, Text, Box, Select } from '@chakra-ui/react'
import React, { useContext, useState, useEffect, useRef } from 'react'
import { TicketContext } from '../../../../../lib/Providers/TicketDetails';
/* eslint-disable no-unused-expressions */
import { BoxWrapper, ButtonBox, ButtonWrapper } from './style'
// import { FlexWrapper } from './style'
import SwitchToggle from './SwitchToggle';
import { useFormik } from 'formik'
import { Createtickervalidation } from '../../../../../lib/Validators/Createticketvalidation';
import Config from '../../../../../lib/Config';
import axios from 'axios';
import Autocomplete from 'react-google-autocomplete';
import Compressor from 'compressorjs'
import { useToast } from '@chakra-ui/react'
import Doctorlistpopup from './Doctorlistpopup';
import { AiOutlineCaretDown } from 'react-icons/ai';
import TakeawayToggle from './TakeawayToggle';

const Newticket = (props) => {
    const { tabchange, SetDocdetils, setSelecteddoc, slecteddoc } = props
    const Toast = useToast()
    // const uploadURL = `${Config.Upload_File}/v1/file/upload`;
    const uploadURL = Config.File_Upload
    const removeURL = Config.Remove_Upload
    const hiddenFileInput = useRef(null);
    const token = localStorage.getItem('token')
    const id = localStorage.getItem('userid')
    const name = localStorage.getItem('userName')
    const apiKey = Config.Map_Key_Api;
    const { detailedticket, setDetailedticket, resetPage, setDocNameDetails, emptyPrescription, vendordetail } = useContext(TicketContext)
    const { id: ticketId,
        phoneNumber, patientName, emailId, address, longitude, latitude,
        pincode, doctorName, doctorPhoneNumber, doctorRegistrationNumber,
        doctorAddress, rx, prescriptionLink, prescriptionNote, medicineList, ticketStatus,
        createdAt, updatedAt, orderByAgent, orderImagesid, location, sourceType, delivery_pickup_types, orderImages } = detailedticket
    // console.log(prescriptionLink?.length > 0, "prescriptionLink");
    const [checked, setChecked] = useState(rx);
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [loading, setLoading] = useState()
    const [fileDetail, setFileDetail] = useState(orderImages);
    const [prescription, setPrescription] = useState(prescriptionLink);
    const [prescriptionId, setPrescriptionId] = useState(orderImagesid);
    // const [slecteddoc, setSelecteddoc] = useState();
    const [leadSource, setLeadSource] = useState(sourceType);
    const [defaultValueSelect, setDefaultValueSelect] = useState(sourceType)
    const [deliveryType, setDeliveryType] = useState(delivery_pickup_types);

    const reg = new RegExp('^[0-9]+$');
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            patientphonenumber: phoneNumber || '',
            patientname: patientName || '',
            // patientemailid: emailId || '',
            patientaddress: address || '',
            longitude: longitude || '',
            lattitude: latitude || '',
            patientpincode: pincode || '',
            doctorname: doctorName || '',
            doctorphonenumber: doctorPhoneNumber || '',
            doctorregistrationnumber: doctorRegistrationNumber || '',
            doctoradress: doctorAddress || '',
            haveRx: rx,
            patientlocation: location || '',
        },
        validationSchema: Createtickervalidation,
        onSubmit: async (values) => {
            let obj = {
                id: ticketId,
                name: name,
                phoneNumber: values.patientphonenumber,
                patientName: values.patientname,
                // emailId: values.patientemailid,
                address: values.patientaddress,
                location: values.patientlocation,
                longitude: values.longitude,
                latitude: values.lattitude,
                pincode: values.patientpincode,
                doctorName: values.haveRx === true ? values?.doctorname : slecteddoc? `${slecteddoc?.firstName} ${slecteddoc?.lastName}` : values?.doctorname,
                doctorPhoneNumber: values.haveRx === true ? values.doctorphonenumber : slecteddoc ? slecteddoc?.mobileNumber : values.doctorphonenumber,
                doctorRegistrationNumber: values.haveRx === true ? values.doctorregistrationnumber : slecteddoc ? slecteddoc?.registrationNumber : values.doctorregistrationnumber,
                rx: values.haveRx,
                prescriptionLink: values.haveRx === true ? null : '',
                orderImagesid: values.haveRx === true ? null : '',
                orderImages: values.haveRx === true ? fileDetail : [],
                doctorAddress: values.doctoradress,
                prescriptionNote: prescriptionNote,
                ticketStatus: ticketStatus,
                medicineList: medicineList,
                orderByAgent: orderByAgent,
                createdAt: createdAt,
                updatedAt: updatedAt,
                sourceType: leadSource,
                delivery_pickup_types: deliveryType === "Takeaway" ? "Pickup" : deliveryType,
            }
            console.log('onjupdate', obj);
            try {
                const res = await axios.post(`${Config.Upadte_Ticket}/${ticketId}?imageChanged=true`, obj
                    ,
                    {
                        headers: {
                            authorization: `Bearer ${token}`,
                        }
                    }
                )
                setDetailedticket(res?.data?.data)
                setFileDetail([])
                tabchange()
            }
            catch (error) {
                console.log(error, "rtrt");

            }
        }
    })

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        errors,
        touched,
        // setFieldTouched,
        resetForm,
    } = formik;

    const UploadPrescription = async (event) => {
        const selectedFiles = event.currentTarget.files;
        const totalImg = selectedFiles?.length + fileDetail?.length;
        if (totalImg > vendordetail?.order_image_upload_limit) {
            Toast({
                position: "top-right",
                description: `You can upload a maximum of ${vendordetail?.order_image_upload_limit} images`,
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } else {
            const formData = new FormData();
            for (let i = 0; i < selectedFiles.length; i++) {
                const file = selectedFiles[i];
                const fileSize = file.size;
                const filetype = file.type;

                // console.log(file, "file2");

                if (filetype !== "application/pdf") {
                    if (fileSize < 10000000) {
                        try {
                            const compressedResult = await new Promise((resolve) => {
                                new Compressor(file, {
                                    quality: 0.1,
                                    success: (result) => resolve(result),
                                });
                            });
                            formData.append(`image[${i}]`, compressedResult);
                        } catch (error) {
                            console.error(error);
                        }
                    } else {
                        Toast({
                            position: "top-right",
                            description: "Please upload images less than 10MB",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        });
                    }
                } else {
                    if (fileSize < 5000000) {
                        formData.append(`image[${i}]`, file);
                    } else {
                        Toast({
                            position: "top-right",
                            description: "Please upload images less than 5MB",
                            status: "error",
                            duration: 5000,
                            isClosable: true,
                        });
                    }
                }
                if (i === selectedFiles.length - 1) {
                    proceedUpload(formData);
                }
            }
        }
    };

    const proceedUpload = async (formData) => {
        // console.log(Object.fromEntries(formData), "dfnnfgjngfjnjfngjnfgjnfgjnnjndfjnfjgn")
        setLoading(true);
        // const formData = new FormData();
        // const metaData2 = new File([file], file.name, {
        //   type: file.type,
        // });
        // formData.append("image[0]", metaData2);

        var user = 'auth@anahad.in';
        var password = 'pass@12345';
        var buff = Buffer.from(user + ':' + password)
        // var buff = newBuffer(user + ':' + password);
        var base64encodedData = buff.toString('base64');
        // formData.append('userID', id);
        // formData.append('serviceName', "DriverApp");
        return axios.post(uploadURL, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Basic ' + base64encodedData,
            },
        })
            .then((result) => result.data)
            .then((data) => {
                setLoading(false)
                // setFileDetail(data.imagedata);
                let imagedetails = []
                data?.imagedata?.map((item) => (
                    imagedetails.push({
                        order_image_id: item?.id,
                        prescription_link: item?.image
                    })
                ))
                setFileDetail(prevFileDetail => [...prevFileDetail, ...imagedetails]);
                Toast({
                    position: 'top-right',
                    description: 'File Uploaded Successfully',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .catch((err) => {
                setLoading(false)
                Toast({
                    position: 'top-right',
                    description: 'Something Went Wrong!!',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                console.log(err, "error");
            })
    };

    const removeImage = async (id) => {
        // console.log(id, "removeImage");
        var user = 'auth@anahad.in';
        var password = 'pass@12345';
        var buff = Buffer.from(user + ':' + password)
        var base64encodedData = buff.toString('base64');
        return axios.post(removeURL, { imageid: id },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Basic ' + base64encodedData,
                },
            })
            .then((result) => result.data)
            .then((data) => {
                setLoading(false)
                // console.log(fileDetail, "djfbhbfgh");
                setFileDetail(prevData => prevData.filter(item => item.order_image_id !== id));
                Toast({
                    position: 'top-right',
                    description: 'File Removed Successfully',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            })
            .catch((err) => {
                setLoading(false)
                Toast({
                    position: 'top-right',
                    description: 'Something Went Wrong!!',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                console.log(err, "error");
            })
    }

    useEffect(() => {
        resetForm()
        // setChecked(false)
        setSelecteddoc()
        setDocNameDetails('')
    }, [resetPage])

    useEffect(() => {
        // setChecked(rx)
        setPrescription('')
        setPrescriptionId('')
    }, [emptyPrescription])



    return (
        <>
            <Doctorlistpopup onClose={onClose} isOpen={isOpen} slecteddoc={slecteddoc} setSelecteddoc={setSelecteddoc} SetDocdetils={SetDocdetils} />
            <BoxWrapper>
                <form onSubmit={handleSubmit}>
                    <Grid templateColumns='repeat(2, 1fr)' rowGap={4} columnGap={8}>
                        <input
                            type="file"
                            ref={hiddenFileInput}
                            onChange={UploadPrescription}
                            style={{ display: 'none' }}
                            accept="image/png, image/jpg, image/jpeg" //  , application/pdf"
                            multiple
                            onClick={(event) => {
                                event.target.value = null
                            }}
                        />
                        <GridItem w='100%' >
                            <FormControl isInvalid={errors.patientphonenumber && touched.patientphonenumber}>
                                <Flex align="center">
                                    <FormLabel width="33%" fontSize={14} fontWeight={400}>Phone No.</FormLabel>
                                    <Input width="68%" bg="#E9F4ED" variant='filled' id='patientphonenumber' name='patientphonenumber' maxLength={10} value={values.patientphonenumber} onChange={(e) => { reg.test(e.target.value) || !e.target.value ? handleChange(e) : null }} onBlur={handleBlur} />
                                </Flex>
                                <FormErrorMessage paddingLeft="35%">{errors.patientphonenumber}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%' >
                            <FormControl isInvalid={errors.doctorname && touched.doctorname} >
                                <Flex align="center">
                                    <FormLabel width="33%" fontSize={14} fontWeight={400}>Doctor Name</FormLabel>
                                    <Input width="68%" bg="#E9F4ED" variant='filled' id='doctorname' name='doctorname' value={slecteddoc ? `${slecteddoc?.firstName} ${slecteddoc?.lastName}` : values.doctorname} onChange={handleChange} onBlur={handleBlur} disabled={!checked} />
                                </Flex>
                                <FormErrorMessage>{errors.doctorname}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%' >
                            <FormControl isInvalid={errors.patientname && touched.patientname}>
                                <Flex align="center">
                                    <FormLabel width="33%" fontSize={14} fontWeight={400}>Patient name</FormLabel>
                                    <Input width="68%" bg="#E9F4ED" variant='filled' id='patientname' name='patientname' value={values.patientname} onChange={handleChange} onBlur={handleBlur} />
                                </Flex>
                                <FormErrorMessage>{errors.patientname}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%' >
                            <FormControl isInvalid={errors.doctorphonenumber && touched.doctorphonenumber}>
                                <Flex align="center">
                                    <FormLabel width="33%" fontSize={14} fontWeight={400}>Phone No.</FormLabel>
                                    <Input width="68%" bg="#E9F4ED" variant='filled' maxLength={10} id='doctorphonenumber' name='doctorphonenumber' value={slecteddoc ? slecteddoc?.mobileNumber : values.doctorphonenumber} onChange={(e) => { reg.test(e.target.value) || !e.target.value ? handleChange(e) : null }} onBlur={handleBlur} disabled={!checked} />
                                </Flex>
                                <FormErrorMessage paddingLeft="35%">{errors.doctorphonenumber}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        {/* <GridItem w='100%' >
                            <FormControl isInvalid={errors.patientemailid && touched.patientemailid} >
                                <Flex align="center">
                                    <FormLabel width="33%" fontSize={14} fontWeight={400}>Email ID</FormLabel>
                                    <Input width="68%" bg="#E9F4ED" variant='filled' type="email" id='patientemailid' name='patientemailid' value={values.patientemailid} onChange={handleChange} onBlur={handleBlur} />

                                </Flex>
                                <FormErrorMessage>{errors.patientemailid}</FormErrorMessage>
                            </FormControl>
                        </GridItem> */}
                        <GridItem>
                            <FormControl isInvalid={errors.patientlocation && touched.patientlocation}>
                                <Flex align="center">
                                    <FormLabel width="33%" fontSize={14} fontWeight={400}>Location</FormLabel>
                                    {/* <Textarea width="68%" bg="#E9F4ED" variant='filled' id='patientaddress' name='patientaddress' value={values.patientaddress} onChange={handleChange} onBlur={handleBlur} /> */}
                                    <Autocomplete
                                        className='inputnm'
                                        apiKey={apiKey}
                                        id="patientlocation"
                                        name="patientlocation"
                                        style={{ width: "68%" }}
                                        // width="68%" 
                                        height="100px"
                                        bg="#E9F4ED"
                                        variant='filled'
                                        placeholder=""
                                        options={{
                                            types: 'places',
                                            componentRestrictions: { country: 'in' },
                                        }}
                                        onPlaceSelected={(place) => {
                                            setFieldValue(
                                                'patientlocation',
                                                place.formatted_address
                                            );
                                            setFieldValue(
                                                'lattitude',
                                                place.geometry.location.lat()
                                            );
                                            setFieldValue(
                                                'longitude',
                                                place.geometry.location.lng()
                                            );
                                        }}
                                        value={values.patientlocation}
                                        onChange={handleChange}
                                        onBlur={(e) => handleBlur(e)}
                                    />
                                </Flex>
                                <FormErrorMessage>{errors.patientlocation}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%' >
                            <FormControl isInvalid={errors.doctorregistrationnumber && touched.doctorregistrationnumber}>
                                <Flex align="center">
                                    <FormLabel width="33%" fontSize={14} fontWeight={400}>Registration
                                        No.</FormLabel>
                                    <Input width="68%" bg="#E9F4ED" variant='filled' id='doctorregistrationnumber' name='doctorregistrationnumber' value={slecteddoc ? slecteddoc?.registrationNumber : values.doctorregistrationnumber} onChange={handleChange} onBlur={handleBlur} disabled={!checked} />

                                </Flex>
                                <FormErrorMessage>{errors.doctorregistrationnumber}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%' >
                            <FormControl isInvalid={errors.patientaddress && touched.patientaddress}>
                                <Flex align="center">
                                    <FormLabel width="33%" fontSize={14} fontWeight={400}>Address</FormLabel>
                                    <Input width="68%" bg="#E9F4ED" type="text" variant='filled' id='patientaddress'
                                        name='patientaddress' value={values.patientaddress}
                                        onChange={(e) => { handleChange(e) }} onBlur={handleBlur} />
                                </Flex>
                                <FormErrorMessage>{errors.patientaddress}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%' >
                            <FormControl isInvalid={errors.doctoradress && errors.doctoradress}>
                                <Flex align="center">
                                    <FormLabel width="33%" fontSize={14} fontWeight={400}>Doc
                                        Address</FormLabel>
                                    <Input width="68%" bg="#E9F4ED" variant='filled' id='doctoradress' name='doctoradress' value={values.doctoradress} onChange={handleChange} onBlur={handleBlur} disabled={!checked} />
                                    {/* <Textarea width="68%" bg="#E9F4ED" variant='filled' id='doctoradress' name='doctoradress' value={values.doctoradress} onChange={handleChange} onBlur={handleBlur} /> */}
                                    {/* <Autocomplete
                                        className='inputnm'
                                        // apiKey={apiKey}
                                        id="doctoradress"
                                        name="doctoradress"
                                        style={{ width: "68%" }}
                                        // width="68%"
                                        height="100px"
                                        bg="#E9F4ED"
                                        variant='filled'
                                        placeholder=" "
                                        // options={{
                                        //     types: 'places',
                                        //     componentRestrictions: { country: 'in' },
                                        // }}
                                        // onPlaceSelected={(place) => {
                                        //     setFieldValue(
                                        //         'doctoradress',
                                        //         place.formatted_address
                                        //     );
                                        // }}
                                        value={values.doctoradress}
                                        onChange={handleChange}
                                        onBlur={(e) => handleBlur(e)}
                                        disabled={!checked}
                                    /> */}
                                </Flex>
                                <FormErrorMessage>{errors.doctoradress}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%' >
                            <FormControl isInvalid={errors.patientpincode && touched.patientpincode}>
                                <Flex align="center">
                                    <FormLabel width="33%" fontSize={14} fontWeight={400}>Pincode</FormLabel>
                                    <Input width="68%" bg="#E9F4ED" type="text" maxLength={"6"} variant='filled' id='patientpincode'
                                        name='patientpincode' value={values.patientpincode}
                                        onChange={(e) => { reg.test(e.target.value) || !e.target.value ? handleChange(e) : null }} onBlur={handleBlur} />
                                </Flex>
                                <FormErrorMessage>{errors.patientpincode}</FormErrorMessage>
                            </FormControl>
                        </GridItem>
                        <GridItem w='100%'>
                            <Flex align="center">
                                <FormLabel width="33%" fontSize={14} fontWeight={400}>Lead Source
                                </FormLabel>
                                <Box width="68%" display="flex" justifyContent="space-between" alignItems="center">
                                    <Select
                                        width='200%'
                                        bg="#E9F4ED"
                                        color="#9F9F9F"
                                        border="transparent"
                                        _focus={{ boxShadow: 'none' }}
                                        icon={<AiOutlineCaretDown size="1.2rem" color='#9F9F9F' />}
                                        placeholder='Lead Source'
                                        onChange={(e) => { setLeadSource(e.target.value) }}
                                        defaultValue={defaultValueSelect}
                                    >
                                        <option value='Whatsapp'>WhatsApp</option>
                                        <option value='Call'>Call</option>
                                    </Select>
                                </Box>
                            </Flex>
                        </GridItem>
                        <GridItem w='100%' >
                            <Flex align="center">
                                <FormLabel width="33%" fontSize={14} fontWeight={400}>Select Type
                                </FormLabel>
                                <TakeawayToggle setDeliveryType={setDeliveryType} defaultType={detailedticket?.delivery_pickup_types === "Pickup" ? "Takeaway" : detailedticket.delivery_pickup_types} />
                            </Flex>
                        </GridItem>
                        <GridItem w='100%' >
                            <Flex align="center">
                                <FormLabel width="33%" fontSize={14} fontWeight={400}>Do you
                                    have Rx?
                                </FormLabel>
                                <Box width="68%">
                                    <Flex mb={2}>
                                        {fileDetail?.length > 0 && fileDetail?.map((image, index) => {
                                            return (
                                                <Box key={index} position='relative'>
                                                    <Image w='100px' h='100px' mr={3} src={image?.prescription_link} />
                                                    <Box as='span' position='absolute' top={'-8px'} right={'4px'} bg={'red'}
                                                        borderRadius={10} color='#fff' w={5} h={5} textAlign={'center'}
                                                        lineHeight={1} cursor={'pointer'} onClick={() => removeImage(image.order_image_id)}>x</Box>
                                                </Box>
                                            )
                                        })}
                                    </Flex>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <SwitchToggle setFieldValue={setFieldValue} setChecked={setChecked} checked={checked} setSelecteddoc={setSelecteddoc} setFileDetail={setFileDetail} />
                                        {checked === true ?
                                            <Button
                                                w="68%"
                                                className='upload-btn'
                                                fontSize="md !important"
                                                // isLoading={loading}
                                                // loadingText='Loading'
                                                // spinnerPlacement='start' 
                                                onClick={() => hiddenFileInput.current.click()}> Upload Prescription </Button> :

                                            (
                                                <>
                                                    {/* <Box w="68%"> */}
                                                    <Button
                                                        w="68%"
                                                        fontSize="md !important"
                                                        className='upload-btn'
                                                        position="inherit"
                                                        onClick={onOpen} > View Available Doctors </Button>

                                                    {/* </Box> */}
                                                </>
                                            )
                                        }
                                    </Box>
                                </Box>
                            </Flex>
                        </GridItem>
                        <GridItem />
                        <GridItem >
                            {!checked && slecteddoc?.id && <Text textAlign="center" fontSize={"16px"} textTransform={'capitalize'} > <b>{`Dr. ${slecteddoc?.firstName} ${slecteddoc?.lastName}`}</b>  is Selected</Text>}
                        </GridItem>

                    </Grid>
                    <ButtonWrapper>
                        <Button
                            textAlign='center'
                            w='150px'
                            bg='#00AB83'
                            color="#fff"
                            borderRadius='5px'
                            height='40px'
                            type='submit'
                            isLoading={loading}
                            loadingText='Loading'
                            spinnerPlacement='start'
                            _hover={{ background: "#000" }}>
                            Update & Next
                        </Button>
                    </ButtonWrapper>
                </form>
            </BoxWrapper>
        </>
    )
}

export default Newticket