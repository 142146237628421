import styled from "styled-components";

const LiveTicketCardContainer = styled.div`
  border-radius: 5px;
  padding: 16px 15px;
  border: solid 1px #C2C2C2;
`;
const LiveTicketDetailsSpan = styled.span`
  font-size: 13px;
  p {
    margin: 0px;
    &.b-text{
      font-weight: 700;
    }
  }
`;

export {
  LiveTicketCardContainer,
  LiveTicketDetailsSpan
};