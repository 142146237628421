import {
    Box, Button, Card, CardBody, CardFooter, Flex, Grid, GridItem, Heading, HStack, Image, Stack, Text, VStack
} from '@chakra-ui/react'
import axios from 'axios'
import React, { useContext } from 'react'
import Placeholder from '../../../../../../assets/logo/Placeholder.svg'
import Config from '../../../../../../lib/Config'
import { Cartcontext } from '../../../../../../lib/Providers/CartFormation'
import { TicketContext } from '../../../../../../lib/Providers/TicketDetails'
import { PatientboldText, PatientLightText } from '../style'
import Discription from './Discription'

const Searchmedi = (props) => {
    const { displayingmedicine, daata, setDaata, setDisplayingmedicine, setSelectedmedi } = props
    const { id, medicineName, description, price, mrp, pieces, customDetailsMappers, custMap } = displayingmedicine
    // console.log('display', description);
    const { detailedticket, setFetch } = useContext(TicketContext)
    const Handleaddtocart = async (displayingmedicine) => {
        console.log('displ', displayingmedicine);
        const medi = {
            mastermenuItemId: displayingmedicine.id,
            medicineName: displayingmedicine.medicineName,
            quantity: 1,
            unit: "unit",
            mrp: displayingmedicine.mrp,
            price: displayingmedicine.price,
            hdpOrderId: detailedticket?.id,
            promcode_applicable: displayingmedicine?.promcode_applicable
        }

        await axios.post(Config.Add_Or_Update_Cart, medi)
            .then((result) => result)
            .then((data) => {
                // console.log(data);
                setFetch((pre) => pre + 1)
                setDaata([])
                setDisplayingmedicine([])
                setSelectedmedi()
            })
            .catch((err) => {
                console.log(err);
            })
    }


    return (
        <>
            {/* <Box width="100%" py={4} borderBottom="solid 1px #b0b0b0">
                <Grid templateColumns='repeat(12, 1fr)' gap={2}>
                    <GridItem colSpan={3}>
                        <Image
                            maxW={{ base: '100%', sm: '200px' }}
                            src={Placeholder}
                            alt='Placeholder'
                        />
                    </GridItem>
                    <GridItem colSpan={9}>
                        <Stack>
                            <Box>
                                <VStack align="flex-start">
                                    <Heading fontWeight={600} fontSize={14} color="#474747">Cropara 650mg Tablet</Heading>
                                    <Heading color="#A23DFF" textAlign="start" as="h6" fontWeight={600} fontSize={14}>15% off</Heading>
                                    <HStack justifyContent="space-between" width="100%">
                                        <Heading color="#474747" textAlign="start" as="h6" fontWeight={600} fontSize={14}>
                                            <Text textDecoration="line-through" as="span">₹13.33</Text>
                                            <Text ml={2} color="#00AB83" as="span">₹13.33</Text></Heading>
                                        <Box textAlign="end">
                                            <Button className='btn-tabs'>Add to cart</Button>
                                        </Box>
                                    </HStack>
                                </VStack>
                            </Box>
                        </Stack>
                    </GridItem>
                </Grid>
            </Box> */}



            <Box width="100%" py={4} borderBottom="solid 1px #b0b0b0">
                <Grid templateColumns='repeat(12, 1fr)' gap={2}>
                    <GridItem colSpan={3}>
                        <Image
                            maxW={{ base: '100%', sm: '200px' }}
                            src={Placeholder}
                            alt='Placeholder'
                        />
                    </GridItem>
                    <GridItem colSpan={9}>
                        <Stack>
                            <Box>
                                <VStack align="flex-start">

                                    <Heading fontWeight={600} fontSize={14} color="#474747">{medicineName}</Heading>

                                    <Heading color="#A23DFF" textAlign="start" as="h6" fontWeight={600} fontSize={14}>{(100 - (price * 100) / mrp).toFixed(0)}% off</Heading>
                                    <HStack justifyContent="space-between" width="100%">
                                        <Heading color="#474747" textAlign="start" as="h6" fontWeight={600} fontSize={14}>
                                            <Text textDecoration="line-through" as="span">₹{mrp}</Text>
                                            <Text ml={2} color="#00AB83" as="span">₹{price}</Text></Heading>
                                        <Box textAlign="end">
                                            <Button className='btn-tabs'
                                                isDisabled={detailedticket ? false : true}
                                                onClick={() => { Handleaddtocart(displayingmedicine) }} >Add to cart</Button>
                                        </Box>
                                    </HStack>
                                </VStack>
                            </Box>
                        </Stack>
                    </GridItem>
                </Grid>
            </Box>
            <Box py="2">
                <VStack align="flex-start" >
                    {
                        description &&
                        <Box pt={1} pb="4" lineHeight="18px" width="100%" borderBottom="solid 2px #f7f7f7" >
                            <PatientboldText>Description</PatientboldText>
                            <br></br>
                            <PatientLightText>{description}</PatientLightText>
                        </Box>
                    }
                    {
                        daata?.map((item) => (
                            <Box pt={1} pb="4" lineHeight="18px" width="100%" borderBottom="solid 2px #f7f7f7">
                                <PatientboldText>{item?.custom_key}</PatientboldText>
                                <br></br>
                                <PatientLightText>{item?.custom_value}</PatientLightText>
                            </Box>
                        ))
                    }

                    {/* <Box pt={1} pb="4" lineHeight="18px" width="100%" borderBottom="solid 2px #f7f7f7">
                    <PatientboldText>Product Composition</PatientboldText>
                    <br></br>
                    <PatientLightText>Paracetamol (650)</PatientLightText>
                </Box>
                <Box pt={1} pb="4" lineHeight="18px" width="100%" borderBottom="solid 2px #f7f7f7">
                    <PatientboldText>Prescription</PatientboldText>
                    <br></br>
                    <PatientLightText>NO</PatientLightText>
                </Box>
                <Box pt={1} pb="4" lineHeight="18px" width="100%" borderBottom="solid 2px #f7f7f7">
                    <PatientboldText>Company Name</PatientboldText>
                    <br></br>
                    <PatientLightText>Himalaya</PatientLightText>
                </Box>
                <Box pt={1} pb="4" lineHeight="18px" width="100%" borderBottom="solid 2px #f7f7f7">
                    <PatientboldText>How To Use</PatientboldText>
                    <br></br>
                    <PatientLightText>Take this medicine in the dose and duration as advised by your doctor. Swallow it as a whole. Do not chew, crush or break it. Cropara 650mg Tablet is to be taken with food</PatientLightText>
                </Box> */}
                </VStack>
            </Box>
            {/* <Discription customDetailsMappers={medicinedetails} /> */}
        </>
    )
}

export default Searchmedi