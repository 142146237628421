import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Dashboardpage from './containers/DashboardPage';
import { Box } from '@chakra-ui/react';
import LoginPage from './containers/LoginPage';
import { Loginstatus } from './lib/Providers/Loginstatus';
import { TicketDetails } from './lib/Providers/TicketDetails';
import { CartFormation } from './lib/Providers/CartFormation';
import Config from './lib/Config';
import Doctordashboard from './containers/DoctordashboardPage';
import GeneratePrescription from './containers/GeneratePrescription/GeneratePrescription';


function App() {
  console.log(process.env.REACT_APP_ORDER_WEB)
  return (
    <Box className="app">
      <BrowserRouter>
        <Loginstatus>
          <TicketDetails>
            <CartFormation>
              <Routes>
                <Route path='/' element={<LoginPage />} />
                <Route path='dashboard' element={<Dashboardpage />} />
                <Route path='doctordashboard' element={<Doctordashboard />} />
                <Route path='generateprescription' element={<GeneratePrescription />} />
              </Routes>
            </CartFormation>
          </TicketDetails>
        </Loginstatus>
      </BrowserRouter>
    </Box>
  );
}

export default App;
