/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Center, Flex, Grid, GridItem, HStack, List, ListItem, Spinner, Tag, Text, UnorderedList, VStack, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { OrderIdboldText, OrderIdLightText, TicketIdboldText } from '../../Chatcomponent/style'
import { OrderIdWrapper } from '../OrderPlaceTab/style'
import { LiveTicketCardContainer } from './styles'
import { AiFillMinusCircle } from "react-icons/ai";
import axios from 'axios'
import moment from 'moment/moment'
import LiveTicketDetails from './LiveTicketDetails'
import { Logincontext } from '../../../../../lib/Providers/Loginstatus'
import Config from '../../../../../lib/Config'
import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { useCallback } from 'react'
import ProgressBar from './progressBar'
const controller = new AbortController();
const LiveTicketCard = (props) => {
    const { setShowdetails, showdetails, setTicketdetails } = props
    const observer = useRef()
    // const skipRef = useRef(0); // useRef to store skip value
    const [hasMore, sethasMore] = useState(false)
    const [loading, setloading] = useState(true)
    // const [limit, setLimit] = useState(10)
    const limit = 10;
    const Toast = useToast()
    function titleCase(str) {
        return str?.toLowerCase()?.replace(/\b\w/g, s => s?.toUpperCase());
    }
    const navigate = useNavigate()
    const handlenavigate = () => {
        localStorage.clear()
        navigate('/')
        Toast({
            position: 'top-right',
            title: 'Session Expired',
            description: 'Please Login Again',
            status: 'error',
            duration: 5000,
            isClosable: true,
        });
    }
    const { refresh, searchvalues, searchresulttickets, setSeatchresulttickets, status, skip, setSkip, alltickets, setAlltickets } = useContext(Logincontext)

    useEffect(() => {
        let interval;

        if (status === '') {
            interval = setInterval(() => {
                getAllticket();
            }, 1000 * 60);
        }

        return () => clearInterval(interval);
    }, [status]);


    const getAllticket = async () => {
        const params = {
            pageNumber: skip,
            limit: limit
        }
        if (status) {
            params.status = status
        }

        await axios.get(Config.Get_All_Tickets, {
            signal: controller.signal,
            params: params
        })
            .then((result) => result)
            .then((data) => {
                // const filtered = data.data.data?.filter((item) => item.createdAt)
                // setAlltickets(data?.data?.data);
                // setAlltickets(prevTicket => {
                //         return [...prevTicket, ...data?.data?.data]
                //     })
                // setAlltickets(prevTicket => [prevTicket, ...response?.data?.data]);
                sethasMore(data?.data?.data?.length > 0)
                // setAlltickets(tickets => [...tickets, ...data?.data?.data]);
                if (skip === 0) {
                    setAlltickets([...data?.data?.data]);
                } else {
                    setAlltickets([...alltickets, ...data?.data?.data]);
                }
                setloading(false)
            })
            .catch((err) => {
                if (err?.response?.status === 401 && err?.response?.data?.data === 'User not authenticated') {
                    handlenavigate()
                    controller.abort();
                }
            })
    }
    // useEffect(()=>{
    //     console.log("lojj");
    //     const filteredData = alltickets?.filter((item) => (item.id === parseInt(searchvalues) || item.phoneNumber === searchvalues ))
    //     // setAlltickets(filteredData)
    // },[searchvalues])
    // return Object.values(item)
    //         .join('')
    //         .toLowerCase()
    //         .includes(searchvalues.toLowerCase());

    const lastTicketElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setSkip(prevSkip => prevSkip + limit)
                // setLimit(prevLimit => prevLimit + 10)
                // console.log("Visible", entries[0].target);
            }
        })
        if (node) observer.current.observe(node)
        // console.log(node);
    }, [hasMore])

    useEffect(() => {
        setloading(true)
        getAllticket()
    }, [refresh, skip, status])


    const handleexpand = (id) => {
        setShowdetails(true)
        setTicketdetails(id.id)
    }

    // console.log(alltickets, "llll");


    const { scrref } = useRef(null)
    const handlescroll = (e) => {
        // console.log(e, "scrref", scrref);
    }

    // console.log('searchval', searchresulttickets);

    useEffect(() => {
        if (searchvalues === '') {
            setSeatchresulttickets([])
            getAllticket()
        }
    }, [searchvalues])
    // console.log("alll", alltickets, 'skip', skip, skip === 0, refresh);

    return (
        <>
            {
                searchresulttickets?.length > 0 ?
                    (
                        <>
                            <Box height={'100vh'} w="100%" ref={scrref} onWheel={(e) => { handlescroll(e) }}>
                                {
                                    searchresulttickets?.map((item, index) => (
                                        <LiveTicketCardContainer
                                            key={item?.id || index}
                                        // onClick={()=>{setShowdetails(true)}}
                                        >
                                            <Grid alignItems="center" templateColumns='repeat(12, 1fr)' gap={0}>
                                                <GridItem colSpan={2} w='100%' h='100%'>
                                                    <Tag
                                                        border="solid 1px #00AB83"
                                                        bg="#D5F5E4"
                                                        rounded={20}
                                                        py={1}
                                                        px={4}
                                                        display="inline-block"
                                                        textOverflow="ellipsis"
                                                        whiteSpace='nowrap'
                                                        overflow="hidden"
                                                        width="100px"
                                                        textTransform={'capitalize'}
                                                    >{item.patientName}</Tag>
                                                </GridItem>
                                                <GridItem colSpan={8} w='100%' h='100%' >
                                                    <Text fontSize={14} color="#343434" width="95%" overflow={"hidden"} textOverflow="ellipsis" whiteSpace="nowrap"
                                                    // margin="0 5px 0 60px"
                                                    >
                                                        <b style={{ textTransform: 'capitalize' }} >{item.pharmacyName}</b> to {item.address}</Text>
                                                </GridItem>
                                                <GridItem lineHeight="10px" colSpan={2} w='100%' h='100%'>
                                                    <Text fontSize={12} as="span" fontWeight={600}>Status:</Text>
                                                    <Text fontSize={10} pl={1} as="span" fontWeight={600} color="#945BF8">{titleCase(item.ticketStatus)}</Text>
                                                </GridItem>
                                            </Grid>
                                            <Grid my={2} templateColumns='repeat(12, 1fr)' gap={0}>
                                                <GridItem colSpan={3} w='100%' h='100%'>
                                                    <VStack lineHeight="48px" h='100%'>
                                                        <Text fontSize={10} as="span">Created on :{moment(item.createdAt).format("DD MMM YYYY, hh:mm a")}
                                                            {/* 12 Dec 2021, 12:00 pm */}
                                                        </Text>
                                                    </VStack>
                                                </GridItem>
                                                <GridItem textAlign="center" colSpan={4} w='100%' h='100%'>
                                                    <Flex justifyContent="center">
                                                        <OrderIdWrapper>
                                                            <OrderIdboldText onClick={() => { handleexpand(item) }}>Ticket ID : #{item?.id}</OrderIdboldText>
                                                            <br></br>
                                                            <TicketIdboldText >Order ID : #{item?.orderId || "---"}</TicketIdboldText>
                                                            <br></br>
                                                            <OrderIdLightText>(Sub Order of #{item?.orderId || "---"})</OrderIdLightText>
                                                        </OrderIdWrapper>
                                                    </Flex>
                                                </GridItem>
                                                <GridItem colSpan={5} w='100%' h='100%'>
                                                    <HStack h='100%'>
                                                        <Button py="7px!important" height="auto" className='btn-tabs'>Call Store</Button>
                                                        <Button py="7px!important" height="auto" className='btn-tabs'>Call Doctor</Button>
                                                        <Button py="7px!important" height="auto" className='btn-tabs'>Call Delivery</Button>
                                                    </HStack>
                                                </GridItem>
                                            </Grid>
                                            <Box mb="45px">
                                                <Box className="timeline">
                                                    {/* <Box w={`${GetProcessstatus(item?.ticketStatus)}%`} className="timeline-progress"></Box> */}
                                                    <ProgressBar item={item} />
                                                </Box>
                                            </Box>
                                        </LiveTicketCardContainer>

                                    ))
                                }
                                {/* {showdetails && <LiveTicketDetails/>} */}
                            </Box>
                        </>
                    )
                    : alltickets?.length > 0 ? (
                        <>
                            <Box height={'100vh'} w="100%" ref={scrref} onWheel={(e) => { handlescroll(e) }}>
                                {
                                    alltickets?.map((item, index) => {
                                        if (alltickets.length === index + 1) {
                                            return (
                                                <>
                                                    <LiveTicketCardContainer
                                                        key={item?.id || index}
                                                        ref={lastTicketElementRef}
                                                    // onClick={()=>{setShowdetails(true)}}
                                                    >
                                                        <Grid alignItems="center" templateColumns='repeat(12, 1fr)' gap={0}>
                                                            <GridItem colSpan={2} w='100%' h='100%'>
                                                                <Tag
                                                                    border="solid 1px #00AB83"
                                                                    bg="#D5F5E4"
                                                                    rounded={20}
                                                                    py={1}
                                                                    px={4}
                                                                    display="inline-block"
                                                                    textOverflow="ellipsis"
                                                                    whiteSpace='nowrap'
                                                                    overflow="hidden"
                                                                    width="100px"
                                                                    textTransform={'capitalize'}
                                                                >{item.patientName}</Tag>
                                                            </GridItem>
                                                            <GridItem colSpan={8} w='100%' h='100%' >
                                                                <Text fontSize={14} color="#343434" width="95%" overflow={"hidden"} textOverflow="ellipsis" whiteSpace="nowrap"
                                                                // margin="0 5px 0 60px"
                                                                >
                                                                    <b style={{ textTransform: 'capitalize' }} >{item.pharmacyName}</b> to {item.address}</Text>
                                                            </GridItem>
                                                            <GridItem lineHeight="10px" colSpan={2} w='100%' h='100%'>
                                                                <Text fontSize={12} as="span" fontWeight={600}>Status:</Text>
                                                                <Text fontSize={10} pl={1} as="span" fontWeight={600} color="#945BF8">{titleCase(item.ticketStatus)}</Text>
                                                            </GridItem>
                                                        </Grid>
                                                        <Grid my={2} templateColumns='repeat(12, 1fr)' gap={0}>
                                                            <GridItem colSpan={3} w='100%' h='100%'>
                                                                <VStack lineHeight="48px" h='100%'>
                                                                    <Text fontSize={10} as="span">Created on :{moment(item.createdAt).format("DD MMM YYYY, hh:mm a")}
                                                                        {/* 12 Dec 2021, 12:00 pm */}
                                                                    </Text>
                                                                </VStack>
                                                            </GridItem>
                                                            <GridItem textAlign="center" colSpan={4} w='100%' h='100%'>
                                                                <Flex justifyContent="center">
                                                                    <OrderIdWrapper>
                                                                        <OrderIdboldText onClick={() => { handleexpand(item) }}>Ticket ID : #{item?.id}</OrderIdboldText>
                                                                        <br></br>
                                                                        <TicketIdboldText >Order ID : #{item?.orderId || "---"}</TicketIdboldText>
                                                                        <br></br>
                                                                        <OrderIdLightText>(Sub Order of #{item?.orderId || "---"})</OrderIdLightText>
                                                                    </OrderIdWrapper>
                                                                </Flex>
                                                            </GridItem>
                                                            <GridItem colSpan={5} w='100%' h='100%'>
                                                                <HStack h='100%'>
                                                                    <Button py="7px!important" height="auto" className='btn-tabs'>Call Store</Button>
                                                                    <Button py="7px!important" height="auto" className='btn-tabs'>Call Doctor</Button>
                                                                    <Button py="7px!important" height="auto" className='btn-tabs'>Call Delivery</Button>
                                                                </HStack>
                                                            </GridItem>
                                                        </Grid>
                                                        <Box mb="45px">
                                                            <Box className="timeline">
                                                                {/* <Box w={`${GetProcessstatus(item?.ticketStatus)}%`} className="timeline-progress"></Box> */}
                                                                <ProgressBar item={item} />
                                                            </Box>
                                                        </Box>
                                                    </LiveTicketCardContainer>
                                                </>
                                            )
                                        }
                                        else {
                                            return (
                                                <>
                                                    <LiveTicketCardContainer
                                                        key={item?.id || index}
                                                    // onClick={()=>{setShowdetails(true)}}
                                                    >
                                                        <Grid alignItems="center" templateColumns='repeat(12, 1fr)' gap={0}>
                                                            <GridItem colSpan={2} w='100%' h='100%'>
                                                                <Tag
                                                                    border="solid 1px #00AB83"
                                                                    bg="#D5F5E4"
                                                                    rounded={20}
                                                                    py={1}
                                                                    px={4}
                                                                    display="inline-block"
                                                                    textOverflow="ellipsis"
                                                                    whiteSpace='nowrap'
                                                                    overflow="hidden"
                                                                    width="100px"
                                                                    textTransform={'capitalize'}
                                                                >{item.patientName}</Tag>
                                                                <span style={{ fontSize: '14px', paddingLeft: '12px', fontWeight: '600' }}>{item.delivery_pickup_types}</span>
                                                            </GridItem>
                                                            <GridItem colSpan={2}></GridItem>
                                                            <GridItem colSpan={6} w='100%' h='100%' >
                                                                <Text fontSize={14} color="#343434" width="95%" overflow={"hidden"} textOverflow="ellipsis" whiteSpace="nowrap"
                                                                // margin="0 5px 0 60px"
                                                                >
                                                                    <b style={{ textTransform: 'capitalize' }}>{item.pharmacyName}</b> to {item.address}</Text>
                                                            </GridItem>
                                                            <GridItem lineHeight="10px" colSpan={2} w='100%' h='100%'>
                                                                <Text fontSize={12} as="span" fontWeight={600}>Status:</Text>
                                                                <Text fontSize={10} pl={1} as="span" fontWeight={600} color="#945BF8">{titleCase(item.ticketStatus)}</Text>
                                                            </GridItem>
                                                        </Grid>
                                                        <Grid my={2} templateColumns='repeat(12, 1fr)' gap={0}>
                                                            <GridItem colSpan={3} w='100%' h='100%'>
                                                                <VStack lineHeight="48px" h='100%'>
                                                                    <Text fontSize={10} as="span">Created on :{moment(item.createdAt).format("DD MMM YYYY, hh:mm a")}
                                                                        {/* 12 Dec 2021, 12:00 pm */}
                                                                    </Text>
                                                                </VStack>
                                                            </GridItem>
                                                            <GridItem textAlign="center" colSpan={4} w='100%' h='100%'>
                                                                <Flex justifyContent="center">
                                                                    <OrderIdWrapper>
                                                                        <OrderIdboldText onClick={() => { handleexpand(item) }}>Ticket ID : #{item?.id}</OrderIdboldText>
                                                                        <br></br>
                                                                        <TicketIdboldText >Order ID : #{item?.orderId || "---"}</TicketIdboldText>
                                                                        <br></br>
                                                                        <OrderIdLightText>(Sub Order of #{item?.orderId || "---"})</OrderIdLightText>
                                                                    </OrderIdWrapper>
                                                                </Flex>
                                                            </GridItem>
                                                            <GridItem colSpan={5} w='100%' h='100%'>
                                                                <HStack h='100%'>
                                                                    <Button py="7px!important" height="auto" className='btn-tabs'>Call Store</Button>
                                                                    <Button py="7px!important" height="auto" className='btn-tabs'>Call Doctor</Button>
                                                                    <Button py="7px!important" height="auto" className='btn-tabs'>Call Delivery</Button>
                                                                </HStack>
                                                            </GridItem>
                                                        </Grid>
                                                        <Box mb="45px">
                                                            <Box className="timeline">
                                                                {/* <Box w={`${GetProcessstatus(item?.ticketStatus)}%`} className="timeline-progress"></Box> */}
                                                                <ProgressBar item={item} />
                                                            </Box>
                                                        </Box>
                                                    </LiveTicketCardContainer>
                                                </>
                                            )
                                        }

                                    })
                                }
                                {/* {showdetails && <LiveTicketDetails/>} */}
                                {
                                    loading
                                    &&
                                    <Box
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center">
                                        <Spinner
                                            thickness='4px'
                                            speed='0.65s'
                                            emptyColor='green.100'
                                            color='green.500'
                                            size='xl'
                                        />
                                    </Box>
                                }
                            </Box>
                        </>
                    )
                        :
                        <Box>
                            <Center>No Tickets Found </Center>
                        </Box>
            }



        </>
    )
}

export default LiveTicketCard