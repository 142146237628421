import styled from 'styled-components';

export const ChatCradWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 1em 1em 1em 2em;
  background: #F4E9E9;
  border-radius: 5px;
  margin-top: 2px;
`;
export const OrderIdWrapper = styled.div`
display: block;
text-align: start;
`;
export const OrderIdboldText = styled.span`
font-size: 14px;
color: #945BF8;
font-weight: 600;
border-bottom: solid 2px #945BF8;
cursor:pointer
`;
export const TicketIdboldText = styled.span`
font-size: 14px;
color: #945BF8;
font-weight: 600;
border-bottom: solid 2px #945BF8;
`;
export const OrderIdLightText = styled.span`
font-size: 14px;
color: #474747;
font-weight:400;
`;