/* eslint-disable no-dupe-keys */
import { Box, Button, Heading, HStack, Input, Text, useDisclosure, useToast, VStack } from '@chakra-ui/react'
import axios from 'axios'
import React, { useContext, useState } from 'react'
import Config from '../../../../../../lib/Config'
import { TicketContext } from '../../../../../../lib/Providers/TicketDetails'
import CartbilldetailPopup from './CartbilldetailPopup'
import { Logincontext } from '../../../../../../lib/Providers/Loginstatus'
import { defaultvendor_id } from '../../../../../../lib/utlis/constants'

const Delivercard = (props) => {
    const { tabchange, total, deliveycharges, mrp, setTopay, topay,
        Gst, couponresponse, discountonmrp, yousaved, gstpercentage, placeorderdisabled,
        cartdata, setPlaceorderdisabled, minimumordervaluedisable, clearcart,
        othermrp, otherdiscount, othertotal, selectedNearbyPharmacy, medicinemrp, medicinediscount,
        minimumordervaluedisabled, setSelectedNearbypharmacy, setisCoupondisable, setisselectdisable
    } = props
    const { detailedticket, setFetch, setDetailedticket, docdeatils, SetDocdetils, docNameDetails, setResetPage, vendordetail } = useContext(TicketContext)
    const { setRefresh } = useContext(Logincontext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const toast = useToast()
    const [specialInstrunction, setSpecialInstrunction] = useState('')
    let itemdetails = []
    const newdocdeatials = {
        doctor_id: docdeatils?.id,
        ticket_id: detailedticket?.id
    }

    // console.log('prescriptionGenerateMapper', docdeatils, newdocdeatials);
    // console.log('DEEEE', detailedticket);
    cartdata?.map((item) => {
        itemdetails.push({
            item_id: `${item.mastermenuItemId}`,
            amount: (item.price * item.quantity)?.toFixed(2),
            single_selection: "",
            is_bravges: 0,
            multiple_selection: "",
            quantity: item.quantity,
            mrp: (item.mrp * item.quantity)?.toFixed(2),
            promocode_applicable: item?.promcode_applicable
        })
    })

    let placeorderobj = {
        id: detailedticket?.id,
        phoneNumber: detailedticket?.phoneNumber,
        patientName: detailedticket?.patientName,
        // emailId: detailedticket?.emailId,
        address: detailedticket?.address,
        longitude: detailedticket?.longitude,
        latitude: detailedticket?.latitude,
        pincode: detailedticket?.pincode,
        // doctorName: detailedticket?.rx ? detailedticket?.doctorName : docNameDetails?.name,
        // doctorPhoneNumber: detailedticket?.rx ? detailedticket?.doctorPhoneNumber : docNameDetails?.phone_number,
        // doctorRegistrationNumber: detailedticket?.rx ? detailedticket?.doctorRegistrationNumber : docNameDetails?.mrn,
        doctorName: detailedticket?.doctorName,
        doctorPhoneNumber: detailedticket?.doctorPhoneNumber,
        doctorRegistrationNumber: detailedticket?.doctorRegistrationNumber,
        doctorAddress: detailedticket?.doctorAddress,
        rx: detailedticket?.rx,
        prescriptionLink: detailedticket?.prescriptionLink,
        prescriptionNote: detailedticket?.prescriptionNote,
        ticketStatus: 'cartcompleted',
        orderId: detailedticket?.orderId,
        // address_id:
        braveges_amount: 0,
        CGST: "0.0",
        delivery_fee: `${deliveycharges}`,
        delivery_pickup_types: detailedticket?.delivery_pickup_types === "Takeaway" ? "Pickup" : detailedticket?.delivery_pickup_types,
        delivery_type: "0",
        discount_price: `${topay}`,
        depot_discount_price: "0.0",
        original_price: (parseFloat(total) + parseFloat(deliveycharges) + parseFloat(Gst)),
        depot_original_price: "0.0",
        promocode_applicable_mrp_sub_total: parseFloat(medicinemrp),
        remaining_amount: topay,
        restaurant_id: selectedNearbyPharmacy ? selectedNearbyPharmacy?.restaurant_id : couponresponse?.restaurant_id,
        restaurant_item_details: JSON.stringify(itemdetails),
        restaurant_service_fee: selectedNearbyPharmacy ? selectedNearbyPharmacy?.restaurant_service_tax : couponresponse?.restaurant_service_tax,
        service_fee: Gst,
        depot_service_fee: "0.0",
        SGST: "0.0",
        sub_total: total,
        depot_sub_total: "0.0",
        total_amount: topay,
        user_id: 43,
        vendor_id: couponresponse?.promocode?.vendor_id || defaultvendor_id,
        wallet_amount: 0,
        depot_saved_amount: "0",
        saved_amount: yousaved,
        store_sub_total: 0,
        mrp_sub_total: mrp,
        orderImagesid: detailedticket?.orderImagesid,
        payment_method: "Cash On Delivery",
        payment_type: "Cash On Delivery",
        orderImagesid: detailedticket?.orderImagesid,
        orderImages: detailedticket?.orderImages,
        createdAt: detailedticket?.createdAt,
        pharmacyName: selectedNearbyPharmacy ? selectedNearbyPharmacy.name : couponresponse?.name,
        prescriptionGenerateMapper: newdocdeatials,
        orderByAgent: detailedticket?.orderByAgent,
        coupon_id: selectedNearbyPharmacy ? null : couponresponse?.promocode?.id,
        device_type: 'Web',
        special_instructions: specialInstrunction,
        sourceType: detailedticket?.sourceType
    }

    console.log('place', placeorderobj, total, othertotal);

    const GetticketDeatilsbyid = async () => {
        // if (ticketdetail) {
        await axios.get(`${Config.Get_TicketDetail_By_Id}${detailedticket?.id}`)
            .then((result) => result)
            .then((data) => {
                setDetailedticket(data.data.data)
            })
            .catch((err) => {
                console.log(err, "error");
            })
        // }
    }

    const handlePlaceorder = async () => {
        if (
            placeorderobj?.orderImages?.length > 0 ||
            (!placeorderobj?.rx &&
                placeorderobj?.doctorName)
        ) {
            setPlaceorderdisabled(true);
            try {
                const result = await axios.post(
                    `${Config.Upadte_Ticket}/${detailedticket?.id}`,
                    placeorderobj
                );
                const data = result.data;
                // setDetailedticket(data.data);
                // GetticketDeatilsbyid();
                setFetch((pre) => pre + 1);
                setRefresh((pre) => pre + 1)
                // setPlaceorderdisabled(true);
                SetDocdetils();
                tabchange();
                setDetailedticket();
                setResetPage((pre) => pre + 1);
                setSpecialInstrunction('')
                setSelectedNearbypharmacy()
                setisCoupondisable(false)
                setisselectdisable(false)
                // clearcart();
            } catch (err) {
                setPlaceorderdisabled(false);
                console.log(err, "error");
                toast({
                    title: "Something went wrong",
                    position: 'top-right',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }

        } else {
            if (placeorderobj.rx) {
                toast({
                    title: "Please Upload Prescription",
                    position: 'top-right',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Please Select Available Doctor",
                    position: 'top-right',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
        setFetch((pre) => pre + 1)
    }

    const placeOrder = () => {
        if (placeorderobj?.address || placeorderobj?.delivery_pickup_types === 'Pickup') {
            handlePlaceorder()
        } else {
            toast({
                title: 'Please select patient address',
                position: 'top-right',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    // console.log('deeee',detailedticket);

    return (
        <>
            <CartbilldetailPopup
                isOpen={isOpen} onClose={onClose}
                othermrp={othermrp}
                otherdiscount={otherdiscount}
                othertotal={othertotal}
                medicinemrp={medicinemrp}
                medicinediscount={medicinediscount}
                deliveycharges={deliveycharges} total={total} mrp={mrp} setTopay={setTopay} Gst={Gst} couponresponse={couponresponse} topay={topay} discountonmrp={discountonmrp} yousaved={yousaved} gstpercentage={gstpercentage} detailedticket={detailedticket} />
            <Box
                bg="#E2F0E9"
                py={2}
            >
                <VStack p={2} align="flex-start" bg="#AEECCA" spacing={2}>
                    <Heading as="h6" fontSize={14} fontWeight={600}>Special Instruction</Heading>

                    <HStack p={2} justifyContent="space-between" width={"100%"} px={2} bg="#fff" rounded="10px">
                        <Input
                            height="auto"
                            width="100%"
                            border="none"
                            _focus={{ border: 'none', boxShadow: 'none' }}
                            placeholder="Any special request for stores"
                            onChange={(e) => { setSpecialInstrunction(e.target.value) }}
                            value={specialInstrunction}
                        />
                    </HStack>
                </VStack>
            </Box>
            <Box my={4} border="solid 1px gray" px={2} py={4} rounded="10px">
                <Heading fontWeight={500} as="h6" fontSize={15} color="#000">{detailedticket?.delivery_pickup_types?.toLowerCase() === "pickup" ? 'You need to Takeaway this order from' : 'Deliver to Home'}</Heading>
                <Text color="gray" mt={1} fontSize={14}>
                    {detailedticket?.delivery_pickup_types?.toLowerCase() === "pickup" ? (couponresponse?.full_address || selectedNearbyPharmacy?.address) : detailedticket?.address}
                    {/* Swapnapurti Aparment 1 Swapnapurti Aparment-1,Swapnapurti Aparment 1,MHADA Colony, Chandivali, Powai, Mumbai, Maharashtra 400072, India */}
                </Text>
                <HStack justifyContent="space-between" mt={3} border="solid 1px #00AB83" rounded="10px">
                    {<Heading pl={2} fontSize={12}>₹ {parseFloat(topay)?.toFixed(2) || "0.00"}</Heading>}
                    <Button px={2} bg="none" _hover={{ bg: 'none' }} isDisabled={placeorderdisabled || detailedticket?.length > 0} fontSize={14} color="#00AB83" onClick={onOpen}>View Bill Details</Button>
                    <Button
                        fontSize={14}
                        bg="#00AB83"
                        color="#fff"
                        _hover={{ bg: 'black' }}
                        isDisabled={placeorderdisabled || detailedticket?.length > 0 || minimumordervaluedisable || minimumordervaluedisabled}
                        onClick={placeOrder}
                    >{minimumordervaluedisabled && detailedticket?.delivery_pickup_types?.toLowerCase() !== "pickup" ? `Minimum order value is ${vendordetail?.minimum_order_value_for_delivery} ` : 'Place Order'}</Button>
                </HStack>
                {detailedticket?.delivery_pickup_types?.toLowerCase() !== "pickup" && deliveycharges > 0 && <Text color='#755ab8' fontSize={12} fontWeight={500} textAlign={'Center'} bg={'rgba(0, 171, 131, 0.1)'} rounded={'10px'} w={'60%'} mt={2} mx={'auto'}>* Order above Rs {vendordetail?.charges && vendordetail?.charges[0]?.max_value} To get Free Delivery.</Text>}
            </Box>
        </>
    )
}

export default Delivercard