import styled from 'styled-components';

export const UserProfileWrapper = styled.div`
  max-height: 350px;
  width: 100%;
 overflow-y: auto;
  padding: 0em 1em 0em 0em;
  line-height: 18px;
`;
export const UserProfileWrapper2 = styled.div`
  max-height: 388px;
  width: 100%;
 overflow-y: auto;
  padding: 0em 1em 0em 0em;
  line-height: 18px;
`;
export const OrderIdWrapper = styled.div`
display: block;
text-align: start;
`;
export const PatientboldText = styled.span`
font-size: 15px;
color: #000;
font-weight: 600;
`;
export const PatientLightText = styled.span`
font-size: 12px;
color: gray;
font-weight:400;
`;