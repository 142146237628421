import { Box, Text, VStack } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import SearchBar2 from '../../../../DashboardComponents/SearchBar/index2'
import Discription from './CartBox/Discription'
import Searchmedi from './CartBox/Searchmedi'
import { UserProfileWrapper2 } from './style'
import { FiChevronLeft } from "react-icons/fi";
import axios from "axios";
import Config from '../../../../../lib/Config'
import { testJSON } from '../../../../../lib/utlis/Functios'


const Searchmedician = (props) => {
    let filteredmedicines;
    const [searchedmedicine, setSearchmedicine] = useState()
    const [searchvalue, setSearchvalue] = useState();
    const [selectedmedi, setSelectedmedi] = useState()
    const [displayingmedicine, setDisplayingmedicine] = useState([])
    const [daata, setDaata] = useState([])
    const [isloading, setIsloading] = useState(false)
    const { setShow } = props

    const searchmedicine = async (searchtext) => {

        if (!searchtext) {
            setSearchmedicine();
            return;
        }
        if (searchtext.length > 2) {
            if (searchtext === " ") {
                searchedmedicine();
                return;
            }
            setSearchmedicine();
            setIsloading(true)
            await axios.get(`${Config.Get_Medicine_By_Name}${searchtext.toLowerCase()}`)
                .then((result) => result.data)
                .then((data) => {
                    setIsloading(false)
                    console.log('dataaaaaaa', data.data);
                    setSearchmedicine(data.data)
                })
                .catch((err) => {
                    console.log(err);
                    setIsloading(false)
                })
        }
    }

    const handlemedicineshow = (medicine) => {
        Getmedicinedescription(medicine)
        setSelectedmedi(medicine)
    }

    const Getmedicinedescription = async (medi) => {
        if (medi.id) {
            await axios.get(`${Config.Get_Medicine_By_Id}${medi.id}`)
                .then((result) => result.data)
                .then((data) => {
                    setDisplayingmedicine(data.data);
                    // console.log('Search log medicine',JSON.parse(data.data.customDetailsMappers),testJSON(data.data.customDetailsMappers));
                    if (testJSON(data.data.customDetailsMappers)) {
                        setDaata(JSON.parse(data.data.customDetailsMappers))
                    }
                    setSearchmedicine()
                    setSearchvalue('')
                })
                .catch((error) => {
                    console.log(error, "error");
                })
        }
    }

    // useEffect(()=>{
    //     Getmedicinedescription(selectedmedi)
    // },[])



    return (
        <Box position="relative" px={4}>
            <Box
                position="absolute"
                top={2}
                left={-3}
                cursor="pointer"
            ><FiChevronLeft onClick={() => { setShow(false) }} fontSize={30} /></Box>
            <Box position={'relative'} zIndex={999}>
                <SearchBar2 searchmedicine={searchmedicine} setSearchvalue={setSearchvalue} searchvalue={searchvalue} setSearchmedicine={setSearchmedicine} />
                {searchedmedicine?.length > 0 ? <Box
                    top={'50%'}
                    borderRadius={4}
                    background="white"
                    width='100%'
                    position="absolute"
                    boxShadow={'2xl'}
                    pb={2}
                    pt={6}
                    zIndex={'-1'}
                    borderBottomLeftRadius="38px"
                    borderBottomRightRadius="38px"
                    overflowY={"auto"}
                    height="auto"
                    maxHeight={"300px"}
                >
                    {searchedmedicine?.map((item, index) => {
                        return (
                            <>
                                <Text
                                    cursor="pointer"
                                    onClick={() => handlemedicineshow(item)}
                                    key={item?.id}
                                    // fontFamily="Avenir Next"
                                    fontSize={16}
                                    fontWeight={400}
                                    // py={1}
                                    pl={'18px'}
                                    lineHeight="22px"
                                >
                                    {item.medicineName}
                                </Text>
                                <Text
                                    // cursor="pointer"
                                    // onClick={() => handlemedicineshow(item)}
                                    key={index}
                                    // fontFamily="Avenir Next"
                                    fontSize={12}
                                    fontWeight={400}
                                    py={1}
                                    pl={'18px'}
                                    // lineHeight="22px"
                                    color='gray'
                                >
                                    {item?.description}
                                </Text>
                            </>
                        )

                    })}
                </Box> : searchedmedicine?.length === 0 ?
                    (<>
                        <Box
                            top={'50%'}
                            borderRadius={4}
                            background="white"
                            width='100%'
                            position="absolute"
                            boxShadow={'2xl'}
                            pb={2}
                            pt={6}
                            zIndex={'-1'}
                            borderBottomLeftRadius="38px"
                            borderBottomRightRadius="38px"
                            overflowY={"auto"}
                            height="auto"
                            maxHeight={"300px"}
                        >
                            <Text
                                cursor="pointer"
                                // onClick={() => handlemedicineshow(item)}
                                // key={index}
                                fontFamily="Avenir Next"
                                fontSize={16}
                                fontWeight={400}
                                py={1}
                                pl={'18px'}
                                lineHeight="22px"
                            >
                                No Results Found
                            </Text>
                        </Box>
                    </>) : isloading ? (
                        <div className="loader-outer">
                            <div className="loader">
                                <span className="dot "></span>
                                <span className="dot "></span>
                                <span className="dot "></span>
                                <span className="dot "></span>
                                <span className="dot "></span>
                            </div>
                        </div>
                    ) : null
                }
            </Box>
            {
                selectedmedi &&
                <UserProfileWrapper2>
                    <VStack mt={2} align="flex-start">
                        <Searchmedi displayingmedicine={displayingmedicine} daata={daata} setDaata={setDaata} setDisplayingmedicine={setDisplayingmedicine} setSelectedmedi={setSelectedmedi} />
                        {/* <Discription /> */}
                    </VStack>
                </UserProfileWrapper2>
            }
        </Box>
    )
}

export default Searchmedician