import React, { Children, useCallback, useEffect, useRef, useState } from 'react'
import { DashboardMainWrapper, DashboardWrapper } from '../DashboardPage/styles'
import DashboardMain from '../DashboardPage/DashboardMain'
import DashboardTopbar from '../../components/DashboardElements/DashboardTopbar'
import { Box, Button, Center, Flex, Heading, Input, Select, Spinner, Tab, TabList, TabPanel, TabPanels, Table, TableContainer, Tabs, Tag, Tbody, Td, Text, Th, Thead, Tooltip, Tr, VStack, background, border, useDisclosure, useToast } from '@chakra-ui/react'
import SearchComponent from '../../components/DashboardElements/DashboardMiddle/MiddleSection/searchComponent'
import { color } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import Datepicker from '../../components/Datepicker/Datepicker'
import SearchBardoc from '../../components/SearchcomponentDoctor/SearchBardoc'
import axios from 'axios'
import Config from '../../lib/Config'
import moment from 'moment'
import { AiOutlineCaretDown } from 'react-icons/ai'
import Oldprescirptionpopup from '../GeneratePrescription/Oldprescirptionpopup'

const Doctordashboard = () => {
    const navigate = useNavigate()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const date = new Date();
    const [selecteddate, setSelectedDate] = useState(date);
    const [consultationRequestList, setConsultationRequestList] = useState([]);
    const [loading, setloading] = useState(true);
    const { scrref } = useRef(null);
    const observer = useRef();
    const [hasMore, sethasMore] = useState(false);
    const [skip, setSkip] = useState(0);
    const [search, setSearch] = useState('');
    const [images, setImages] = useState([]);
    const limit = 10;
    const [status, setStatus] = useState('');
    const userId = localStorage.getItem("userid");
    console.log('user',userId);
    const Toast = useToast()
    const CustomButton = (props) => {
        // console.log('chi', props?.children, props);
        const { children, onClick, color } = props
        return (
            <>
                <Button
                    variant='solid'
                    background={color}
                    color='#fff'
                    rounded='full'
                    fontSize={14}
                    fontWeight={400}
                    border="solid 1px"
                    borderColor={color}
                    _hover={{
                        borderColor: color,
                        border: 'solid 1px',
                        background: 'transparent',
                        color: color
                    }}
                    onClick={onClick}
                >
                    {children}
                </Button>
            </>
        )
    }

    useEffect(() => {
        setloading(true);
        getConsultationRequest();
    }, [skip, search, selecteddate, status])

    useEffect(() => {

        const Runateveryminutefunction = () => {
            setSkip(0)
            setSelectedDate(date)
            setStatus('')
            setSearch('')
            getConsultationRequest()
        };

        const intervalId = setInterval(Runateveryminutefunction, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, [])

    const getConsultationRequest = async () => {
        await axios.get(`${Config.Get_Doctor_Consultation_Request}/${userId}`, {
            params: {
                skip,
                limit,
                date: moment(selecteddate).format('YYYY-MM-DD'),
                search,
                status
            }
        })
            .then((result) => result)
            .then((data) => {
                sethasMore(data?.data?.data?.length > 0);
                if (skip === 0) {
                    setConsultationRequestList([...data?.data?.data]);
                } else {
                    setConsultationRequestList([...consultationRequestList, ...data?.data?.data]);
                }
                setloading(false);
            })
            .catch((err) => {
                console.log(err.response.data.data, err.status, "error");
                Toast({
                    position: 'top-right',
                    description: err.response.data.data || 'Something went wrong !!!',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
                if (err.response.data.data == "User not authenticated" && err.response.status === 401) {
                    localStorage.clear()
                    navigate('/login')
                }
            })
    }

    const lastRequestElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setSkip(prevSkip => prevSkip + limit)
            }
        })
        if (node) observer.current.observe(node)
    }, [hasMore])

    const handlescroll = (e) => {
        // console.log(e, "scrref", scrref);
    }

    const handleDate = (e) => {
        setConsultationRequestList([]);
        setSelectedDate(e);
        setSkip(0);
    }
    const handleChange = (e) => {
        setConsultationRequestList([]);
        setSkip(0);
        setStatus(e.target.value);
        // console.log(e.target.value, skip);
    }

    const Openviewpopup = (prescription_link) => {
        setImages([{ prescription_link }])
        onOpen()
    }

    const statustext = (status) => {
        switch (status) {
            case 'NEWREQUEST':
                return (<Tag px={2} bg='yellow.400' rounded='full' color='#fff' fontSize={12} fontWeight={500}>{status}</Tag>)
            case 'APPROVED':
                return (<Tag px={2} bg='green.400' rounded='full' color='#fff' fontSize={12} fontWeight={500}>{status}</Tag>)
            case 'REJECTED':
                return (<Tag px={2} bg='red.400' rounded='full' color='#fff' fontSize={12} fontWeight={500}>{status}</Tag>)
            default:
                return (<Tag px={2} bg='gray.400' rounded='full' color='#fff' fontSize={12} fontWeight={500}>{status}</Tag>)
        }
    }

    return (
        <>
            <Oldprescirptionpopup isOpen={isOpen} onClose={onClose} Pimages={images} />
            <DashboardWrapper>
                <DashboardMainWrapper>
                    <DashboardTopbar />
                    <Box paddingLeft='1em'>
                        <Flex justify='space-between' alignItems='center'>
                            <Box width="50%">
                                <SearchBardoc setSearch={setSearch} search={search} setSkip={setSkip} setConsultationRequestList={setConsultationRequestList} />
                            </Box>
                            <Select
                                width='15%'
                                height='45px'
                                bg="#fff"
                                color="#9F9F9F"
                                border="transparent"
                                _focusVisible={{
                                    boxShadow: 'none'
                                }}
                                icon={<AiOutlineCaretDown size="1.2rem" color='#9F9F9F' />}
                                onChange={(e) => handleChange(e)}
                                value={status}
                            >
                                <option value=''>All Status</option>
                                <option value='NEWREQUEST'>New Request</option>
                                <option value='APPROVED'>Approved</option>
                                <option value='REJECTED'>Rejected</option>
                            </Select>
                            {/* <Input width="15%" type='date' /> */}
                            <Datepicker width="15%" date={selecteddate} onChange={handleDate} format='dd/MM/yyyy' />
                        </Flex>
                        <Box mt={8} width={'100%'}>
                            {/* <Tag size="sm"
                                    key="1"
                                    variant="solid"
                                    colorScheme="teal"
                                    fontSize={'md'}
                                    p={'12px 17px'}
                                    bg={'#16999E'}
                                    ml={6}
                                    borderRadius={'10px 10px 0 0'}>
                                    Patient List
                                </Tag> */}
                            <Box width={"100%"}
                                height={'auto'}
                                overflow="hidden"
                                bg={'white'}
                                borderWidth="1px"
                                borderRadius="lg"
                                overflowY={'auto'}
                                // p={'15px 20px'}
                                borderBottomRadius={15}
                                borderTopRadius={15}
                                maxHeight={'560px'}
                                border={0} >
                                <TableContainer>
                                    <Table>
                                        <Thead bg="#16999e">
                                            <Tr>
                                                <Th color='#fff'>Patient Name</Th>
                                                <Th color='#fff'>Time ago</Th>
                                                <Th color='#fff' textAlign={'center'}>Generate Prescription</Th>
                                                {/* <Th></Th> */}
                                                <Th textAlign='center' color='#fff'>Status</Th>
                                            </Tr>
                                        </Thead>

                                        {consultationRequestList?.length > 0 ? (
                                            <Tbody w="100%" ref={scrref} onWheel={(e) => { handlescroll(e) }}>
                                                {consultationRequestList.length > 0 && consultationRequestList.map((item, index) => {
                                                    // console.log(item, "itemS");
                                                    if (consultationRequestList.length === index + 1) {
                                                        return (
                                                            <Tr borderBottom='solid 1px' borderColor='gray.300' key={index} ref={lastRequestElementRef}>
                                                                <Td fontWeight={600} textTransform={'capitalize'} >{item?.patient_name}</Td>
                                                                <Td>{moment(item?.created_at).fromNow()}</Td>
                                                                <Td textAlign={'center'}>{item?.request_status === 'NEWREQUEST' ?
                                                                    <CustomButton
                                                                        onClick={() => { navigate('/generateprescription', { state: item }) }}
                                                                        color='#16999e'
                                                                    >
                                                                        Generate Prescription
                                                                    </CustomButton> : <>
                                                                        {item?.request_status === "APPROVED" ?
                                                                            <CustomButton onClick={() => { Openviewpopup(item.prescription_link) }} color='#16999e'>
                                                                                View Prescription
                                                                            </CustomButton>
                                                                            :
                                                                            <Tooltip label={item?.remark} hasArrow position='absolute'>
                                                                                <Box>
                                                                                    <CustomButton
                                                                                        color='#C0C0C0'
                                                                                    >
                                                                                        View Reason
                                                                                    </CustomButton>
                                                                                </Box>
                                                                            </Tooltip>
                                                                        }</>}

                                                                </Td>
                                                                {/* <Td>
                                                                    <CustomButton color='#16999e'>
                                                                        Old Prescription
                                                                    </CustomButton>
                                                                </Td> */}
                                                                <Td textAlign='center'>{statustext(item?.request_status)}</Td>
                                                            </Tr>
                                                        )
                                                    } else {
                                                        return (
                                                            <Tr borderBottom='solid 1px' borderColor='gray.300' key={index}>
                                                                <Td fontWeight={600} textTransform={'capitalize'} >{item?.patient_name}</Td>
                                                                <Td>{moment(item?.created_at).fromNow()}</Td>
                                                                <Td textAlign={'center'}>{item?.request_status === 'NEWREQUEST' ?
                                                                    <CustomButton
                                                                        onClick={() => { navigate('/generateprescription', { state: item }) }}
                                                                        color='#16999e'
                                                                    >
                                                                        Generate Prescription
                                                                    </CustomButton> : <>
                                                                        {item?.request_status === "APPROVED" ?
                                                                            <CustomButton onClick={() => { Openviewpopup(item.prescription_link) }} color='#16999e'>
                                                                                View Prescription
                                                                            </CustomButton>
                                                                            :
                                                                            <Tooltip label={item?.remark} hasArrow position='absolute'>
                                                                                <Box>
                                                                                    <CustomButton
                                                                                        color='#C0C0C0'
                                                                                    >
                                                                                        View Reason
                                                                                    </CustomButton>
                                                                                </Box>
                                                                            </Tooltip>
                                                                        }</>} </Td>
                                                                {/* <Td>
                                                                    <CustomButton color='#16999e'>
                                                                        Old Prescription
                                                                    </CustomButton>
                                                                </Td> */}
                                                                <Td textAlign='center'>{statustext(item?.request_status)}</Td>
                                                            </Tr>
                                                        )
                                                    }
                                                })}
                                                {
                                                    loading
                                                    &&
                                                    <Td
                                                        textAlign='center' colSpan={4}>
                                                        <Spinner
                                                            thickness='4px'
                                                            speed='0.65s'
                                                            emptyColor='green.100'
                                                            color='green.500'
                                                            size='xl'
                                                        />
                                                    </Td>
                                                }
                                            </Tbody>
                                        ) :
                                            <Tbody>
                                                <Td textAlign='center' colSpan={4}> No Tickets Found </Td>
                                            </Tbody>}
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>
                </DashboardMainWrapper>
                <Box py='20px' px='15px' bg="#fff">
                    <Tabs variant='unstyled'>
                        <TabList>
                            <Tab borderTopRadius='2xl' px='25px' color='#fff' bg='#9f9f9f' _selected={{ color: 'white', bg: '#16999e' }}>Instamed</Tab>
                            <Tab marginLeft={4} borderTopRadius='2xl' px='25px' color='#fff' bg='#9f9f9f' _selected={{ color: 'white', bg: '#16999e' }}>IDP</Tab>
                        </TabList>
                        <TabPanels>
                            <TabPanel p={0}>
                                <VStack mb={4} p={4} bg="red.50" rounded='sm' alignItems='flex-start' spacing={4}>
                                    <Heading as="h6" fontSize='sm' color='blue.500' textDecoration='underline'>Ravi Rotela</Heading>
                                    <Tag px={2} bg='red.400' rounded='full' color='#fff' fontSize={12} fontWeight={400}>Denied</Tag>
                                    <Button
                                        variant='solid'
                                        background='#16999e'
                                        color='#fff'
                                        rounded='md'
                                        fontSize={14}
                                        fontWeight={400}
                                        border="solid 1px"
                                        borderColor="#16999e"
                                        _hover={{
                                            borderColor: 'black',
                                            border: 'solid 1px',
                                            background: 'black',
                                            color: '#fff'
                                        }}
                                    >
                                        Generate
                                    </Button>
                                </VStack>
                                <VStack mb={4} p={4} bg="red.50" rounded='sm' alignItems='flex-start' spacing={4}>
                                    <Heading as="h6" fontSize='sm' color='blue.500' textDecoration='underline'>Ravi Rotela</Heading>
                                    <Tag px={2} bg='red.400' rounded='full' color='#fff' fontSize={12} fontWeight={400}>Denied</Tag>
                                    <Button
                                        variant='solid'
                                        background='#16999e'
                                        color='#fff'
                                        rounded='md'
                                        fontSize={14}
                                        fontWeight={400}
                                        border="solid 1px"
                                        borderColor="#16999e"
                                        _hover={{
                                            borderColor: 'black',
                                            border: 'solid 1px',
                                            background: 'black',
                                            color: '#fff'
                                        }}
                                    >
                                        Generate
                                    </Button>
                                </VStack>
                            </TabPanel>
                            <TabPanel p={0}>
                                <VStack mb={4} p={4} bg="red.50" rounded='sm' alignItems='flex-start' spacing={4}>
                                    <Heading as="h6" fontSize='sm' color='blue.500' textDecoration='underline'>Ravi Rotela</Heading>
                                    <Tag px={2} bg='red.400' rounded='full' color='#fff' fontSize={12} fontWeight={400}>Denied</Tag>
                                    <Button
                                        variant='solid'
                                        background='#16999e'
                                        color='#fff'
                                        rounded='md'
                                        fontSize={14}
                                        fontWeight={400}
                                        border="solid 1px"
                                        borderColor="#16999e"
                                        _hover={{
                                            borderColor: 'black',
                                            border: 'solid 1px',
                                            background: 'black',
                                            color: '#fff'
                                        }}
                                    >
                                        Generate
                                    </Button>
                                </VStack>
                                <VStack mb={4} p={4} bg="red.50" rounded='sm' alignItems='flex-start' spacing={4}>
                                    <Heading as="h6" fontSize='sm' color='blue.500' textDecoration='underline'>Ravi Rotela</Heading>
                                    <Tag px={2} bg='red.400' rounded='full' color='#fff' fontSize={12} fontWeight={400}>Denied</Tag>
                                    <Button
                                        variant='solid'
                                        background='#16999e'
                                        color='#fff'
                                        rounded='md'
                                        fontSize={14}
                                        fontWeight={400}
                                        border="solid 1px"
                                        borderColor="#16999e"
                                        _hover={{
                                            borderColor: 'black',
                                            border: 'solid 1px',
                                            background: 'black',
                                            color: '#fff'
                                        }}
                                    >
                                        Generate
                                    </Button>
                                </VStack>
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                </Box>
            </DashboardWrapper>
        </>
    )
}

export default Doctordashboard