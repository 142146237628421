import { Box, HStack, useRadio, useRadioGroup } from '@chakra-ui/react'
import React from 'react'
import RadioCard from './RadioCard'

const TakeawayToggle = (props) => {
    const { setDeliveryType, defaultType } = props
    const options = ['Delivery', 'Takeaway']
    const { getRootProps } = useRadio(props)
    const { getRadioProps } = useRadioGroup(({
        defaultValue: defaultType,
        ...props,
        onChange: (value) => {
            // Perform any desired actions when the value changes
            setDeliveryType(value)
            // console.log('Selected value:', value);
          },
      }))
  
    const group = getRootProps()
    return (
        <>
        <HStack bg={'#E9F4ED'} px={'10px'} py={'7px'} rounded={'50px'} {...group}>
        {options.map((value) => {
          const radio = getRadioProps({ value })
          return (
            <RadioCard key={value} {...radio}>
              {value}
            </RadioCard>
          )
        })}
      </HStack>
        </>
    )
}

export default TakeawayToggle
