import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, VStack } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import Chatcard from '../../components/DashboardElements/DashboardMiddle/Chatcomponent/Chatcard'
import { ChatSection } from './styles'
import Pusher from 'pusher-js';
import axios from 'axios'
import { TicketContext } from '../../lib/Providers/TicketDetails';
import Config from '../../lib/Config'

const ChatSec = () => {

    const [ordersList, setOrdersList] = useState(null)
    const { detailedticket,fetch } = useContext(TicketContext)
    // console.log(detailedticket, "koko");

    useEffect(() => {
        const pusher = new Pusher('45987be4c79e14071e28', {
            cluster: 'ap2',
        });
        const channel = pusher.subscribe('UmeedNotification');
        channel.bind('REJECTED', function (data) {
            // console.log(data,"pusher data");
        });
    }, []);

    const getChartList = async () => {
        await axios.get(Config.Get_Chatcard_Data)
            .then((res) => {
                setOrdersList(res?.data?.data)
            }).catch((err) => {
                console.log(err);
            })
    }  

    useEffect(() => { 
        getChartList();     
    }, [fetch]);

    return (
        <ChatSection>
            <Box height="100%">
                <Tabs variant='enclosed'>
                    <TabList className='chat-tabs' >
                        <Tab>Tickets</Tab>
                        <Tab>InstaMed Team</Tab>
                    </TabList>
                    <TabPanels
                        height={650}
                        maxH={650}
                        overflowY="auto"
                        bg="#fff"
                    >
                        <TabPanel p={0}>
                            <VStack>
                                {ordersList && ordersList.length ? ordersList.map((order, i) => (
                                    <Chatcard order={order} key={i} />
                                )) : null}
                            </VStack>
                        </TabPanel>
                        <TabPanel p={0}>
                            <p>two!</p>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </ChatSection>
    )
}

export default ChatSec