import React, { createContext, useEffect, useState } from 'react'
import IsRouteAllowed from '../Routes/Isrouteallowed'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios'
import Config from '../Config';
import { useToast } from '@chakra-ui/react';

const Logincontext = createContext()
const Loginstatus = ({ children }) => {
    const userId = localStorage.getItem('userid')
    const role = localStorage.getItem('role')
    const [refresh, setRefresh] = useState(0)
    const [tabIndex, setTabIndex] = useState(0)
    const [searchvalues, setSearchvalues] = useState('')
    const token = localStorage.getItem('token')
    const navigate = useNavigate()
    const location = useLocation()
    const [status, setStatus] = useState()
    const [searchresulttickets, setSeatchresulttickets] = useState([])
    const [skip, setSkip] = useState(0)
    const [alltickets, setAlltickets] = useState([])
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    // console.log('role', role);

    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            // Handle errors here, you can log them or display a message to the user.
            console.error('API request failed:', error);
            if (error?.response?.status === 401 && error?.response?.data === 'Invalid or expired token') {
                localStorage.clear()
                window.location.replace('/')
            }
            // Rethrow the error to propagate it to the component making the request
            throw error;
        }
    );

    const searchval = async () => {
        await axios.get(Config.Search_Tickets, {
            params: {
                searchString: searchvalues
            }
        })
            .then((result) => result.data)
            .then((data) => {
                // console.log('data?.data', data?.data);
                setSeatchresulttickets(data?.data);
            })
            .catch((err) => {
                console.log('err', err);
            })
    }

    useEffect(() => {
        if (userId) {
            axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            if (!IsRouteAllowed(location.pathname, true, role) && role === 'Insta_Agent') {
                navigate('/dashboard', { replace: true });
            }
            else if (!IsRouteAllowed(location.pathname, true, role) && role === 'Insta_Doctor') {
                navigate('/doctordashboard', { replace: true });
            }
        } else if (!IsRouteAllowed(location.pathname, false, role)) { window.location.replace('/') }
    }, [navigate])

    return (
        <Logincontext.Provider value={{ refresh, setRefresh, tabIndex, setTabIndex, setSearchvalues, searchvalues, searchresulttickets, searchval, setSeatchresulttickets, status, setStatus, setSkip, skip, alltickets, setAlltickets }}>
            {children}
        </Logincontext.Provider>
    )
}

export { Logincontext, Loginstatus }