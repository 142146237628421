import React from 'react'
import MiddleSection from './MiddleSection'
import PointsSection from './PointSection'
import { DashboardMainWrapper } from './style'

const DashboardMiddle = () => {
    return (
        // <DashboardMainWrapper>
            // <PointsSection />
            <MiddleSection />
        // </DashboardMainWrapper>
    )
}

export default DashboardMiddle