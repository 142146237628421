import styled from 'styled-components';

export const DashboardWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: 1.5fr 0.5fr;
  column-gap: 2em;
`;

export const DashboardMainWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1.5fr;
  column-gap: 2em;
  height: 100%;
`;

export const ChatSection = styled.div`
  background-color: white;
  text-align: center;
  padding: 20px 15px;
`;