import React, { useState, useContext } from 'react'
import Switch from "react-switch";
import { TicketContext } from '../../../../../lib/Providers/TicketDetails';

const SwitchToggle = ({setFieldValue, checked, setChecked, setSelecteddoc, setFileDetail}) => {
    const { setDocNameDetails, setEmptyPrescription } = useContext(TicketContext)
    const handleChange = (e) => {
        setEmptyPrescription((prev)=> prev+1)
        setChecked(e);
        setFieldValue('haveRx', e);
        setFieldValue('doctorname', '')
        setFieldValue('doctorregistrationnumber', '')
        setFieldValue('doctorphonenumber', '')
        setFieldValue('doctoradress', '')
        if(e === true){
            setSelecteddoc('')
            setDocNameDetails('')
            setFileDetail([])
        }
    };
    return (
        <label htmlFor="small-radius-switch">
            <Switch
                checked ='true'
                // checked={checked}
                onChange={(e) => { handleChange(e) }}
                disabled
                handleDiameter={30}
                offColor="#D9D9D9"
                onColor="#01AB97"
                offHandleColor="#01AB97"
                onHandleColor="#D9D9D9"
                height={30}
                width={70}
                borderRadius={'50px'}
                activeBoxShadow="0px 0px 1px 2px #01AB97"
                uncheckedIcon={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            color: "#000",
                            paddingRight: 2
                        }}
                    >
                        No
                    </div>
                }
                checkedIcon={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 15,
                            color: "#fff",
                            paddingRight: 2
                        }}
                    >
                        Yes
                    </div>
                }
                uncheckedHandleIcon={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            fontSize: 20,
                        }}
                    >
                    </div>
                }
                checkedHandleIcon={
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                            color: "green",
                            fontSize: 18
                        }}
                    >
                    </div>
                }
                className="react-switch"
                id="small-radius-switch"
            />
        </label>
    )
}

export default SwitchToggle