import { Grid, GridItem } from '@chakra-ui/react'
import React, { useContext } from 'react'
import { TicketContext } from '../../../../../lib/Providers/TicketDetails'
import { PatientboldText, PatientLightText } from '../OrderPlaceTab/style'

const Patientdetails = () => {
    const { detailedticket } = useContext(TicketContext)
    return (
        <Grid templateColumns='repeat(12, 1fr)' gap={0}>
            <GridItem colSpan={2} w='100%' h='100%'>
                <PatientLightText>Patient name</PatientLightText>
                <br></br>
                <PatientboldText>{detailedticket?.patientName}</PatientboldText>
            </GridItem>
            <GridItem colSpan={2} w='100%' h='100%'>
                <PatientLightText>Patient Phone No.</PatientLightText>
                <br></br>
                <PatientboldText>{detailedticket?.phoneNumber}</PatientboldText>
            </GridItem>
            <GridItem pl={2} colSpan={3} w='100%' h='100%'>
                <PatientLightText>Patient Address</PatientLightText>
                <br></br>
                <PatientboldText>{detailedticket?.address}</PatientboldText>
            </GridItem>
            <GridItem colSpan={3} w='100%' h='100%'>
                <PatientLightText>Delivering Pharmacy Name</PatientLightText>
                <br></br>
                <PatientboldText>
                    {detailedticket?.pharmacyName}
                </PatientboldText>
            </GridItem>
            <GridItem pl={2} colSpan={2} w='100%' h='100%'>
                <PatientLightText>Doctor name</PatientLightText>
                <br></br>
                <PatientboldText>{detailedticket?.doctorName}</PatientboldText>
            </GridItem>
        </Grid>
    )
}

export default Patientdetails