import { Button, Flex, Heading, Text } from '@chakra-ui/react'
import { useContext } from 'react'
import { ChatCradWrapper, OrderIdboldText, OrderIdLightText, OrderIdWrapper } from '../style';
import { TicketContext } from '../../../../../lib/Providers/TicketDetails';
import moment from 'moment/moment'
import { useNavigate } from 'react-router-dom';
import { Logincontext } from '../../../../../lib/Providers/Loginstatus'


const Chatcard = (props) => {

    const { order } = props
    const { setDetailedticket } = useContext(TicketContext)
    const navigate = useNavigate()
    const { setTabIndex, tabIndex } = useContext(Logincontext)
    const millisec = order.updatedAt;
    const orderTime = moment(millisec).startOf('hour').fromNow();

    const orderDetails = (id) => {
        setDetailedticket(id)
        setTabIndex(0)
        // console.log('tabIndex', tabIndex, 'order', id);
    };


    return (
        <ChatCradWrapper>
            <OrderIdWrapper>
                <OrderIdboldText>Order ID : #{order.id}</OrderIdboldText>
                <br></br>
                {order.orderId !== 0 ? <OrderIdLightText>(Sub Order of #{order.orderId})</OrderIdLightText> : null}

            </OrderIdWrapper>
            <Flex mt={4} justifyContent={'space-between'} textAlign="start" align="center">
                {/* <Heading fontSize={14} as="h6">{status.Oredrstatus}</Heading> */}
                <Text fontWeight={500} fontSize={12}>{orderTime}</Text>
            </Flex>
            <Flex mt={4} justifyContent={'space-between'}>
                <Button className='btn-tabs' //onClick={() => { orderDetails(order) }}
                >View Order Details</Button>
                {/* {order ? <div>{setDetailedticket(order)}</div> : <div>Loding...</div>} */}
                {/* <Button className='btn-tabs'>Call Doctor</Button> */}
            </Flex>
        </ChatCradWrapper>
    )
}
export default Chatcard