import { Box, Button, Flex, Heading, IconButton, Image, Img, Link, Spinner, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack, useBreakpointValue, useDisclosure, useToast } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import HeaderLogo from '../../assets/logo/instamedlogowhite.svg'
import Backicon from '../../assets/logo/backicon.svg'
import RejactionPopup from './RejactionPopup'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import Config from '../../lib/Config'
import moment from 'moment'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { saveAs } from 'file-saver'
import Oldprescirptionpopup from './Oldprescirptionpopup'

const Loader = () => {
    return (
        <>
            <Box
                w="100%"
                h="100%"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='green.100'
                    color='green.500'
                    size='xl'
                />
            </Box>
        </>
    )
}


const GeneratePrescription = () => {
    const { isOpen: rejectisOpen, onOpen: rejectonOpen, onClose: rejectonClose } = useDisclosure()
    const { isOpen: prescriptionisOpen, onOpen: prescriptiononOpen, onClose: prescriptiononClose } = useDisclosure()
    const navigate = useNavigate()
    const location = useLocation()
    const { state } = location
    const uploadURL = Config.File_Upload
    const [consultdetails, setConsultdetails] = useState()
    const [share, setShare] = useState(false)
    const [hidewhileprint, setHidewhileprint] = useState(true)
    const [isloading, setIsloading] = useState(false)
    const [prescriptionimage, setPrescriptionimage] = useState()
    const [rejectionreason, setRejectionreson] = useState()
    const docuser = JSON.parse(localStorage.getItem('docuser'))
    let docname = docuser?.firstName + " " + docuser?.lastName
    let docdegree = docuser.degree
    // console.log(docuser, state, 'stst');
    const contentRef = useRef(null);
    const Toast = useToast()

    const ToastNoti = (status, message) => {
        Toast({
            position: "top-right",
            description: message,
            status,
            duration: 5000,
            isClosable: true,
        });
    };

    function dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/png' });
    }

    const Custumbtn = (props) => {
        const { onClick, children, color, isloading } = props
        return (
            <>
                <Button
                    isDisabled={isloading}
                    onClick={onClick}
                    variant='solid'
                    background={color}
                    color='#fff'
                    rounded='md'
                    fontSize={14}
                    fontWeight={400}
                    border="solid 1px"
                    borderColor={color}
                    _hover={{
                        borderColor: color,
                        border: 'solid 1px',
                        background: 'transparent',
                        color: color
                    }}
                >
                    {children}
                </Button></>
        )
    }


    const GetConsultationdetails = async () => {
        if (state) {
            await axios.get(`${Config.Get_Consulttation_Details}${state.id}`)
                .then((res) => res.data)
                .then((data) => {
                    // console.log(data, "conc");
                    setConsultdetails(data.data)
                })
                .catch((error) => {
                    console.log(error);
                })

        }
    }


    useEffect(() => {
        GetConsultationdetails()
    }, [state])

    const handlePrintStart = () => {
        setHidewhileprint(false);
        setTimeout(function () {
            handleShare();
        }, 100);
    };

    const handlesignandshare = () => {
        setShare(true)
        handlePrintStart();
    }


    const handleShare = () => {
        // const input = document.getElementById('divtoprint');
        // html2canvas(container).then(async (canvas) => {
        //     const maxAllowedSize = 1 * 1024 * 1024;
        //     const imgWidth = 360;
        //     const imgHeight = canvas.height * imgWidth / canvas.width;
        //     const imgData = canvas.toDataURL('image/png');
        //     console.log('CANVAS', canvas);
        //     // const imgData = canvas.toDataURL({
        //     //     format: 'jpeg',
        //     //     quality: 0, // compression works now!
        //     // });
        //     const pdf = new jsPDF({
        //         orientation: 'portrait',
        //         unit: 'mm',
        //         format: "b3",
        //         compressPdf: true,
        //     });
        //     pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight);
        //     // Save or share the PDF
        //     pdf.save('screenshot.pdf');
        // })

        // html2canvas(contentRef.current).then((canvas) => {
        //     const imgWidth = 210; // PDF page width (A4)
        //     const imgHeight = (canvas.height * imgWidth) / canvas.width;

        //     const imgData = canvas.toDataURL('image/png');

        //     const pdf = new jsPDF('p', 'mm', 'a4');

        //     pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);

        //     // Save or share the PDF
        //     pdf.save('screenshot.pdf');
        //   });

        if (contentRef.current) {
            const content = contentRef.current;

            // Calculate the total height of the content
            const totalHeight = content.clientHeight;

            // Create a jsPDF instance
            const pdf = new jsPDF({
                orientation: 'portrait',
                format: "b3",
                compressPdf: true,
            });

            // Function to capture and add content to the PDF
            const captureAndAddPage = (y) => {
                html2canvas(content, { scrollY: -y }).then((canvas) => {
                    const imgWidth = 360;
                    const imgHeight = canvas.height * imgWidth / canvas.width;
                    // console.log('CANVAS', canvas, y, imgHeight);
                    const imgData = canvas.toDataURL('image/png');
                    const blob = dataURItoBlob(imgData);
                    // console.log(blob, "blob");
                    // console.log('Blob size:', blob.size, blob.type);
                    const newfile = new File([blob], 'image.png', { type: blob.type })
                    const formData = new FormData();
                    formData.append('image[0]', newfile);
                    if (consultdetails?.order_id) {
                        formData.append('order_id', consultdetails?.order_id);
                    }
                    uploadimage(formData)
                    setIsloading(true)
                    setHidewhileprint(true)
                    // saveAs(imgData, `${consultdetails?.patientName}.png`);
                    // pdf.addImage(imgData, 'PNG', 0, 0, 360, imgHeight);
                    // // y += 297; // Height of an A4 page in mm
                    // pdf.save(`${consultdetails?.patientName}.jpg`);
                });
            };
            captureAndAddPage(0);
        }
    }


    const uploadimage = async (imagedata) => {
        // console.log(imagedata, "lojko");
        var user = 'auth@anahad.in';
        var password = 'pass@12345';
        var buff = Buffer.from(user + ':' + password)
        var base64encodedData = buff.toString('base64')
        await axios.post(uploadURL, imagedata, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': 'Basic ' + base64encodedData,
            },
        })
            .then((result) => result.data)
            .then((data) => {
                // console.log('data', data?.imagedata[0]);
                setPrescriptionimage(data?.imagedata[0]);
                setIsloading(false)
                ToastNoti('success', "Prescrition uploaded successfully")
            })
            .catch((err) => {
                console.log('err', err);
                setIsloading(false)
                ToastNoti('error', "Something went worng !!!")
            })
    }

    const UpdatePrescription = async (status) => {
        setIsloading(true)
        let obj
        if (consultdetails?.order_id) {
            obj = {
                request_status: status,
                order_id: consultdetails?.order_id,
                image_id: prescriptionimage.id,
                prescription_link: prescriptionimage.image,
            }
        } else if (consultdetails?.hdp_order_id) {
            obj = {
                request_status: status,
                hdp_order_id: consultdetails?.hdp_order_id,
                image_id: prescriptionimage.id,
                prescription_link: prescriptionimage.image,
            }
        } else {
            return
        }

        await axios.post(`${Config.Update_Consultation_Prescription}${state.id}`, obj)
            .then((result) => result.data)
            .then((data) => {
                setIsloading(false)
                // console.log('log', data);
                ToastNoti('success', "Prescrition sent successfully")
                navigate('/doctordashboard')

            })
            .catch((err) => {
                setIsloading(false)
                ToastNoti('error', "Something went worng !!!")
                console.log('err', err);
            })
    }

    const UpdatePrescriptionReject = async (status, remark) => {
        // console.log(status, remark, "reject");
        setIsloading(true)
        let obj
        if (consultdetails?.order_id) {
            if (remark) {
                obj = {
                    request_status: status,
                    order_id: consultdetails?.order_id,
                    remark
                }
            }
        } else if (consultdetails?.hdp_order_id) {
            if (remark) {
                obj = {
                    request_status: status,
                    hdp_order_id: consultdetails?.hdp_order_id,
                    remark
                }
            }
        } else {
            return
        }

        await axios.post(`${Config.Update_Consultation_Prescription}${state.id}`, obj)
            .then((result) => result.data)
            .then((data) => {
                setIsloading(false)
                // console.log('log', data);
                ToastNoti('success', "Prescrition rejected successfully")
                navigate('/doctordashboard')
            })
            .catch((err) => {
                setIsloading(false)
                ToastNoti('error', "Something went wrong !!!")
                console.log('err', err);
            })
    }


    return (
        <>
            <Oldprescirptionpopup onClose={prescriptiononClose} isOpen={prescriptionisOpen} Pimages={consultdetails?.orderPrescriptionImageList} />
            <RejactionPopup onClose={rejectonClose} isOpen={rejectisOpen} handlePrintStart={handlePrintStart} rejectionreason={rejectionreason} setRejectionreson={setRejectionreson} UpdatePrescription={UpdatePrescriptionReject} />
            {
                isloading
                    ?
                    <Loader isloading={isloading} />
                    :
                    <div id='divtoprint' ref={contentRef} style={{ height: 'auto', backgroundColor: '#fff' }}>
                        <Box bg='#fff' h={'100%'} >
                            <Box
                                as='header'
                                bg='#16999e'
                                roundedBottom='50px'
                                py={6}
                                px={16}
                                color='#fff'
                            >
                                <Flex justifyContent='space-between' alignItems='center'>
                                    <VStack spacing='0' alignItems='flex-start'>
                                        <Heading as='h2' textTransform={'capitalize'} fontSize={16} mb={1}>Dr. {docname}</Heading>
                                        <Text fontSize={14} >{docdegree}</Text>
                                    </VStack>
                                    <Flex alignItems='center'>
                                        <Image src={HeaderLogo} />
                                    </Flex>
                                    <Box>
                                        <Text fontSize={14}>MRN: 098765YU4355</Text>
                                        <Text fontSize={14}>Saket, New Delhi</Text>
                                    </Box>
                                </Flex>
                            </Box>
                            <Box px={16}>
                                {
                                    hidewhileprint &&
                                    <Flex mt={12} >
                                        <Img cursor='pointer' src={Backicon} onClick={() => { navigate('/doctordashboard') }} />
                                        <Text as='span' pl={4}>Back</Text>
                                    </Flex>
                                }
                                <Flex mt={4} p={2} rounded='md' justifyContent='space-between' alignItems='center' border='solid 1px' borderColor='gray.400'>
                                    <Text>Patient: <Text as='span' fontWeight={700} textTransform={'capitalize'} >{consultdetails?.patientName || '--'}</Text></Text>
                                    <Text as='span' fontWeight={700}>{moment(consultdetails?.orderDate).format("DD/MM/YYYY")}</Text>
                                </Flex>
                                <Flex mt={4} justifyContent='space-between'>
                                    {hidewhileprint &&
                                        (consultdetails?.orderPrescriptionImageList?.length > 0 &&
                                            <Custumbtn onClick={prescriptiononOpen} color='#16999e'>
                                                View Old Prescription
                                            </Custumbtn>
                                        )
                                    }
                                    {hidewhileprint &&
                                        <Box display={'block'} width={'100%'} textAlign={'end'}>
                                            <Custumbtn color='#16999e'>
                                                Call Patient : {consultdetails?.patientPhoneNumber}
                                            </Custumbtn>
                                        </Box>
                                    }
                                </Flex>
                                <Box mt={8} p={6} rounded='2xl' justifyContent='space-between' alignItems='center' border='solid 1px' borderColor='gray.400'>
                                    <Heading fontSize={16}>Medicine required</Heading>
                                    <TableContainer mt={6}>
                                        <Table>
                                            <Thead>
                                                <Tr>
                                                    <Th width='40%' borderColor='transparent'>Name</Th>
                                                    <Th borderColor='transparent'>Qty</Th>
                                                </Tr>
                                            </Thead>
                                            <Tbody>
                                                {
                                                    consultdetails?.orderMedicineList?.map((item, index) => {
                                                        return (
                                                            <Tr key={index}>
                                                                <Td borderColor='transparent' textTransform={'capitalize'} py={2}>{index + 1}.{' '}{item?.medicineName}</Td>
                                                                <Td borderColor='transparent' py={2}>{item?.quantity}</Td>
                                                            </Tr>
                                                        )
                                                    })
                                                }
                                            </Tbody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                                <Flex mt={4} justifyContent='space-between' alignItems='center'>
                                    <VStack alignItems='flex-start'>
                                        {hidewhileprint &&
                                            <Custumbtn onClick={rejectonOpen} color='red'>
                                                Reject Prescription
                                            </Custumbtn>}
                                        <Text mt={4} fontWeight={600}>E-Prescription available on your InstaMed Mobile App.<br /> Download on <Link color='#16999e'>Play Store</Link> or <Link color='#16999e'>App Store</Link></Text>
                                    </VStack>
                                    <VStack alignItems='flex-start'>
                                        {
                                            share ?
                                                (hidewhileprint &&
                                                    <Custumbtn
                                                        isloading={isloading}
                                                        onClick={() => { UpdatePrescription('APPROVED') }}
                                                        color='#16999e'>
                                                        Send Prescription
                                                    </Custumbtn>) :
                                                <Custumbtn onClick={handlesignandshare} color='#16999e'>
                                                    Generate Prescription
                                                </Custumbtn>
                                        }
                                        <VStack mt={8} width='100%' spacing={0}>
                                            <Text>{moment().format("DD/MM/YYYY")}</Text>
                                            <Heading fontSize={18} textTransform={'capitalize'} >Dr. {docname}</Heading>
                                            <Text as='small'>{docdegree}</Text>
                                            <Text as='small' visibility={'hidden'}>{docdegree}</Text>
                                        </VStack>
                                    </VStack>
                                </Flex>
                            </Box>
                        </Box >
                    </div>
            }
        </>
    )
}

export default GeneratePrescription