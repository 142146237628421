import styled from 'styled-components';

export const Topbar = styled.div`
    display: flex;
    height: 12vh;
    align-items: center;
    padding: 1em;
    justify-content: space-between;
`;

export const LogTime = styled.div`
    flex: 1;
    margin-left: 8%;
`;

export const TakeABreak = styled.div`
    margin-right: 1.2em;
`;

export const Profile = styled.div``;

export const LogText = styled.p`
    margin: 0;
    font-size: 1.1vw;
`;

export const CustomButton = styled.button`
background-color: rgb(29, 191, 103);
    border: none;
    color: white;
    letter-spacing: 0px;
    flex-direction: row;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    font-size: 1.2vw;
    font-weight: 600;
    border-radius: 30px;
    height: 7vh;
    width: 35%;
    outline: none;
    text-align: center;
    -webkit-box-pack: start;
    justify-content: flex-start;
    padding: 0.5rem 3rem 0.5rem 0.5rem;
    margin: 2rem auto 1rem;
`;


