import { Box, FormControl, FormErrorMessage, FormLabel, Input, VStack } from '@chakra-ui/react'
import axios from 'axios'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import loginIcon from '../../assets/logo/loginIcon.svg'
import Config from '../../lib/Config'
import { loginschema } from '../../lib/Validators/Loginvalidation'
import { ButtonSvg, CustomButton, ErrorMessage, HeadWrapper, LogoBtn, MainWrapper, QuoteBookMainWrapper, TabName, TabsMainWrapper, WhiteCenterDiv } from './styles'

const LoginPage = () => {
    const navigate = useNavigate()
    const [error, setError] = useState()

    const formik = useFormik({
        initialValues: {
            username: '',
            password: ''
        },
        validationSchema: loginschema,
        onSubmit: async (values) => {
            try {
                const res = await axios.post(`${Config.Auth_Url}`, {
                    userName: values?.username,
                    password: values?.password
                }
                )
                console.log('res',res?.data?.data?.employee);
                localStorage.setItem("userid", res?.data?.data?.employee?.id)
                localStorage.setItem("token", res?.data?.data?.token)
                // localStorage.setItem("refreshToken", res?.data?.data?.refreshToken)
                localStorage.setItem("userName", res?.data?.data?.employee?.userName)
                localStorage.setItem("role",  res?.data?.data?.employee?.role)
                localStorage.setItem("Logintime", new Date())
                if (res?.data?.data?.employee?.role === 'Insta_Agent') {
                    navigate("/dashboard")
                } else if (res?.data?.data?.employee?.role === 'Insta_Doctor') {
                    // getDoctorDetailsById(res?.data?.data?.userID, res?.data?.data?.token);
                    localStorage.setItem("docuser", JSON.stringify(res?.data?.data?.employee))
                    navigate('/doctordashboard')
                } else {
                    setError("Something went wrong !!")
                }
            }
            catch (error) {
                console.log(error, "rtrt");
                setError(error?.response?.data?.meta?.msg)
            }
        }
    })

    const {
        handleSubmit,
        handleChange,
        handleBlur,
        // setFieldValue,
        values,
        errors,
        touched,
        // setFieldTouched,
    } = formik;

    const getDoctorDetailsById = async (id, token) => {
        try {
            const res = await axios.get(`${Config.Get_Doctor_Detail_By_ID}/${id}`,
                {
                    headers: {
                        authorization: `Bearer ${token}`,
                    }
                }
            )
            if (res?.data?.meta?.statusCode === 200) {
                localStorage.setItem("userid", res?.data?.data?.id)
                localStorage.setItem("docuser", JSON.stringify(res?.data?.data))
                navigate('/doctordashboard')
            }
        } catch (error) {
            setError(error?.response?.data?.meta?.msg)
        }
    }

    return (
        <MainWrapper>
            <HeadWrapper>
                <LogoBtn>Instamed IDP</LogoBtn>
            </HeadWrapper>
            <TabsMainWrapper>
                <Box width="45%">
                    <TabName style={{ backgroundColor: '#16999e', marginLeft: '1.5rem' }}>Login</TabName>
                    <WhiteCenterDiv>
                        <form onSubmit={handleSubmit}>
                            <QuoteBookMainWrapper>
                                <VStack spacing={8}>
                                    <FormControl isInvalid={errors.username && touched.username}>
                                        <FormLabel>User Name</FormLabel>
                                        <Input bg="#F0F0F0" maxLength="15" variant='filled' type='text' name='username' id='username' onChange={handleChange} onBlur={handleBlur} />
                                        <FormErrorMessage>{errors.username}</FormErrorMessage>
                                    </FormControl>
                                    <FormControl isInvalid={errors.password && touched.password}>
                                        <FormLabel>Password</FormLabel>
                                        <Input bg="#F0F0F0" variant='filled' type='password' name='password' id='password' onChange={handleChange} onBlur={handleBlur} />
                                        <FormErrorMessage>{errors.password}</FormErrorMessage>
                                    </FormControl>
                                    <CustomButton type='submit'>
                                        <ButtonSvg src={loginIcon} /> Login
                                    </CustomButton>
                                    {error && <ErrorMessage>
                                        {/* {errors.password || errors.username} */}
                                        {error || 'Error While Login, Please Check Your UserId and Password'}
                                    </ErrorMessage>}
                                </VStack>
                            </QuoteBookMainWrapper>
                        </form>
                    </WhiteCenterDiv>
                </Box>
            </TabsMainWrapper>
        </MainWrapper>
    )
}

export default LoginPage