
const unAccessPaths = ["/"]
const accessPaths = ['/dashboard']
const docaccesspaths = ['/doctordashboard', '/generateprescription']

const IsRouteAllowed = (path, isloggedIn, role) => {
    let isAllowed = false;
    if (isloggedIn && role === "Insta_Agent") {
        isAllowed = accessPaths.indexOf(path) > -1;
    }
    else if (isloggedIn && role === "Insta_Doctor") {
        isAllowed = docaccesspaths.indexOf(path) > -1;
    } else {
        isAllowed = unAccessPaths.indexOf(path) > -1;
    }
    return isAllowed
}

export default IsRouteAllowed