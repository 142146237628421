import React from "react";
import styled from "styled-components";

import {
    StyledCardContainer,
    HeadingWrapper,
    StyledHeading,
    SpaceBetweenWrapper,
    StyledData,
    FooterTextTrips,
    FlexWrapper,
    SeeMoreText
} from "./index";

// import trips from "../../../images/trips.svg";
// import gold from "../../../images/gold.svg";
// import silver from "../../../images/silver.svg";
// import bronze from "../../../images/bronze.svg";
// import { numberSuffix } from '../../../utils/helper';

const TripsStyledData = styled(StyledData)`
  font-size: 0.9vw;
`;

const StyledImage = styled.img`
  height: 1vw;
  width: 1vw;
`;

const TripsLeaderboardCard = ({ tripsCount = [], setSeeMore = () => { }, seeMore = false }) => {
    return (
        <StyledCardContainer>
            <HeadingWrapper>
                <StyledHeading> Trips Leaderboard </StyledHeading>
                {/* <img src={trips} alt="leaderboard logo" /> */}
            </HeadingWrapper>
            <FlexWrapper style={{ height: seeMore ? '50vh' : 'auto', width: '100%' }}>
                <SpaceBetweenWrapper>
                    <TripsStyledData>Name</TripsStyledData>
                    <TripsStyledData>Tickets</TripsStyledData>
                </SpaceBetweenWrapper>
                <SpaceBetweenWrapper>
                    <TripsStyledData>1. Sanket</TripsStyledData>
                    <TripsStyledData>5 Trips</TripsStyledData>
                </SpaceBetweenWrapper>
                <SpaceBetweenWrapper>
                    <TripsStyledData>2. Vivek</TripsStyledData>
                    <TripsStyledData>3 Trips</TripsStyledData>
                </SpaceBetweenWrapper>
                <SpaceBetweenWrapper>
                    <TripsStyledData>3. Faiz</TripsStyledData>
                    <TripsStyledData>2 Trips</TripsStyledData>
                </SpaceBetweenWrapper>
            </FlexWrapper>
            <FooterTextTrips />
            {/* <SeeMoreText onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'See Less' : 'See More'}</SeeMoreText> */}
        </StyledCardContainer>
    );
};

export default TripsLeaderboardCard;
