/* eslint-disable no-restricted-globals */
import { Box, Button, useToast } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { Logincontext } from '../../../../../lib/Providers/Loginstatus'
import Patientdetails from './Patientdetails'
import PatientSelect from './PatientSelect'
import { TicketContext } from '../../../../../lib/Providers/TicketDetails'
import axios from 'axios'
import Config from '../../../../../lib/Config'

const CaseDespction = (props) => {
    const { tabchange } = props
    const { setRefresh } = useContext(Logincontext)
    const Toast = useToast()
    const { detailedticket, setDetailedticket } = useContext(TicketContext)
    const [disposition, setDisposition] = useState()
    const [topic, setTopic] = useState()
    const [subtopic, setSubtopic] = useState()
    const [outcome, setOutcome] = useState()



    const handledidposition = async () => {
        if (detailedticket) {
            if (disposition && topic && subtopic && outcome) {
                delete detailedticket.ticketStatus
                const discompositionobj = {
                    ...detailedticket,
                    ticketStatus: 'DISPOSITION',
                    disposition: disposition,
                    topic: topic,
                    subTopic: subtopic,
                    outCome: outcome,
                }
                await axios.post(`${Config.Upadte_Ticket}/${detailedticket?.id}`, discompositionobj)
                    .then((result) => result)
                    .then((data) => {
                        // console.log(data, "dataaaf")
                        tabchange()
                        setDetailedticket()
                        window.location.reload()
                        setRefresh((pre) => pre + 1)

                        // setDetailedticket(data.data.data)
                        // GetticketDeatilsbyid()
                        // setFetch((pre) => pre + 1)
                        // setPlaceorderdisabled(true)
                        // tabchange()

                    })
                    .catch((err) => {
                        console.log(err, "error");
                    })
            }
            else {
                Toast({
                    position: 'top-right',
                    description: 'Please Select All The Fields ',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }

    }
    return (
        <>
            <Patientdetails />
            <PatientSelect
                disposition={disposition} setDisposition={setDisposition}
                topic={topic} setTopic={setTopic}
                subtopic={subtopic} setSubtopic={setSubtopic}
                outcome={outcome} setOutcome={setOutcome}
            />
            <Box mt={8} textAlign="center" pt={8} pb={2} mr={4} ml={0}>
                <Button width={130} className='btn-tabs' onClick={handledidposition}>Save</Button>
            </Box>
        </>
    )
}

export default CaseDespction