import React, { createContext, useReducer, useState } from 'react'
import { cartReducer } from '../Reducers.js/Cartreducer';

const Cartcontext = createContext()
const CartFormation = ({children}) => {

    const [state, dispatch] = useReducer(cartReducer, {
        cart: [],
      }); 

  return (
    <Cartcontext.Provider value={{state,dispatch}}>
        {children}
    </Cartcontext.Provider>
  )
}

export { Cartcontext , CartFormation}