import { Box, Button, Flex, Grid, GridItem, HStack, Image, Text, VStack } from '@chakra-ui/react'
import axios from 'axios'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { FiChevronLeft } from 'react-icons/fi'
import Config from '../../../../../lib/Config'
import { Logincontext } from '../../../../../lib/Providers/Loginstatus'
import { TicketContext } from '../../../../../lib/Providers/TicketDetails'
import { OrderIdboldText, OrderIdLightText, TicketIdboldText } from '../../Chatcomponent/style'
import { OrderIdWrapper, PatientboldText, PatientLightText } from '../OrderPlaceTab/style'
import { LiveTicketCardContainer } from './styles'
import TicketNotecard from './TicketNotecard'

const LiveTicketDetails = (props) => {
    const { setShowdetails, ticketdetail } = props
    const [selectedticketdetail, setSelectedticketdetails] = useState()
    const { setTabIndex, tabindex } = useContext(Logincontext)
    const { setDetailedticket, fetch } = useContext(TicketContext)
    const GetticketDeatilsbyid = async () => {
        if (ticketdetail) {
            await axios.get(`${Config.Get_TicketDetail_By_Id}${ticketdetail}`)
                .then((result) => result)
                .then((data) => {
                    setSelectedticketdetails(data.data.data)
                    if (data.data.data.ticketStatus === 'neworder') {
                        setDetailedticket(data.data.data)
                    }

                })
                .catch((err) => {
                    console.log(err, "error");
                })
        }
    }

    useEffect(() => {
        GetticketDeatilsbyid()
    }, [ticketdetail, fetch])


    const handletabchange = () => {
        if (selectedticketdetail.ticketStatus === 'neworder') {
            setTabIndex(0)
        }
    }

    const handleopen = (link) => {
        window.open(link, "_black")
    }
    return (
        <LiveTicketCardContainer>
            <Grid px={6} alignItems="center" templateColumns='repeat(12, 1fr)' gap={0}>
                <GridItem position="relative" colSpan={5} w='100%' h='100%'>
                    <Box
                        position="absolute"
                        top={0}
                        left={-8}
                        cursor="pointer"
                    ><FiChevronLeft fontSize={30} onClick={() => { setShowdetails(false) }} /></Box>
                    <OrderIdWrapper>
                        <OrderIdboldText onClick={handletabchange}>Tickets Id: #{selectedticketdetail?.id}</OrderIdboldText>
                    </OrderIdWrapper>
                    <VStack spacing={1} mt={8} align="flex-start">
                        <Box>
                            <PatientLightText>Created by : </PatientLightText>
                            <PatientboldText> {selectedticketdetail?.orderByAgent || "---"} </PatientboldText>
                        </Box>
                        <Box>
                            <PatientLightText>Created on : </PatientLightText>
                            <PatientboldText> {moment(selectedticketdetail?.createdAt).format("DD MMM YYYY, hh:mm a")}</PatientboldText>
                        </Box>
                        <Box>
                            <PatientLightText>Patient name :  </PatientLightText>
                            <PatientboldText> {selectedticketdetail?.patientName}</PatientboldText>
                        </Box>
                        <Box>
                            <PatientLightText>Patient Phone No. :  </PatientLightText>
                            <PatientboldText> {selectedticketdetail?.phoneNumber}</PatientboldText>
                        </Box>
                        <Box>
                            <PatientLightText>Lead Source :  </PatientLightText>
                            <PatientboldText>{selectedticketdetail?.sourceType?.slice(0, 1) + selectedticketdetail?.sourceType?.toLowerCase()?.slice(1)}</PatientboldText>
                        </Box>
                        <Box>
                            <TicketIdboldText >Order ID : #{selectedticketdetail?.orderId || "---"}</TicketIdboldText>
                            <br></br>
                            <OrderIdLightText> (Sub Order of #{selectedticketdetail?.orderId || "---"})</OrderIdLightText>
                        </Box>
                    </VStack>
                </GridItem>
                <GridItem colSpan={7} w='100%' h='100%'>
                    <Box textAlign="end">
                        {
                            selectedticketdetail?.prescriptionLink && <Button className='btn-tabs' isDisabled={selectedticketdetail?.prescriptionLink === null ? true : false} onClick={() => { handleopen(selectedticketdetail?.prescriptionLink) }} >View Prescription</Button>
                        }
                        {selectedticketdetail?.orderImages?.length > 0 &&
                            <HStack justifyContent={'end'}>
                                <PatientboldText>Prescriptions : </PatientboldText>
                                {selectedticketdetail?.orderImages?.map((item) => {
                                    return (
                                        <>
                                            <Image cursor={'pointer'} w={'50px'} h={'50px'} src={item?.prescription_link} onClick={() => { handleopen(item?.prescription_link) }} />
                                        </>
                                    )
                                })
                                }
                            </HStack>
                        }

                    </Box>

                    <VStack spacing={1} mt={8} align="flex-start">
                        <Box>
                            <PatientLightText>Doctor name :  </PatientLightText>
                            <PatientboldText>{selectedticketdetail?.doctorName}</PatientboldText>
                        </Box>
                        <Box>
                            <PatientLightText>Delivering From :  </PatientLightText>
                            <PatientboldText>
                                {/* InstaMed Paaras Store */}
                                {selectedticketdetail?.pharmacyName}
                            </PatientboldText>
                        </Box>
                        <Box>
                            <PatientLightText>Delivering To :   </PatientLightText>
                            <PatientboldText> {selectedticketdetail?.address}</PatientboldText>
                        </Box>
                    </VStack>
                </GridItem>
            </Grid>
            {/* <TicketNotecard /> */}
        </LiveTicketCardContainer >
    )
}

export default LiveTicketDetails