import { Avatar, Button, Flex, Input, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import LogoHeading from '../../LogoHeading'
import { LogText, LogTime, Profile, TakeABreak, Topbar } from './style'
import { AiOutlineCaretDown } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import Popupmodel from '../../Popupmodel/Popupmodel';
import { ButtonSvg } from '../../../containers/LoginPage/styles';
import loginIcon from '../../../assets/logo/loginIcon.svg'
import { CustomButton } from './style'


const breaktype = [
    { id: "Tea", value: "Tea" },
    { id: "Bio", value: "Bio" },
    { id: "Lunch", value: "Lunch" },
    { id: "Feedback", value: "Feedback" },
    { id: "Training", value: "Training" }
]

const DashboardTopbar = () => {
    let breaktype1 = localStorage.getItem('breaktype');
    const loggedintime = localStorage.getItem("Logintime")
    const username = localStorage.getItem("userName")
    const [showModal, setShowModal] = useState(false);
    var [date,setDate] = useState(new Date());
    const handlebreak = (id) => {
        setShowModal(true)
        localStorage.setItem('breaktype', id)
    }
    const handlebreakover = () =>{
        localStorage.removeItem('breaktype')
        setShowModal(false)
       }

    useEffect(() => {
        
        if (breaktype1) {
            setShowModal(true)
        }
    }, [breaktype1])
   
   

    const navigate = useNavigate()
    const handlenavigayte = () =>{
        localStorage.clear()
        navigate('/')
    }


    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 30000 )
        return function cleanup() {
            clearInterval(timer)
        }
    },[]);

    return (
        <Topbar>
            <LogoHeading />
            <LogTime>
                <LogText>{moment(date).format("Do MMM YYYY,     dddd, hh:mm a")}
                    {/* 12th Sep 2020, Monday, 12.45pm, */}
                </LogText>
                <LogText>Logged @ {moment(loggedintime).format('HH:mm a')}</LogText>
            </LogTime>
            {/* <TakeABreak>
                <Menu>
                    <MenuButton
                        bg="#00AB83"
                        color="#fff"
                        _hover={{ bg: '#00AB83' }}
                        _active={{ bg: '#00AB83' }}
                        fontSize="0.875rem!important"
                        fontWeight={400}
                        as={Button}
                        rightIcon={<AiOutlineCaretDown size="1.2rem" />}
                    >
                        Take a Break
                    </MenuButton>
                    <MenuList
                        minW="8.5rem!important"
                        w="8.5rem!important"
                        fontSize="0.875rem!important"
                        bg="#00AB83"
                        color="#fff"
                    >
                        {
                            breaktype?.map((item) => (
                                <MenuItem onClick={()=>{handlebreak(item.id)}}
                                    bg="none"
                                    _hover={{ bg: '#fff', color: '#00ab83' }}
                                >{item.value}</MenuItem>
                            ))
                        }
                    </MenuList>
                </Menu>
            </TakeABreak> */}
            <Profile>
                <Flex align="center">
                    <Menu>
                        <MenuButton
                            bg="transparent"
                            color="#343434"
                            _hover={{ bg: 'transparent' }}
                            _active={{ bg: 'transparent' }}
                            fontSize="0.875rem!important"
                            fontWeight={400}
                            as={Button}
                            rightIcon={<AiOutlineCaretDown size="1.2rem" color='#5C8081' />}
                        >
                            Hi {username}
                        </MenuButton>
                        <MenuList
                            minW="8.5rem!important"
                            w="8.5rem!important"
                            fontSize="0.875rem!important"
                            bg="#fff"
                            color="#00ab83"
                        >
                            <MenuItem
                                bg="none"
                                _hover={{ bg: 'none', color: '#00ab83' }} 
                                onClick = {handlenavigayte}
                            >Logout</MenuItem>
                        </MenuList>
                    </Menu>
                    <Avatar bg="transparent" border="solid 2px #fff" name={username} src='' />
                </Flex>
            </Profile>
            {
                showModal && <Popupmodel showModal={showModal} setShowModal={setShowModal}>
                    <p>Enter Password to Resume</p>
                    <Input width="60%" height="6vh" //onChange={(e) => checkPassword(e)} 
                    />
                    <CustomButton onClick={handlebreakover}>
                        <ButtonSvg src={loginIcon}  /> Login
                    </CustomButton>
                </Popupmodel>
            }
        </Topbar>
    )
}

export default DashboardTopbar