const primary = '#16999e';

const styles = () => (
  <style global jsx>{`
    .react-calendar {
      background-color: white;
      padding: 10px 10px 0px 10px;
      max-widht: 200px;
    }
    .react-date-picker {
      display: inline-flex;
      position: relative;
      width: 100%;
    }
    .react-date-picker,
    .react-date-picker *,
    .react-date-picker *:before,
    .react-date-picker *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
    .react-date-picker--disabled {
      background-color: #f0f0f0;
      color: #6d6d6d;
    }
    .react-date-picker__wrapper {
      display: flex;
      flex-grow: 1;
      flex-shrink: 0;
      border: 1px solid #c4c4c4;
      padding: 0.5rem;
      border-radius: 8px;
    }
    .react-date-picker__inputGroup {
      min-width: 60%;
      flex-grow: 1;
      padding: 0 2px;
      box-sizing: content-box;
    }
    .react-date-picker__inputGroup__divider {
      padding: 1px 0;
      white-space: pre;
    }
    .react-date-picker__inputGroup__input {
      min-width: 0.54em;
      height: 100%;
      position: relative;
      padding: 0 1px;
      border: 0;
      background: none;
      font: inherit;
      box-sizing: content-box;
      -moz-appearance: textfield;
    }
    .react-date-picker__inputGroup__input::-webkit-outer-spin-button,
    .react-date-picker__inputGroup__input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .react-date-picker__inputGroup__input:invalid {
      background: rgba(255, 0, 0, 0.1);
    }
    .react-date-picker__inputGroup__input--hasLeadingZero {
      margin-left: -0.54em;
      padding-left: calc(1px + 0.54em);
    }
    .react-date-picker__button {
      border: 0;
      background: transparent;
      padding: 4px 6px;
    }
    .react-date-picker__button:enabled {
      cursor: pointer;
    }
    .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
    .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
      stroke: #0078d7;
    }

    .react-date-picker__inputGroup__year {
      min-width: 40px !important;
    }

    .react-date-picker__button:disabled .react-date-picker__button__icon {
      stroke: #6d6d6d;
    }
    .react-date-picker__button svg {
      display: inherit;
    }
    .react-date-picker__calendar {
      width: 350px;
      max-width: 100vw;
      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1;
    }
    .react-date-picker__calendar--closed {
      display: none;
    }
    .react-date-picker__calendar .react-calendar {
      border-width: thin;
    }

    .react-calendar button {
      font-weight: 600;
    }

    .react-calendar__navigation {
      border-bottom: 1px solid #eae9e9;
    }

    .react-calendar__navigation button {
      min-width: 44px !important;
      background: none !important;
    }
    .react-calendar__navigation {
      display: -webkit-flex !important;
      display: flex !important;
      height: 44px !important;
      margin-bottom: 1em !important;
    }
    z .react-calendar {
      width: 400px !important;
      border-radius: 8px;
      box-shadow: 0px 0px 15px 10px rgba(0, 0, 0, 0.05);
    }

    .react-calendar__month-view__days__day--weekend {
      color: black;
    }

    abbr {
      text-decoration: none !important;
      white-space: nowrap;
    }

    .react-calendar__month-view__weekdays {
      color: #808080;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      margin-top: 5px;
      text-align: center;
    }

    .react-calendar__navigation {
      margin-bottom: 0;
    }

    .react-calendar__month-view__days__day--neighboringMonth {
      visibility: hidden;
    }

    .react-calendar__tile {
      border-radius: 50px;
      height: 3rem;
      color: ${primary};
      font-weight: bold;
    }

    .react-calendar__tile--now {
      background: white;
    }

    .react-calendar__tile--active {
      color: white;
      background: ${primary};
    }

    .react-calendar__tile--active:enabled:hover,
    .react-calendar__tile--active:enabled:focus {
      background: ${primary};
    }

    .react-calendar__tile.react-calendar__year-view__months__month,
    .react-calendar__tile.react-calendar__decade-view__years__year,
    .react-calendar__tile.react-calendar__century-view__decades__decade {
      padding: 0.5em !important;
      margin: 5px 0 !important;
    }

    .react-calendar__century-view__decades {
      white-space: nowrap;
    }

    .react-calendar__month-view__days {
      border-bottom: 1px solid #eae9e9;
    }

    .react-date-picker__calendar-button {
      margin-right: 15px;
    }
  `}</style>
);

export default styles;
