import { Box, Button, Center, Flex, Heading, HStack, Image, Input, Select, Spinner, Text, useToast, VStack } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import Done from "../../../../../../assets/logo/Done.svg"
import Closegray from "../../../../../../assets/logo/Closegray.svg"
import axios from 'axios'
import { TicketContext } from '../../../../../../lib/Providers/TicketDetails'
import { useEffect } from 'react'
import Config from '../../../../../../lib/Config'

const CartCoupon = ({ total, setTotal, mrp, setCouponresponse, setDiscountonmrp, discountonmrp,
    setDiscountpercentage, setMaxdiscount, setYousaved, setPlaceorderdisabled, couponcode, setCouponcode,
    disable, setDisable, handleremovecoupon, setMinimumorderamount,
    selectedNearbyPharmacy, setSelectedNearbypharmacy, medicinemrp, setMinimumordervaluedisable, isCouponDisable,
    setisCoupondisable, isselectDisable, setisselectdisable
}) => {
    const toast = useToast()
    // const [couponcode, setCouponcode] = useState()
    // const [disable, setDisable] = useState(false)
    // const [discountonmrp, setDiscountonmrp] = useState()
    const { detailedticket, setFetch, fetch, nearbystoreslist } = useContext(TicketContext)
    const [isloading, setIsloading] = useState(false)





    const handlecouponapply = async () => {
        if (detailedticket.longitude && detailedticket.latitude) {
            // if (!disable) {
            setIsloading(true)
            await axios.post(Config.Apply_Coupon, {
                vendor_id: 41167,
                is_langauge: "en",
                original_price: medicinemrp,
                coupon_code: couponcode,
                delivery_type_time_slots: detailedticket?.delivery_pickup_types === "Takeaway" ? "0" : "1",
                latitude: detailedticket.latitude, //"26.89785963486394" for testing lat long
                longitude: detailedticket.longitude, //"75.76839593696442"
                hdp_order: 1
            })
                .then((result) => result)
                .then((data) => {
                    setIsloading(false)
                    if (data.data.Result && data.data.code === 101) {
                        setCouponcode("")
                        toast({
                            title: data.data.msg,
                            position: 'top-right',
                            status: 'error',
                            duration: 5000,
                            isClosable: true,
                        })
                    } else if (data.data.Result && data.data.code === 200) {
                        setisselectdisable(true)
                        setCouponresponse(data.data.Result)
                        setDiscountpercentage(data.data.Result?.promocode?.discount_percentage) //Check with instamed team if this field is changed
                        setMaxdiscount(data.data.Result.promocode.maximum_discount_amount)
                        setMinimumorderamount(data.data.Result.promocode.minimum_order_amount)
                        setFetch((pre) => pre + 1)
                        setDiscountonmrp((mrp * data.data.Result?.promocode?.discount_percentage) / 100)
                        const discount = (mrp * data.data.Result?.promocode?.discount_percentage) / 100
                        if (data.data.Result.promocode.maximum_discount_amount && data.data.Result?.promocode?.discount_percentage) {

                            if (discount < data.data.Result.promocode.maximum_discount_amount) {
                                setDiscountonmrp(discount)
                            } else {
                                setDiscountonmrp(data.data.Result.promocode.maximum_discount_amount)
                            }
                        }
                        else {
                            console.log('logging out discount');
                            setDiscountonmrp(0)
                        }
                        setTotal(total - parseFloat(discountonmrp))
                        setDisable(true)
                        setPlaceorderdisabled(false)
                    }
                })
                .catch((err) => {
                    setIsloading(false)
                    console.log(err);
                })
            // }
        } else {
            toast({
                title: 'Please select patient location',
                position: 'top-right',
                status: 'error',
                duration: 5000,
                isClosable: true,
            })
        }
    }

    // const handleremovecoupon = () => {
    //     setFetch((pre) => pre + 1)
    //     setCouponcode()
    //     setDisable(false)
    //     setCouponresponse()
    //     setDiscountonmrp(0)
    //     setYousaved(0)
    //     setPlaceorderdisabled(true)
    // }

    // useEffect(()=>{
    //     handlecouponapply()
    // },[mrp])



    const handlepharmacyselection = (e) => {
        let selectresult = nearbystoreslist?.filter((item) => (item?.restaurant_id == e))

        console.log('seleted', selectresult[0], total);

        setSelectedNearbypharmacy(selectresult[0])
        if (e) {
            console.log('handlepharmacyselection', e);
            setisCoupondisable(true)
            setPlaceorderdisabled(false)
            if (total > selectresult[0]?.minimum_order_value) {
                console.log("ordercan be placed");
                setMinimumordervaluedisable(false)

            }
        } else {
            console.log('handlepharmacyselection2', e);
            setisCoupondisable(false)
            setPlaceorderdisabled(true)
        }

    }

    console.log(' handlepharmacyselection nearbystoreslist', selectedNearbyPharmacy);

    return (
        <Box
            bg="#E2F0E9"
            py={2}
        >
            <VStack p={2} align="flex-start" bg="#AEECCA" spacing={2}>
                <Heading as="h6" fontSize={14} fontWeight={600}>Select InstaMed Pharmacy of your choice</Heading>
                {
                    disable ?
                        (<Flex alignItems="center" width={"100%"} p={2} bg="#fff" rounded="10px">
                            <Box
                                width="auto"
                                color="#A23DFF"
                            >
                                <Text
                                    maxW={145}
                                    fontWeight={600}
                                    pl={4}
                                    pr={2}
                                    borderRight="solid 2px #A23DFF!important"
                                    whiteSpace="nowrap"
                                    overflow="hidden"
                                    textOverflow="ellipsis"
                                >{couponcode}</Text>
                            </Box>
                            <Flex maxW="100%" width="100%" align="center" justifyContent="space-between">
                                <Heading color="#A23DFF"
                                    px={2} fontSize={12}>Applied </Heading>
                                <HStack align="center" width="auto">

                                    <Button bg="none"
                                        width="auto"
                                        p={0}
                                        height="auto"
                                        pr={1}
                                        minW="auto"
                                        _hover={{ bg: 'none', }}
                                        onClick={() => {
                                            handleremovecoupon();
                                            setisselectdisable(false);
                                        }}
                                    >
                                        <Image w="1.5em" h="1.5em" src={Closegray} />
                                    </Button>
                                    <Image w="1.5em" h="1.5em" borderLeft="solid 2px gray" pl={1} ml="0!important" src={Done} />
                                </HStack>
                            </Flex>
                        </Flex>) :
                        (<HStack p={2} justifyContent="space-between" width={"100%"} px={2} bg="#fff" rounded="10px">
                            <Input
                                height="auto"
                                width="auto"
                                border="none"
                                _focus={{ border: 'none', boxShadow: 'none' }}
                                placeholder="Enter Promocode"
                                onChange={(e) => { setCouponcode(e.target.value) }}
                                disabled={disable || isCouponDisable}
                                value={couponcode}
                            />

                            {
                                isloading ? (<Spinner color='#00ab83' size='sm' />)
                                    : <Button isDisabled={isCouponDisable} height={'auto'} variant={'unstyled'} pr={2} fontSize={13} cursor="pointer" onClick={handlecouponapply} >APPLY</Button>
                            }
                        </HStack>)
                }


                {
                    nearbystoreslist?.length > 0 && (
                        <>
                            <Heading as="h6" w={'100%'} textAlign={'center'} fontSize={14} fontWeight={600}>Or</Heading>
                            <HStack width={'100%'} justifyContent={'space-between'}>
                                {
                                    !selectedNearbyPharmacy && (
                                        <Select
                                            _focus={{ border: 'none', boxShadow: 'none' }}
                                            placeholder='Select Pharmacy' alignItems="center" width={"100%"} py={2} bg="#fff" rounded="10px"
                                            textTransform={'capitalize'}
                                            onChange={(e) => { handlepharmacyselection(e.target.value) }}
                                            value={selectedNearbyPharmacy?.restaurant_id}
                                            isDisabled={isselectDisable}
                                        >
                                            {
                                                nearbystoreslist?.length > 0 && nearbystoreslist?.map((item) => {
                                                    return (
                                                        <option value={item?.restaurant_id} style={{ textTransform: 'capitalize' }}>
                                                            {item?.name}
                                                        </option>
                                                    )
                                                })
                                            }
                                        </Select>
                                    )
                                }
                                {
                                    selectedNearbyPharmacy && (
                                        <Flex alignItems="center" width={"100%"} p={2} bg="#fff" rounded="10px">
                                            <Flex
                                                width="100%"
                                                color="#A23DFF"
                                                alignItems={'center'}
                                            >
                                                <Text
                                                    maxW={145}
                                                    fontWeight={600}
                                                    pl={4}
                                                    pr={2}
                                                    borderRight="solid 2px #A23DFF!important"
                                                    whiteSpace="nowrap"
                                                    overflow="hidden"
                                                    textOverflow="ellipsis"
                                                    textTransform={'capitalize'}
                                                >{selectedNearbyPharmacy?.name}</Text>
                                                <Flex maxW="100%" width="100%" align="center" justifyContent="space-between">
                                                    {/* <Heading px={2} fontSize={12}>Incorrect Code. Re-enter. </Heading> */}
                                                    <Heading px={2} fontSize={12}>Selected</Heading>
                                                    <HStack align="center" width="auto">

                                                        <Button bg="none"
                                                            width="auto"
                                                            p={0}
                                                            height="auto"
                                                            pr={1}
                                                            minW="auto"
                                                            _hover={{ bg: 'none', }}
                                                            onClick={() => {
                                                                setPlaceorderdisabled(true);
                                                                setSelectedNearbypharmacy(null);
                                                                setisCoupondisable(false);
                                                            }}
                                                        >
                                                            <Image w="1.5em" h="1.5em" src={Closegray} />
                                                        </Button>
                                                        <Image w="1.5em" h="1.5em" borderLeft="solid 2px gray" pl={1} ml="0!important" src={Done} />
                                                    </HStack>
                                                </Flex>
                                            </Flex>
                                        </Flex>
                                    )
                                }
                            </HStack>
                        </>
                    )
                }

            </VStack>
        </Box>
    )
}

export default CartCoupon


